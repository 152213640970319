import {
  RESOURCES,
} from '../libs/config';

import {
  api, getOptions,
} from './helpers';

const me = () => api.get('/user/me', getOptions());

const checkVersion = () => api.get(`${RESOURCES}/versions/libot.json`);

const update = (payload) => api.post('/user/update', payload, getOptions());

export default {
  checkVersion,
  update,
  me,
};
