import {
  UilCamera, UilChat, UilPhone, UilEnvelope,
} from '@iconscout/react-unicons';
import {
  Box,
  Button,
  IconButton,
  Notification,
  Waiting,
} from '@xbotvn/mui';
import {
  saveAs,
} from 'file-saver';
import html2canvas from 'html2canvas';
import React, {
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import packageJson from '../../package.json';
import {
  SUPPORT_EMAIL,
} from '../libs/utils';

export default function Footer() {
  const {
    user,
    unit,
    cities,
  } = useSelector(({
    user: userData,
    unit: allUnits,
    catalogs,
  }) => ({
    user: userData,
    unit: allUnits?.data?.[userData.activeUnit] || {},
    cities: catalogs.system.cities.data,
  }));

  const [isCapturing, setIsCapturing] = useState(false);

  const takeScreenShot = () => {
    setIsCapturing(true);
    const options = {
      logging: false,
      imageTimeout: 0,
      backgroundColor: null,
    };
    html2canvas(document.body, options).then((canvas) => {
      canvas.toBlob((blob) => {
        saveAs(blob, 'chup_man_hinh.png');
      });
      setIsCapturing(false);
    });
  };

  const loadLiveSupport = () => {
    if (!user || !window.fcWidget) {
      return;
    }

    window.fcWidget.init({
      token: 'a1e32696-ff15-47e3-a3ec-44c01ddf8f0c',
      host: 'https://wchat.freshchat.com',
      locale: 'vi',
      siteId: 'LIBOT',
      tags: ['libot'],
      firstName: 'Thầy/Cô',
      lastName: user.displayName,
      email: user.email,
      faqTags: {
        tags: ['libot'],
        filterType: 'category',
      },
      config: {
        showFAQOnOpen: false,
        headerProperty: {
          hideChatButton: true,
          appName: 'LIBOT',
        },
        content: {
          placeholders: {
            search_field: 'Tìm kiếm',
            reply_field: 'Trả lời',
            csat_reply: 'Nhập nội dung đánh giá...',
          },
          actions: {
            csat_yes: 'Có',
            csat_no: 'Không',
            push_notify_yes: 'Có',
            push_notify_no: 'Không',
            tab_faq: 'Câu hỏi thường gặp',
            tab_chat: 'Hỗ trợ',
            csat_submit: 'Gửi',
          },
          headers: {
            chat: 'Hỗ trợ trực tuyến',
            chat_help: 'Xin cám ơn thầy/cô đã sử dụng hệ thống LIBOT',
            faq: 'Các câu hỏi thường gặp',
            faq_help: 'Thầy/Cô có thể tìm kiếm theo nội dung',
            faq_not_available: 'Không tìm thấy',
            faq_search_not_available: 'Không tìm thấy tài liệu nào có nội dung {{query}}',
            faq_useful: 'Tài liệu này có giúp ích gì cho thầy/cô không?',
            faq_thankyou: 'Cám ơn phản hồi của thầy/cô',
            faq_message_us: 'Góp ý',
            push_notification: 'Thầy/Cô có đồng ý hiện thông báo khi có tin nhắn đến?',
            csat_question: 'Câu hỏi của thầy/cô có được trả lời không??',
            csat_yes_question: 'Thầy/cô đánh giá như thế nào về thái độ chăm sóc?',
            csat_no_question: 'Công ty có thể cải thiện gì để hỗ trợ tốt thêm không?',
            csat_thankyou: 'Cám ơn đánh giá của thầy/cô',
            csat_rate_here: 'Gửi đánh giá',
            channel_response: {
              offline: 'Hiện không có nhân viên nào đang trực tổng đài. Thầy/Cô vui lòng để lại tin nhắn tại đây.',
              online: {
                minutes: {
                  one: 'Thầy/Cô vui lòng đợi khoảng {!time!} phút',
                  more: 'Thông thường sẽ mất khoảng {!time!} phút',
                },
                hours: {
                  one: 'Thầy/Cô vui lòng đợi khoảng 1 tiếng',
                  more: 'Thông thường sẽ mất khoảng {!time!} tiếng',
                },
              },
            },
          },
        },
      },
    });

    window.fcWidget.user.setProperties({
      lastName: user.name,
      email: user.email,
      unit_name: unit.name,
      address: unit.address,
      districtName: cities?.[unit?.province]?.districts?.[unit?.district]?.name || '',
      proviceName: cities?.[unit?.province]?.name || '',
      unit_id: user.activeUnit,
      user_id: user._id,
    });
  };

  loadLiveSupport();

  return (
    <div style={{
      zIndex: 9,
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      padding: '1rem',
      paddingTop: 5,
      paddingBottom: 5,
      textAlign: 'center',
      background: '#F2F2F2',
      fontFamily: 'sans-serif, Arial, Verdana, "Trebuchet MS"',
    }}
    >
      { isCapturing && <Waiting fullscreen />}
      <div>
        <Button
          color="success"
          size="small"
          style={{
            float: 'left',
            marginLeft: 5,
          }}
          onClick={() => {
            window.open('https://www.notion.so/xbotguide/H-ng-d-n-s-d-ng-LIBOT-343c6249adee407c9287dec479fd3ed1', '_blank');
          }}
        >
          Hướng dẫn sử dụng
        </Button>
        <div style={{
          float: 'left', marginLeft: 5,
        }}
        >
          <p style={{
            margin: 0,
          }}
          >
            {`LIBOT v${packageJson.version} © 2020, STEDU Technology JSC.`}
          </p>
          <p style={{
            margin: 0,
          }}
          >
            All Rights Reserved.
          </p>
        </div>

        <Box display="flex" alignContent="center" alignItems="center" position="absolute" right={0}>

          <Box marginRight={2} display="flex" flexDirection="column" justifyContent="start">
            <p style={{
              margin: 0,
              alignSelf: 'start',
              display: 'flex',
            }}
            >
              <UilPhone size={17} />
              <a style={{ marginLeft: 5 }} href="tel:028-7300-3588">028-7300-3588</a>
            </p>
            <p style={{
              margin: 0,
              alignSelf: 'start',
              display: 'flex',
            }}
            >
              <UilEnvelope size={17} />
              <a style={{ marginLeft: 5 }} href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
            </p>
          </Box>
          <div style={{
            float: 'right', marginRight: 5,
          }}
          >
            <p style={{
              margin: 0,
            }}
            >
              Bấm nút Hỗ trợ để được
              <br />
              chăm sóc đầy đủ nhất
            </p>
          </div>

          {window.fcWidget ? (
            <Button
              color="primary"
              size="small"
              style={{
                float: 'right', marginRight: 5,
              }}
              startIcon={<UilChat />}
              onClick={() => {
                if (!user?.email) {
                  Notification.warn('Vui lòng đăng nhập tại trang Hệ thống để được hỗ trợ');
                  return;
                }
                window.fcWidget.open();
              }}
            >
              Hỗ trợ
            </Button>
          ) : null}
          <IconButton
            color="primary"
            size="small"
            style={{
              float: 'right',
              marginRight: 5,
            }}
            onClick={takeScreenShot}
          >
            <UilCamera />
          </IconButton>

        </Box>

      </div>
    </div>
  );
}
