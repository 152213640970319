import { UilSave, UilTimes } from '@iconscout/react-unicons';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React from 'react';

export default function UserInfo({
  user,
  onClose,
}) {
  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
    >
      <DialogTitle>Thông tin tài khoản</DialogTitle>
      <DialogContent>
        {user.photo && <img loading="eager" src={user.photo} alt="ava" height="50" />}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="Tên"
              fullWidth
              required
              value={user.name}
              onChange={() => {
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              fullWidth
              required
              value={user.email}
              onChange={() => {
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            onClose();
          }}
          startIcon={<UilSave />}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={onClose}
          startIcon={<UilTimes />}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UserInfo.propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};
