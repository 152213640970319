import './App.css';
import { Notification } from '@xbotvn/mui';
import { ThemeProvider } from '@xbotvn/mui/styles';
import { lightTheme } from '@xbotvn/mui/themes';
import {
  createBrowserHistory,
} from 'history';
import PropTypes from 'prop-types';
import React, {
  useEffect,
} from 'react';
import {
  useDispatch,
} from 'react-redux';
import {
  Router, Redirect, Route, Switch,
} from 'react-router-dom';

import {
  Routes, DEFAULT_ROUTE,
} from './Routes';
import Layout from './components/Layout';
import Admin from './pages/Admin';
import Borrow from './pages/Borrow';
import Children from './pages/Children';
import Home from './pages/Home';
import Xbot from './pages/Xbot';
import {
  USER,
} from './redux/actions/constants';

const browserHistory = createBrowserHistory();

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: USER.handlers.login,
    });
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <Router
        history={browserHistory}
      >
        <Switch>
          <Route
            exact
            path={Routes.home}
            render={(props) => (
              <Layout {...props}>
                <Home {...props} />
              </Layout>
            )}
          />
          <Route
            path="/category"
            render={(props) => (
              <Layout {...props}>
                <Home {...props} categoryId={props?.location?.state?.categoryId} />
              </Layout>
            )}
          />
          <Route
            exact
            path={Routes.borrow}
            render={(props) => (
              <Layout {...props}>
                <Borrow {...props} />
              </Layout>
            )}
          />
          <Route
            exact
            path={Routes.admin}
            render={(props) => (
              <Layout {...props}>
                <Admin {...props} />
              </Layout>
            )}
          />
          <Route
            exact
            path={Routes.xbot}
            render={(props) => (
              <Xbot {...props} />
            )}
          />
          <Route
            exact
            path={Routes.children}
            render={(props) => (
              <Layout {...props}>
                <Children {...props} />
              </Layout>
            )}
          />
          <Redirect to={DEFAULT_ROUTE} />
        </Switch>
      </Router>
      <Notification.Container
        position="top-right"
        autoClose={3000}
        hideProgressBar
        pauseOnHover
        pauseOnFocusLoss
        closeOnClick
        draggable
        draggablePercent={80}
      />
    </ThemeProvider>
  );
}

App.defaultProps = {
  location: undefined,
};

App.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      categoryId: PropTypes.string,
    }),
  }),
};
