import _ from 'lodash';

import { paginationApi } from '../../../api';
import {
  exportExcel,
} from '../../../libs/reports';

const defaultCell = null;

const getCellExports = (sheet, dataFollowSheets) => {
  const styleCenter = {
    border: true,
    verticalAlignment: 'center',
    horizontalAlignment: 'center',
  };
  const styleLeft = {
    border: true,
    verticalAlignment: 'center',
    horizontalAlignment: 'left',
  };

  let cellNumberMain = 4;
  _.forEach(dataFollowSheets, (dataFollowSheet) => {
    sheet.row(cellNumberMain).height(18.75);
    sheet.cell(`A${cellNumberMain}`).value(defaultCell).style(styleCenter);
    sheet.cell(`B${cellNumberMain}`).value(dataFollowSheet.order || defaultCell).style(styleCenter);
    sheet.cell(`C${cellNumberMain}`).value(dataFollowSheet.order || defaultCell).style(styleCenter);
    sheet.cell(`D${cellNumberMain}`).value(dataFollowSheet.name || defaultCell).style(styleLeft);
    sheet.cell(`E${cellNumberMain}`).value(dataFollowSheet.producer || defaultCell).style(styleCenter);
    sheet.cell(`F${cellNumberMain}`).value(dataFollowSheet.pop || defaultCell).style(styleLeft);
    sheet.cell(`G${cellNumberMain}`).value(dataFollowSheet.publishYear || defaultCell).style(styleCenter);
    sheet.cell(`H${cellNumberMain}`).value(dataFollowSheet.price || defaultCell).style(styleCenter);
    sheet.cell(`I${cellNumberMain}`).value(dataFollowSheet.category || defaultCell).style(styleCenter);
    sheet.cell(`J${cellNumberMain}`).value(defaultCell).style(styleCenter);
    sheet.cell(`K${cellNumberMain}`).value(defaultCell).style(styleCenter);
    sheet.cell(`L${cellNumberMain}`).value(defaultCell).style(styleCenter);
    sheet.cell(`M${cellNumberMain}`).value(defaultCell).style(styleCenter);
    sheet.cell(`N${cellNumberMain}`).value(defaultCell).style(styleCenter);
    sheet.cell(`O${cellNumberMain}`).value(defaultCell).style(styleCenter);
    sheet.cell(`P${cellNumberMain}`).value(defaultCell).style(styleCenter);
    sheet.cell(`Q${cellNumberMain}`).value(defaultCell).style(styleCenter);
    cellNumberMain += 1;
  });
  return sheet;
};

const exportHandler = ({
  year, documents, storages, onSuccess, activeUnit, producers, categorys,
}) => {
  const data = [];
  const relateDocs = _.groupBy(storages, 'documentId');
  paginationApi.getPage('documentItem', {
    condition: {
      documentId: {
        $in: Object.keys(relateDocs),
      },
      unitId: activeUnit,
    },
  }).then(({
    data: res,
  }) => {
    let currentId = '';
    _.forEach(res?.records || {}, ({ order, documentId }) => {
      if (currentId === documentId) {
        data.push({
          order,
          name: 'nt',
          producer: 'nt',
          pop: 'nt',
          publishYear: 'nt',
          price: 'nt',
          category: 'nt',
        });
      } else {
        currentId = documentId;
        data.push({
          order,
          name: documents?.[documentId]?.name || '',
          producer: producers?.[documents?.[documentId]?.producerId]?.name || '',
          pop: documents?.[documentId]?.pop || '',
          publishYear: documents?.[documentId]?.publishYear || '',
          price: documents?.[documentId]?.price || '',
          category: categorys?.[documents?.[documentId]?.categoryId]?.name || '',
        });
      }
    });
    exportExcel('Mau_SoDangKyCaBiet', (wb) => {
      const sheet = wb.sheet(1);

      sheet.cell('A1').value(`Năm ${year}`);
      getCellExports(sheet, data);
    }, onSuccess, 'SoDangKyCaBiet', true);
  });
};

export default exportHandler;
