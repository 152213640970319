import { UilImageUpload, UilImageDownload } from '@iconscout/react-unicons';
import {
  Box,
  Button, Stack, Typography,
} from '@xbotvn/mui';
import _ from 'lodash';
import React from 'react';
import Dropzone from 'react-dropzone';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  RESOURCES,
} from '../../../libs/config';
import {
  imageExists,
  SUBJECTS_BOOK,
  uploadFileHandler,
} from '../../../libs/utils';
import {
  UNIT,
} from '../../../redux/actions/constants';

export default function SubjectImage() {
  const dispatch = useDispatch();

  const {
    data, activeUnit,
  } = useSelector(({
    unit, user,
  }) => ({
    data: unit.data,
    activeUnit: user.activeUnit,
  }));

  return (
    <div>
      {_.map(SUBJECTS_BOOK, (name, key) => {
        const file = data?.[activeUnit]?.subject?.[key];
        const imageURL = file ? `${RESOURCES}/${activeUnit}/${file}` : '';
        return (
          <Box spacing={2} key={key}>
            <Typography variant="h5">{name}</Typography>
            {imageExists(imageURL) ? (
              <img src={imageURL} width="50%" alt={name} />
            ) : null}
            <Stack
              justifyContent="center"
              direction="row"
              sx={{
                marginTop: 2,
              }}
              spacing={2}
            >
              <Dropzone
                style={{
                  paddingRight: 5,
                }}
                accept=".jpg, .jpeg, .png"
                multiple={false}
                maxSize={5 * 1024 * 1024 * 1024} // 5MB
                onDrop={(files) => {
                  uploadFileHandler({
                    unitID: activeUnit,
                    files,
                    onSuccess: (fileName) => dispatch({
                      type: UNIT.handlers.update,
                      data: {
                        _id: activeUnit,
                        subject: {
                          ...data?.[activeUnit]?.subject || {},
                          [key]: fileName,
                        },
                      },
                    }),
                  });
                }}
              >
                {({
                  getRootProps,
                  getInputProps,
                }) => ((
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button
                      startIcon={<UilImageUpload />}
                      color="primary"
                    >
                      Tải ảnh lên
                    </Button>
                  </div>
                ))}
              </Dropzone>
              {imageExists(imageURL) ? (
                <>
                  <Button
                    color="success"
                    href={imageURL}
                    startIcon={<UilImageDownload />}
                    target="_blank"
                  >
                    Tải ảnh xuống
                  </Button>
                </>
              ) : null}
            </Stack>
          </Box>
        );
      })}
    </div>
  );
}
