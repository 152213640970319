import styled from '@emotion/styled';
import {
  Chip,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, {
  useMemo,
} from 'react';

import {
  cloneDeep,
  forEach,
  map,
} from '../../../libs/lodash';

export default function Tags({
  setFilters,
  filters,
}) {
  const tags = useMemo(() => {
    const converted = [];
    forEach.convert({
      cap: false,
    })(
      (filterType, filterTypeKey) => {
        forEach.convert({
          cap: false,
        })(
          (filterItem, filterItemKey) => {
            converted.push({
              filterTypeKey,
              filterItemKey,
              name: filterItem.name || (filterItemKey === 'new' ? 'Mới' : ''),
            });
          },
          filterType,
        );
      },
      filters,
    );
    return converted;
  }, [filters]);

  return tags.length > 0 && (
    <StyledTags>
      {map((tag, index) => (
        <StyledChip
          key={index}
          onDelete={() => {
            const newFilters = cloneDeep(filters);
            delete newFilters[tag.filterTypeKey][tag.filterItemKey];
            setFilters(newFilters);
          }}
          label={tag.name}
        />
      ), tags)}
      <StyledChip
        color="error"
        onDelete={() => setFilters({})}
        label="Bỏ lọc"
      />
    </StyledTags>
  );
}

const StyledTags = styled.div`
  padding-bottom: 10px;

  cursor: pointer;
`;

const StyledChip = styled(Chip)`
  height: 25px;
  margin-left: 10px;
`;

Tags.propTypes = {
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
};
