import { UilSave, UilTimes } from '@iconscout/react-unicons';
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Notification,
  Grid,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, {
  useState,
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { listingApi } from '../../api';
import {
  getOr,
  includes,
  map,
  filter,
  findIndex,
  size,
} from '../../libs/lodash';
import {
  USER,
} from '../../redux/actions/constants';
import AddressSelector from '../AddressSelector';
import SingleSelect from '../SingleSelect';

export default function UnitSelector({
  onClose,
  user,
}) {
  const dispatch = useDispatch();

  const {
    unitsInitial,
    staffInitial,
    cities,
  } = useSelector(({
    unit,
    staff,
    catalogs,
  }) => ({
    unitsInitial: unit.data,
    staffInitial: staff.data,
    cities: catalogs.system.cities.data,
  }));
  const citiesLoaded = size(cities) > 0;
  const activeUnit = unitsInitial?.[user.activeUnit] || {};
  const {
    province,
    district,
    ward,
  } = activeUnit;
  const [selectedOption, setSelectedOption] = useState(user.activeUnit || '');
  const [unitOptions, setUnitOptions] = useState([]);
  const [address, setAddress] = useState({
    province,
    district,
    ward,
  });

  useEffect(() => {
    const {
      province: p,
      district: d,
      ward: w,
    } = address;
    if (citiesLoaded) {
      let filteredUnits = [];
      if (w) {
        filteredUnits = filter((unit) => unit?.ward === w)(unitsInitial);
      } else if (d) {
        filteredUnits = filter((unit) => unit?.district === d)(unitsInitial);
      } else if (p) {
        filteredUnits = filter((unit) => unit?.province === p)(unitsInitial);
      }
      const unitExist = findIndex((unit) => unit._id === selectedOption)(filteredUnits);
      if (unitExist === -1) {
        setSelectedOption('');
      }
      setUnitOptions(filteredUnits);
    } else {
      setUnitOptions(unitsInitial);
    }
  }, [address]);

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>Chọn đơn vị</DialogTitle>
      <DialogContent>
        {citiesLoaded ? (
          <Grid container>
            <Grid item xs={9}>
              <AddressSelector
                {...address}
                cities={cities}
                onAddressChange={(data) => {
                  setAddress(data);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <SingleSelect
                options={
              map(({
                _id: id, name,
              }) => ({
                value: id, label: name,
              }), unitOptions)
            }
                value={selectedOption}
                onChange={setSelectedOption}
              />
            </Grid>
          </Grid>
        ) : (
          <SingleSelect
            options={
              map(({
                _id: id, name,
              }) => ({
                value: id, label: name,
              }), unitOptions)
            }
            value={selectedOption}
            onChange={setSelectedOption}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          startIcon={<UilSave />}
          onClick={async () => {
            if (!selectedOption) {
              Notification.warn('Bạn chưa chọn đơn vị.');
              return;
            }
            const selectedUnit = unitsInitial[selectedOption];
            const {
              result,
            } = await new Promise((resolve, reject) => {
              listingApi.checkInfo({
                collection: 'member',
                payload: {
                  data: {
                    email: user.email,
                    unitId: selectedOption,
                  },
                },
              }).then((res) => resolve(res.data))
                .catch((err) => reject(err));
            });

            if (!getOr(false, 'support', staffInitial)) {
              const check = includes(
                user.email,
                map((obj) => obj.email, selectedUnit.members),
              );
              if (!check && !result) {
                Notification.warn(`Bạn chưa được cấp quyền vào ${selectedUnit.name || ''}, liên hệ quản trị để được cấp quyền.`);
                return;
              }
            }
            dispatch({
              type: USER.handlers.update,
              data: {
                ...user,
                activeUnit: selectedUnit._id,
              },
              onSuccess: () => {
                Notification.success('Cập nhật đơn vị thành công.');
                window.location.reload();
              },
            });
          }}
          size="small"
        >
          Lưu
        </Button>
        <Button
          color="error"
          startIcon={<UilTimes />}
          onClick={onClose}
          style={{ marginLeft: 15 }}
          size="small"
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UnitSelector.propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};
