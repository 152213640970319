import {
  all,
} from 'redux-saga/effects';

import authorSaga from './author';
import bookshelfSaga from './bookshelf';
import borrowSaga from './borrow';
import cabinetSaga from './cabinet';
import catalogsSaga from './catalogs';
import categorySaga from './category';
import childrenBookSaga from './childrenBook';
import documentSaga from './document';
import fileSaga from './file';
import levelSaga from './level';
import listingSaga from './listing';
import memberSaga from './member';
import producerSaga from './producer';
import staffSaga from './staff';
import storageSaga from './storage';
import storageTypeSaga from './storageType';
import subjectSaga from './subject';
import typeSaga from './type';
import unitSaga from './unit';
import userSaga from './user';
import warehouseSaga from './warehouse';

export default function* rootSaga() {
  yield all([
    userSaga(),
    staffSaga(),
    unitSaga(),
    categorySaga(),
    typeSaga(),
    warehouseSaga(),
    bookshelfSaga(),
    producerSaga(),
    authorSaga(),
    borrowSaga(),
    documentSaga(),
    storageSaga(),
    levelSaga(),
    storageTypeSaga(),
    memberSaga(),
    fileSaga(),
    cabinetSaga(),
    subjectSaga(),
    catalogsSaga(),
    childrenBookSaga(),
    listingSaga(),
  ]);
}
