import axios from 'axios';

import {
  BACKEND_URL,
} from '../libs/config';

const defaults = {
  baseURL: `${BACKEND_URL}/api`,
  withCredentials: true,
};

const api = axios.create(defaults);

const fileDownload = axios.create({
  ...defaults,
  responseType: 'blob',
});

const getOptions = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export {
  api,
  fileDownload,
  getOptions,
};
