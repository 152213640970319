import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Autocomplete,
  Notification,
  Grid,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';

import {
  getOr,
  map,
  includes,
} from '../../../libs/lodash';
import {
  convertGGDriveUrl,
  SUBJECTS_BOOK,
} from '../../../libs/utils';

export default function Editor({
  onHandler,
  data: initData,
  onClose,
}) {
  const [data, setData] = useState(initData);

  return (
    <Dialog maxWidth="lg" fullWidth open onClose={onClose}>
      <DialogTitle onClose={onClose}>{`${initData?._id ? 'Sửa' : 'Thêm'} sách cho trẻ` }</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={5}>
            <img
              style={{ objectFit: 'fill' }}
              width="100%"
              height="100%"
              src={data?.coverLink}
              srcSet={data?.coverLink}
              alt={data?.name}
              loading="lazy"
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              label="Nhan đề sách"
              value={getOr('', 'name', data)}
              onChange={(e) => {
                setData((prevData) => ({
                  ...prevData,
                  name: e.target.value,
                }));
              }}
              required
            />
            <Autocomplete
              options={map.convert({
                cap: false,
              })(
                (value, key) => ({
                  label: value,
                  value: key,
                }),
                SUBJECTS_BOOK,
              )}
              value={SUBJECTS_BOOK?.[getOr('', 'subject', data)]}
              onChange={(e, value) => setData((prevData) => ({
                ...prevData,
                subject: value?.value,
              }))}
              renderInput={(params) => <TextField {...params} label="Chủ đề" placeholder="Chọn chủ đề" />}
            />
            <TextField
              label="Đường link bìa sách"
              value={getOr('', 'coverLink', data)}
              onChange={(e) => {
                let coverLink = e.target.value;
                if (includes('drive.google.com/file/d/')(coverLink)) {
                  coverLink = convertGGDriveUrl(coverLink);
                }
                setData((prevData) => ({
                  ...prevData,
                  coverLink,
                }));
              }}
              required
            />
            <TextField
              label="Đường link sách"
              value={getOr('', 'bookLink', data)}
              onChange={(e) => setData((prevData) => ({
                ...prevData,
                bookLink: e.target.value,
              }))}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            const {
              name, subject, coverLink, bookLink,
            } = data;
            if (!name) {
              Notification.warn('Họ tên không được bỏ trống.');
              return;
            }
            if (!subject) {
              Notification.warn('Chủ đề không được bỏ trống.');
              return;
            }
            if (!coverLink) {
              Notification.warn('Đường link ảnh bìa không được bỏ trống.');
              return;
            }
            if (!bookLink) {
              Notification.warn('Đường link sách online không được bỏ trống.');
              return;
            }
            onHandler(data);
          }}
        >
          Lưu
        </Button>
        <Button color="warning" onClick={onClose}>
          Huỷ
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Editor.propTypes = {
  data: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  onHandler: PropTypes.func,
  title: PropTypes.string,
};

Editor.defaultProps = {
  data: {},
  title: 'Dialog',
  onHandler: () => { },
};
