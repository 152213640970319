import styled from '@emotion/styled';
import { Button } from '@xbotvn/mui';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  RESOURCES,
} from '../../libs/config';
import { getSubjectImage, SUBJECTS_BOOK } from '../../libs/utils';

import Books from './Books';

export default function Layout({ subject, setSubject }) {
  const {
    unitData, activeUnit,
  } = useSelector(({
    unit, user,
  }) => ({
    unitData: unit.data?.[user?.activeUnit] || {},
    activeUnit: user?.activeUnit,
  }));

  return (
    <Container>
      <div style={{
        display: 'flex',
        marginBottom: 50,
      }}
      >
        <StyledFilters>
          {_.map(SUBJECTS_BOOK, (name, key) => {
            const file = unitData?.subject?.[key];
            const imageURL = file ? `${RESOURCES}/${activeUnit}/${file}` : '';
            return (
              <Button
                onClick={() => setSubject(key)}
                key={key}
                variant="text"
                style={{
                  height: 100,
                  width: '100%',
                  justifyContent: 'flex-start',
                  marginBottom: 5,
                }}
                startIcon={(
                  <img
                    alt={name}
                    loading="lazy"
                    src={getSubjectImage(imageURL, `${key}.png`)}
                    width={100}
                    height={100}
                    style={{ borderStyle: 'solid', borderWidth: 1, borderRadius: 5 }}
                  />
              )}
              >
                {name}
              </Button>
            );
          })}
        </StyledFilters>
        <StyledRecords>
          <Books subject={subject} />
        </StyledRecords>
      </div>
    </Container>
  );
}

Layout.propTypes = {
  subject: PropTypes.string.isRequired,
  setSubject: PropTypes.func.isRequired,
};

const Container = styled.div`
  padding: 10px;
`;

const StyledFilters = styled.div`
  padding: 10px;
  padding-right: 0px;
  width: 20%;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
`;

const StyledRecords = styled.div`
  margin-left: 10px;
  padding: 10px;
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
`;
