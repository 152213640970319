import { UilTransaction } from '@iconscout/react-unicons';
import {
  LoadingButton,
} from '@xbotvn/mui';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import {
  find,
  getOr,
  keys,
} from '../../libs/lodash';

import UnitSelector from './UnitSelector';

export default function Header() {
  const {
    userInitial,
    unitsInitial,
  } = useSelector(({
    user, unit,
  }) => ({
    userInitial: user,
    unitsInitial: unit.data,
  }));

  const [unitModal, setUnitModal] = useState(false);

  const getUnitName = useMemo(() => {
    const {
      activeUnit,
    } = userInitial;
    const filtered = find((unit) => unit._id === activeUnit, unitsInitial);
    if (keys(filtered).length) {
      return getOr('', 'name', filtered);
    }
    return 'Chọn đơn vị';
  }, [userInitial, unitsInitial]);

  return (
    <>
      {unitModal && (
        <UnitSelector
          user={userInitial}
          onClose={() => setUnitModal(false)}
        />
      )}
      {userInitial._id && (
        <LoadingButton
          variant="text"
          style={{
            marginRight: 15,
          }}
          loading={userInitial.handling}
          onClick={() => setUnitModal(true)}
          startIcon={<UilTransaction />}
        >
          {getUnitName}
        </LoadingButton>
      )}
    </>
  );
}
