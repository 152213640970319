import {
  BORROW,
} from '../actions/constants';

const initialState = {
  handling: false,
  data: {},
};

export default function borrow(state = initialState, action) {
  switch (action.type) {
    case BORROW.handlers.update:
      return {
        ...state,
        handling: true,
      };
    case BORROW.update:
      return {
        ...state,
        data: action.data,
        handling: false,
      };
    default:
      return state;
  }
}
