import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Autocomplete,
  Notification,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';

import {
  getOr, map,
} from '../../../libs/lodash';
import {
  ROLES,
} from '../../../libs/utils';

const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default function MemberEditor({
  onHandler,
  title,
  data: initData,
  onClose,
}) {
  const [data, setData] = useState(initData);

  return (
    <Dialog maxWidth="sm" fullWidth open onClose={onClose}>
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent>
        <TextField
          label="Họ và tên"
          value={getOr('', 'name', data)}
          onChange={(e) => {
            setData((prevData) => ({
              ...prevData,
              name: e.target.value,
            }));
          }}
          required
        />
        <TextField
          label="Email"
          value={getOr('', 'email', data)}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            email: e.target.value,
          }))}
          required
        />
        <Autocomplete
          options={map.convert({
            cap: false,
          })(
            (value, key) => ({
              label: value,
              value: key,
            }),
            ROLES,
          )}
          value={ROLES?.[getOr('', 'role', data)]}
          onChange={(e, value) => setData((prevData) => ({
            ...prevData,
            role: value?.value,
          }))}
          renderInput={(params) => <TextField {...params} label="Chức quyền" placeholder="Chọn quyền" />}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            const {
              name, email, role,
            } = data;
            if (!name || !name.length) {
              Notification.warn('Họ tên không được bỏ trống.');
              return;
            }
            if (email && !EmailRegex.test(email)) {
              Notification.warn('Email không đúng định dạng.');
              return;
            }
            if (!email) {
              Notification.warn('Email không được bỏ trống.');
              return;
            }
            if (!role || !role.length) {
              Notification.warn('Chưa chọn quyền cho tài khoản.');
              return;
            }
            onHandler(data);
          }}
        >
          Lưu
        </Button>
        <Button color="warning" onClick={onClose}>
          Huỷ
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MemberEditor.propTypes = {
  data: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  onHandler: PropTypes.func,
  title: PropTypes.string,
};

MemberEditor.defaultProps = {
  data: {},
  title: 'Dialog',
  onHandler: () => { },
};
