import {
  UilTrash,
  UilPen,
  UilUpload,
  UilImport,
  UilPlus,
  UilQrcodeScan,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  DialogContent,
  ConfirmDialog,
  Divider,
  IconButton,
  Box,
  Waiting,
  Notification,
} from '@xbotvn/mui';
import { get } from 'lodash';
import moment from 'moment';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import { paginationApi } from '../../../api';
import DropzoneModal from '../../../components/DropzoneModal';
import Table from '../../../components/Table';
import {
  OLD_DOCUMENTS,
} from '../../../libs/config';
import {
  cloneDeep,
  forEach,
  getOr,
  replace,
  orderBy,
  values,
} from '../../../libs/lodash';
import { languages, tempGrades, tempSchoolLevels } from '../../../libs/options';
import {
  DOCUMENT,
  LISTING,
  FILE,
} from '../../../redux/actions/constants';
import {
  importExcelOld,
  handleRows,
} from '../Helpers';

import DocumentEditor from './DocumentEditor';
import ExportCode from './ExportCode';
import ImportErrors from './ImportErrors';

export default function Document() {
  const dispatch = useDispatch();

  const {
    categories,
    authors,
    producers,
    types,
    activeUnit,
    documents,
    subjects,
    storageTypes,
    warehouses,
    bookShelves,
  } = useSelector(({
    category,
    author,
    producer,
    type,
    user,
    document,
    subject,
    storageType,
    level,
    warehouse,
    bookshelf,
  }) => ({
    categories: category.data,
    subjects: subject.data,
    authors: author.data,
    producers: producer.data,
    types: type.data,
    activeUnit: user.activeUnit,
    documents: document.data,
    storageTypes: storageType.data,
    levels: level.data,
    warehouses: warehouse.data,
    bookShelves: bookshelf.data,
  }));

  const [editor, setEditor] = useState();
  const [showImport, setShowImport] = useState();
  const [isOpenCodeModal, setIsOpenCodeModal] = useState(false);
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [importErrors, setImportErrors] = useState();

  const dataDocument = useMemo(() => orderBy(
    ['registrationNumber'],
    ['desc'],
    values(documents),
  ).map((row) => ({
    ...row,
    typeName: types?.[row.typeId]?.name ?? '',
    authorName: authors?.[row.authorId]?.name ?? '',
    categoryName: categories?.[row.categoryId]?.name ?? '',
    producerName: producers?.[row.producerId]?.name ?? '',
    regSymbol: row?.symbol ? `${row?.symbol ?? ''}-${row?.registrationNumber ?? ''}` : row?.registrationNumber,
  })), [documents]);

  const importFunction = importExcelOld;

  const columns = useMemo(() => {
    const results = [
      {
        Header: 'STT',
        style: {
          width: 80,
        },
        Cell: ({
          row,
        }) => row.index + 1,
      },
      {
        Header: 'Tài liệu',
        accessor: 'name',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Giá tiền',
        accessor: 'price',
        style: {
          width: 250,
        },
        Cell: (rowProps) => {
          const { value } = rowProps;
          if (!value) return '';
          return replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,', value);
        },
      },
      {
        Header: 'Số đăng ký cá biệt',
        accessor: 'registrationNumber',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Danh mục sách',
        accessor: 'categoryName',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Tác giả',
        accessor: 'authorName',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Nhà xuất bản',
        accessor: 'producerName',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Ngày nhập liệu',
        accessor: 'createdAt',
        style: {
          width: 250,
        },
        Cell: (rowProps) => {
          const value = get(rowProps, 'value');
          if (!value) return null;
          return moment(value).format('DD/MM/YYYY');
        },
      },
      {
        Header: () => (
          <Button
            startIcon={<UilPlus />}
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => setEditor({
              unitId: activeUnit,
              name: '',
              typeId: '',
              categoryId: '',
              authorId: '',
              producerId: '',
              bookshelfId: '',
              warehouseId: '',
              price: 0,
            })}
          >
            Thêm
          </Button>
        ),
        id: 'add',
        style: {
          width: 150,
        },
        Cell: (rowProps) => {
          const rowValue = getOr({}, 'row.original', rowProps);
          return (
            <ButtonGroup>
              <IconButton
                onClick={() => setEditor(cloneDeep(rowValue))}
                color="primary"
                size="small"
                sx={{ marginRight: '5px' }}
              >
                <UilPen />
              </IconButton>
              <Divider orientation="vertical" flexItem />
              <IconButton
                size="small"
                color="error"
                sx={{ marginLeft: '5px' }}
                onClick={() => {
                  setRemoveConfirm(rowValue._id);
                }}
              >
                <UilTrash />
              </IconButton>
            </ButtonGroup>
          );
        },
      },
    ];

    results.splice(4, 0, {
      Header: 'Loại sách',
      accessor: 'typeName',
      style: {
        width: 250,
      },
    });

    return results;
  }, [
    types,
    categories,
    authors,
    producers,
  ]);

  const renderEditor = () => {
    if (editor) {
      return <DocumentEditor data={editor} onClose={() => setEditor()} />;
    }
    return null;
  };

  return (
    <div>
      {renderEditor()}
      {isOpenCodeModal && <ExportCode onClose={() => setIsOpenCodeModal(false)} />}
      {(waiting) && <Waiting />}
      { importErrors && (
      <ImportErrors
        data={importErrors}
        onClose={() => {
          setImportErrors();
        }}
      />
      )}
      {removeConfirm ? (
        <ConfirmDialog
          title="Khi xoá tài liệu, sẽ xoá tất cả các mã sách và các thông tin liên quan như tình trạng lưu trữ và tình trạng mượn trả của tài liệu đó. Thầy/Cô có muốn tiếp tục không?"
          color="error"
          confirmLabel="Xoá"
          cancelLabel="Huỷ"
          maxWidth="sm"
          fullWidth
          open
          onClose={() => setRemoveConfirm()}
          onConfirm={async () => {
            setWaiting(true);
            const { data: items } = await paginationApi.getPage('documentItem', {
              condition: {
                unitId: activeUnit,
                documentId: removeConfirm,
                borrowed: true,
              },
            });

            if (items.records?.length) {
              setWaiting(false);
              Notification.warn('Tài liệu đang được mượn, vui lòng kiểm tra lại.');
            } else {
              dispatch({
                type: DOCUMENT.handlers.remove,
                _id: removeConfirm,
                onSuccess: () => {
                },
              });
              setWaiting(false);
            }
            setRemoveConfirm();
          }}
        >
          <DialogContent />
        </ConfirmDialog>
      ) : null}
      {showImport && (
        <DropzoneModal
          onConfirm={(files) => {
            if (!files.length) return;
            importFunction({
              files,
              activeUnit,
              config: OLD_DOCUMENTS,
              onSuccess: (result) => {
                if (result.some(({ errors }) => errors)) {
                  setImportErrors(result);
                } else {
                  const {
                    newData,
                    newListing,
                    storageData,
                    documentItems,
                  } = handleRows({
                    records: result,
                    unitId: activeUnit,
                    stores: {
                      author: authors,
                      category: categories,
                      type: types,
                      producer: producers,
                      warehouses,
                      bookShelves,
                    },
                    documents,
                  });

                  forEach.convert({
                    cap: false,
                  })(
                    (data, collection) => {
                      if (Object.keys(data).length) {
                        dispatch({
                          type: LISTING.handlers.create,
                          data: {
                            data, collection,
                          },
                        });
                      }
                    },
                    newListing,
                  );
                  dispatch({
                    type: DOCUMENT.handlers.import,
                    data: newData,
                    storageData,
                    documentItems,
                  });
                }
                setShowImport();
              },
            }, 1, {
              categories,
              authors,
              languages,
              subjects,
              storageTypes,
              coAuthors: authors,
              producer: producers,
              levels: tempSchoolLevels,
              grades: tempGrades,
            });
          }}
          onClose={() => setShowImport()}
        />
      )}
      <Box marginLeft={1} padding={1}>
        <Button
          startIcon={<UilUpload />}
          color="success"
          size="small"
          onClick={() => setShowImport(true)}
          sx={{ marginRight: 1 }}
        >
          Nhập từ Excel
        </Button>
        <Button
          startIcon={<UilImport />}
          color="primary"
          size="small"
          sx={{ marginRight: 1 }}
          onClick={() => dispatch({
            type: FILE.handlers.download,
            data: {
              unitId: activeUnit,
              fileName: 'Mau_TaiLieu.xlsx',
            },
          })}
        >
          Tải file mẫu
        </Button>
        <Button
          startIcon={<UilQrcodeScan />}
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => setIsOpenCodeModal(true)}
        >
          Xuất mã QR/Bar code
        </Button>
      </Box>
      <Table
        columns={columns}
        data={dataDocument}
        alwaysShowPagination
      />
    </div>
  );
}
