import {
  api, fileDownload, getOptions,
} from './helpers';

const upload = (payload) => api.post('/file/upload', payload, getOptions());

const download = (payload) => fileDownload.post('/file/download', payload, getOptions()).then((result) => {
  if (result.data) return result.data;
  return null;
});

export default {
  upload,
  download,
};
