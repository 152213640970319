import {
  BOOKSHELF,
} from '../actions/constants';

const initialState = {
  handling: false,
  data: {},
};

export default function bookshelf(state = initialState, action) {
  switch (action.type) {
    case BOOKSHELF.handlers.fetch:
    case BOOKSHELF.handlers.update:
    case BOOKSHELF.handlers.remove:
      return {
        ...state,
        handling: true,
      };
    case BOOKSHELF.update:
      return {
        ...state,
        data: action.merge ? {
          ...state.data,
          ...action.data,
        } : action.data,
        handling: false,
      };
    default:
      return state;
  }
}
