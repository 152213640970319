import { Notification } from '@xbotvn/mui';
import ReactGA from 'react-ga';

import {
  dev,
} from './firebase';
import {
  replace,
} from './lodash';

export const initializeGA = (uid, version) => {
  if (dev) return;
  ReactGA.initialize('UA-xxx-1', {
    gaOptions: {
      clientId: uid,
      userId: uid,
      appId: 'elibot-release.firebaseapp.com',
      appVersion: version,
    },
  });
};

export const pageView = (page) => {
  if (dev) { return; }
  ReactGA.pageview(page);
};

export const initializeLog = (uid, email) => {
  if (dev) { return; }
  ReactGA.ga(
    'create', 'UA-xxx-3', 'auto', 'log',
    {
      clientId: uid,
      userId: uid,
    },
  );
  ReactGA.ga(
    'create', 'UA-xxx-2', 'auto', 'emaillog',
    {
      clientId: replace(email, /@/g, '_'),
      userId: replace(email, /@/g, '_'),
    },
  );
};

export const initializeUnitLog = (unitId) => {
  if (dev) { return; }
  ReactGA.ga(
    'create', 'UA-xxx-4', 'auto', 'unitlog',
    {
      clientId: unitId,
      userId: unitId,
    },
  );
};

export const writeLog = (
  category, action, label, value = 1,
) => {
  if (dev) { return; }

  const sendLog = (logServer) => ReactGA.ga(
    logServer, 'event',
    replace(`${category}`, /@/g, '_'),
    replace(`${action}`, /@/g, '_'),
    replace(`${label}`, /@/g, '_'),
    value,
  );
  sendLog('log.send');
  sendLog('emaillog.send');
  sendLog('unitlog.send');
};

export const exception = (msg) => {
  console.log('Lỗi hệ thống', msg);
  Notification.error(`Lỗi hệ thống: ${msg}`);
};

export const errorHandler = (title, error, onSuccess) => {
  const {
    message, data,
  } = error || {};
  const msg = message || data || error;
  Notification.error(`${title}: ${msg}`);
  if (onSuccess) onSuccess();
};

export const successHandler = (title, message, onSuccess) => {
  Notification.success(`${title}: ${message}`);
  if (onSuccess) onSuccess();
};
