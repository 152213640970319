import { UilPrint } from '@iconscout/react-unicons';
import {
  Box,
  Grid,
  Waiting,
  Autocomplete,
  Radio,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Button,
} from '@xbotvn/mui';
import html2pdf from 'html2pdf.js';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import PosterComponent from '../../../components/Poster';
import {
  map, cloneDeep,
} from '../../../libs/lodash';
import { convertViToEn } from '../../../libs/utils';

function Code() {
  const {
    cabinetData,
    storageData,
    documentData,
    authorData,
    handling,
    categories,
    producers,
    // warehouseData,
  } = useSelector(({
    cabinet,
    storage,
    document,
    author,
    warehouse,
    category,
    producer,
  }) => ({
    cabinetData: cabinet?.data ?? {},
    storageData: storage?.data ?? {},
    documentData: document?.data ?? {},
    authorData: author?.data ?? {},
    warehouseData: warehouse?.data ?? {},
    handling: cabinet.handling ?? false,
    categories: category.data,
    producers: producer.data,
  }));

  const [filters, setFilters] = useState('all');
  const [waiting, setWaiting] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [selectedProducers, setSelectedProducers] = useState([]);
  const [selectedCabinet, setSelectedCabinet] = useState([]);

  const posters = useMemo(() => Object.values(storageData).filter(({ cabinetId }) => cabinetId)
    .map(({ documentId, cabinetId }) => {
      const doc = {
        ...documentData?.[documentId],
        authorName: authorData?.[documentData?.[documentId]?.authorId]?.name,
        cabinetId,
      };
      return doc;
    }), []);

  const allFilters = {
    selectedAuthors,
    selectedCabinet,
    selectedCategories,
    selectedProducers,
    selectedTitle,
  };

  const filterTerms = {
    selectedAuthors: 'authorId',
    selectedCategories: 'categoryId',
    selectedProducers: 'producerId',
    selectedCabinet: 'cabinetId',
    selectedTitle: 'title',
  };
  const filterPosters = (docs, filterFields) => {
    const filterKeys = cloneDeep(filterFields);
    let results = docs;
    if (allFilters?.[filterKeys[0]]?.length > 0) {
      results = docs.filter((item) => {
        if (filterTerms?.[filterKeys[0]] === 'title') {
          return convertViToEn(item?.name ?? '').includes(convertViToEn(selectedTitle ?? ''));
        }
        const filterKey = filterTerms?.[filterKeys[0]];
        return allFilters?.[filterKeys[0]].some(({ value }) => value === item?.[filterKey]);
      });
      filterKeys.shift();
    } else filterKeys.shift();
    if (filterKeys.length) return filterPosters(results, filterKeys);
    return results;
  };

  return (
    <Box
      display="flex"
      sx={{
        position: 'relative', height: window.innerHeight - 418, overflowY: 'hidden',
      }}
    >
      {(waiting || handling) && <Waiting fullscreen />}
      <Box
        sx={{
          flex: '70%',
          overflowY: 'scroll',
        }}
        id="print-area"
      >
        {/* {records.length && <ExportCode data={records} typeCode={options} unitName={unitName} />} */}
        {filterPosters(posters, Object.keys(filterTerms)).map((posterData) => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: 1,
              '& .MuiGrid-container': {
                marginLeft: 'unset',
              },
            }}
          >
            <PosterComponent document={posterData} height={280} width={500} />
          </Box>
        ))}
      </Box>
      <Box flex="30%">
        <Box
          style={{
            display: 'flex',
          }}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">Lọc phích:</FormLabel>
            <RadioGroup
              row
              onChange={(e) => setFilters(e.target.value)}
              value={filters}
            >
              <FormControlLabel label="Tất cả" control={<Radio value="all" />} />
              <FormControlLabel label="Theo điều kiện" control={<Radio value="filters" />} />
            </RadioGroup>
          </FormControl>
        </Box>
        {filters === 'filters' && (
        <Box width="90%">
          <Grid container>
            <Grid item xs={6}>
              <Autocomplete
                options={map(({ _id, name }) => ({ label: name, value: _id }), cabinetData)}
                value={selectedCabinet}
                onChange={(e, data) => setSelectedCabinet(data)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Tủ phích"
                  />
                )}
                multiple
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder="Nhan đề"
                fullWidth
                value={selectedTitle}
                onChange={(e) => setSelectedTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={map(({ _id, name }) => ({ label: name, value: _id }), categories)}
                value={selectedCategories}
                onChange={(e, data) => setSelectedCategories(data)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Loại ấn phẩm"
                  />
                )}
                multiple
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={map(({ _id, name }) => ({ label: name, value: _id }), authorData)}
                value={selectedAuthors}
                onChange={(e, data) => setSelectedAuthors(data)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Tác giả"
                  />
                )}
                multiple
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={map(({ _id, name }) => ({ label: name, value: _id }), producers)}
                value={selectedProducers}
                onChange={(e, data) => setSelectedProducers(data)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Nhà xuất bản"
                  />
                )}
                multiple
              />
            </Grid>
          </Grid>
        </Box>
        )}
        <Box sx={{ marginLeft: 1 }}>
          <Button
            startIcon={<UilPrint />}
            color="success"
            onClick={async () => {
              setWaiting(true);
              const printNode = document.getElementById('print-area').cloneNode(true);
              Object.values(printNode.children || {}).forEach((element) => {
                // eslint-disable-next-line no-param-reassign
                element.style.height = 'auto';
              });
              await html2pdf()
                .set({
                  filename: 'Danh sách Phích.pdf',
                  html2canvas: { scale: 1.2 },
                  jsPDF: { format: 'a4', orientation: 'portrait' },
                  pagebreak: { mode: 'avoid-all' },
                })
                .from(printNode)
                .save();
              setWaiting(false);
            }}
          >
            Xuất File
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Code;
