import { Notification } from '@xbotvn/mui';
import { words, capitalize } from '@xbotvn/utils/string';
import { last, toNumber, split as lSplit } from 'lodash';

import { fileApi } from '../api';

import {
  getOr,
  size,
  map,
  toLower,
  split,
  toUpper,
} from './lodash';
import {
  cutter,
} from './options';

export const SUPPORT_EMAIL = 'hotrolibot@gmail.com';

export const hasChildrenUnit = (unitInfo) => unitInfo?.config?.children;

export const ROLES = {
  admin: 'Admin',
  member: 'Nhân viên',
};

export const SUBJECTS_BOOK = {
  subject0: 'Sách giáo viên tự làm',
  subject1: 'Sách tổng hợp',
  subject2: 'Truyện kể thiếu nhi',
  subject3: 'Sách kỹ năng - lễ giáo',
  subject4: 'Sách Hồ Chí Minh',
  subject5: 'Sách nghe',
};

export const uploadFileHandler = ({
  unitID,
  files,
  onSuccess,
  newName,
  isReplace,
}) => {
  const file = files[0];
  const fd = new FormData();
  fd.append('unitID', unitID);
  fd.append('isReplace', isReplace);
  if (newName) {
    fd.append('file', file, newName);
  } else {
    fd.append('file', file);
  }

  fileApi
    .upload(fd)
    .then((result) => {
      onSuccess(result.data.fileName);
      Notification.success('Thành công');
    })
    .catch((error) => {
      Notification.error(error.message);
    });
};

export const imageExists = (imageUrl) => {
  if (!imageUrl) return false;
  const http = new XMLHttpRequest();

  http.open('HEAD', imageUrl, true);
  http.send();

  return http.status !== 404;
};

export const getISBD = (doc, authors, producers) => (doc.name ? `${doc.name}` : '')
  + (doc.parallelTitle ? ` = ${doc.parallelTitle}` : '')
  + (doc.subTitle ? ` : ${doc.subTitle}` : '')
  + (doc.authorId ? ` / ${authors?.[doc.authorId]?.name || ''}` : '')
  + (size(doc.sameAuthor)
    ? ` ; ${map((author) => authors?.[author]?.name)(doc.sameAuthor).join(
      ', ',
    )}`
    : '')
  + (doc.translator ? ` ; ${doc.translator}` : '')
  + (doc.resInfo ? ` ; ${doc.resInfo}` : '')
  + (doc.edition ? `. - ${doc.edition}` : '')
  + (doc.pop ? `. -  ${doc.pop}` : '')
  + (doc.producerId
    ? ` : ${getOr('', [doc.producerId, 'name'])(producers)}`
    : '')
  + (doc.publishYear ? `, ${doc.publishYear}` : '')
  + (doc.pages ? `. - ${doc.pages}tr` : '')
  + (doc.size ? `. ; ${doc.size}.` : '');

export const convertViToEn = (value) => {
  if (typeof value === 'string') {
    return value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd');
  }
  return value;
};

export const convertName = (name) => words(name)
  .map((word) => capitalize(word))
  .join(' ');

export const removeAccents = (text) => text
  .replace(/à|á|ạ|ả|ã/g, 'a')
  .replace(/ằ|ắ|ặ|ẳ|ẵ/g, 'ă')
  .replace(/ầ|ấ|ậ|ẩ|ẫ/g, 'â')
  .replace(/è|é|ẹ|ẻ|ẽ/g, 'e')
  .replace(/ề|ế|ệ|ể|ễ/g, 'ê')
  .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  .replace(/ò|ó|ọ|ỏ|õ/g, 'o')
  .replace(/ồ|ố|ộ|ổ|ỗ/g, 'ô')
  .replace(/ờ|ớ|ợ|ở|ỡ/g, 'ơ')
  .replace(/ù|ú|ụ|ủ|ũ/g, 'u')
  .replace(/ừ|ứ|ự|ử|ữ/g, 'ư')
  .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  .replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '')
  .replace(/\u02C6|\u0306|\u031B/g, '');

export const vowelReg = /a|ă|â|e|ê|i|o|ô|ơ|u|ư/;

export const splitVowel = (str) => str.slice(str.search(vowelReg));

export const splitConsonant = (str) => str.slice(0, str.search(vowelReg));

export const decodeCutter = (text) => {
  if (text) {
    const lowerStr = toLower(text);
    const normalizeStr = removeAccents(lowerStr);
    const splitedStrs = split(' ')(normalizeStr);
    if (size(splitedStrs) === 1) {
      return toUpper(splitedStrs[0].charAt(0));
    }
    const vowel = splitVowel(splitedStrs[0]);
    return toUpper(`${splitConsonant(splitedStrs[0])}${cutter[vowel] || ''}${splitedStrs[1].charAt(0)}`);
  }
  return '';
};

export const getBigestNum = (regNum) => toNumber(last(lSplit(last(lSplit(regNum, ',')), '-'))) || 0;

export const isValidUrl = (url) => (/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ig).test(url);

export const getSubjectImage = (url, defaultImage) => url || defaultImage;

export const convertGGDriveUrl = (url) => {
  const id = url.substring(
    url.indexOf('d/') + 2,
    url.lastIndexOf('/view'),
  );
  return id ? `https://drive.google.com/uc?export=view&id=${id}` : url;
};
