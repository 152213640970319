import {
  api, getOptions,
} from './helpers';

const get = () => api.get('/unit/get', getOptions());

const update = (payload) => api.post('/unit/update', payload, getOptions());

const create = (payload) => api.post('/unit/create', payload, getOptions());

export default {
  get,
  update,
  create,
};
