import {
  UilQuestionCircle,
  UilCreditCard,
  UilBox,
  UilInbox,
} from '@iconscout/react-unicons';
import { Button, Box, Toolbar } from '@xbotvn/mui';
import React, {
  useMemo,
  useState,
} from 'react';

import Records from './Records';

export default function Borrow() {
  const [menuActive, setMenu] = useState('request');

  const renderContent = useMemo(() => <Records type={menuActive} />, [menuActive]);

  return (
    <>
      <Toolbar sx={{
        boxShadow:
        'rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
      }}
      >
        <Box>
          <Button
            variant="text"
            startIcon={<UilQuestionCircle />}
            color={menuActive === 'request' ? 'primary' : 'inherit'}
            onClick={() => setMenu('request')}
          >
            Yêu cầu
          </Button>

          <Button
            variant="text"
            startIcon={<UilCreditCard />}
            color={menuActive === 'new' ? 'primary' : 'inherit'}
            onClick={() => setMenu('new')}
          >
            Đang mượn
          </Button>
          <Button
            variant="text"
            startIcon={<UilBox />}
            color={menuActive === 'late' ? 'primary' : 'inherit'}
            onClick={() => setMenu('late')}
          >
            Hết hạn
          </Button>
          <Button
            variant="text"
            startIcon={<UilInbox />}
            color={menuActive === 'done' ? 'primary' : 'inherit'}
            onClick={() => setMenu('done')}
          >
            Đã trả
          </Button>
        </Box>
      </Toolbar>
      {renderContent}
    </>
  );
}
