import {
  UilTrash,
  UilPen,
  UilUpload,
  UilImport,
  UilPlus,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  ConfirmDialog,
  Divider,
  TextField,
  IconButton,
  Box,
  Waiting,
  FormLabel,
  Notification,
  Autocomplete,
} from '@xbotvn/mui';
import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import DropzoneModal from '../../../../components/DropzoneModal';
import PaginationClient from '../../../../components/PaginationClient';
import Table from '../../../../components/Table';
import {
  LISTING,
} from '../../../../libs/config';
import {
  cloneDeep,
  filter,
  getOr,
  forEach,
  map,
  values,
} from '../../../../libs/lodash';
import { getISBD } from '../../../../libs/utils';
import {
  FILE,
  CABINET,
} from '../../../../redux/actions/constants';
import {
  importExcel,
} from '../../Helpers';

export default function Warehouse() {
  const dispatch = useDispatch();

  const {
    cabinetData,
    warehouseData,
    storages,
    documents,
    handling,
    activeUnit,
  } = useSelector(({
    cabinet, storage, document, user, warehouse,
  }) => ({
    cabinetData: cabinet.data,
    warehouseData: warehouse.data,
    storages: storage.data,
    documents: document.data,
    handling: cabinet.handling,
    activeUnit: user.activeUnit,
  }));

  const [editor, setEditor] = useState();
  const [page, setPage] = useState([]);
  const [showImport, setShowImport] = useState();
  const [removeConfirm, setRemoveConfirm] = useState(false);

  const data = values(cabinetData).map(({ warehouseId, ...rest }) => ({ ...rest, warehouseId, warehouse: warehouseData?.[warehouseId]?.name }));

  useEffect(() => {
    forEach((storage) => {
      storages[storage?._id] = {
        ...storage,
        name: documents[storage.documentId].name,
      };
    }, storages);
  }, [documents], [storages]);
  const cols = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 100,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Phích trong tủ phích',
      accessor: 'poster',
      style: {
        width: 350,
      },
    },
    {
      Header: 'Số lượng',
      accessor: 'amount',
      style: {
        width: 150,
      },
    },
    {
      Header: '',
      accessor: '_id',
      disableFilters: true,
      style: {
        width: 150,
      },
      Cell: () => <Button size="small" color="primary" variant="outlined">Xuất phích</Button>,
    },
  ], []);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 100,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Tên',
      accessor: 'name',
      style: {
        width: 350,
      },
    },
    {
      Header: 'Ký hiệu',
      accessor: 'short',
      style: {
        width: 350,
      },
    },
    {
      Header: 'Kho',
      accessor: 'warehouse',
      style: {
        width: 350,
      },
    },
    {
      Header: () => (
        <Button
          startIcon={<UilPlus />}
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => setEditor({
            unitId: activeUnit,
            name: '',
          })}
        >
          Thêm
        </Button>
      ),
      id: 'add',
      style: {
        width: 250,
      },
      Cell: (rowProps) => {
        const rowValue = getOr({}, 'row.original', rowProps);
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => setEditor(cloneDeep(rowValue))}
              color="primary"
              size="small"
              sx={{ marginRight: '5px' }}
            >
              <UilPen />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              size="small"
              color="error"
              onClick={() => {
                setRemoveConfirm(rowValue._id);
              }}
              sx={{ marginLeft: '5px' }}
            >
              <UilTrash />
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ], []);
  const renderPagination = useMemo(() => (
    <PaginationClient
      rows={filter((rc) => rc.cabinetId === getOr('', '_id', editor), Object.values(storages).map(({
        documentId, ...rest
      }) => {
        const registrationNumber = documents?.[documentId]?.registrationNumber ?? '';
        const symbol = documents?.[documentId]?.symbol ?? '';
        const isbd = getISBD(rest);
        const poster = ` ${symbol || ''} ${registrationNumber || ''} - ${isbd}`;
        return ({
          ...rest,
          poster,
        });
      }))}
      onChange={({
        activeRows,
      }) => setPage(activeRows)}
    />
  ), [storages, editor]);

  const renderEditor = () => (
    <Dialog
      open
      onClose={() => setEditor()}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle onClose={() => setEditor()}>{editor._id ? 'Sửa' : 'Thêm'}</DialogTitle>
      <DialogContent>
        <TextField
          label="Tên"
          required
          fullWidth
          value={editor.name || ''}
          onChange={(e) => setEditor((prev) => ({
            ...prev,
            name: e.target.value,
          }))}
        />
        <TextField
          label="Ký hiệu"
          required
          fullWidth
          value={editor.short || ''}
          onChange={(e) => setEditor((prev) => ({
            ...prev,
            short: e.target.value,
          }))}
        />
        <Autocomplete
          options={map.convert({ cap: false })(({ name }, id) => ({ label: name, key: id }), warehouseData)}
          value={warehouseData?.[editor?.warehouseId]?.name ?? ''}
          onChange={(e, value) => {
            setEditor((prev) => ({ ...prev, warehouseId: value?.key }));
          }}
          renderInput={(params) => <TextField {...params} label="Kho" required />}
        />
        {editor._id && (
          <FormLabel label="Thông tin kho">
            <Table columns={cols} data={page} />
            {renderPagination}
          </FormLabel>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            if (!editor.name) {
              Notification.warn('Tên không được trống.');
              return;
            }
            if (!editor.short) {
              Notification.warn('Ký hiệu không được trống.');
              return;
            }
            if (!editor.warehouseId) {
              Notification.warn('Kho không được trống.');
              return;
            }
            dispatch({
              type: CABINET.handlers.update,
              data: editor,
              onSuccess: () => setEditor(),
            });
          }}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={() => setEditor()}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box>
      {editor ? renderEditor() : null}
      {handling && <Waiting />}
      {removeConfirm ? (
        <ConfirmDialog
          title="Bạn có chắc chắn muốn xóa?"
          color="error"
          confirmLabel="Xoá"
          cancelLabel="Huỷ"
          maxWidth="sm"
          fullWidth
          open
          onClose={() => setRemoveConfirm()}
          onConfirm={async () => {
            dispatch({
              type: CABINET.handlers.remove,
              _id: removeConfirm,
            });
            setRemoveConfirm();
          }}
        >
          <DialogContent />
        </ConfirmDialog>
      ) : null}
      {showImport && (
        <DropzoneModal
          onConfirm={(files) => {
            if (!files.length) return;
            importExcel({
              files,
              activeUnit,
              config: LISTING,
              onSuccess: (records) => {
                dispatch({
                  type: CABINET.handlers.import,
                  data: records,

                });
                setShowImport();
              },
            });
          }}
          onClose={() => setShowImport()}
        />
      )}
      <Box marginLeft={1} padding={1}>
        <Button
          startIcon={<UilUpload />}
          color="success"
          size="small"
          onClick={() => setShowImport(true)}
          sx={{
            marginRight: 1,
          }}
        >
          Nhập từ Excel
        </Button>
        <Button
          startIcon={<UilImport />}
          color="primary"
          size="small"
          onClick={() => dispatch({
            type: FILE.handlers.download,
            data: {
              unitId: activeUnit,
              fileName: 'Mau_Kho.xlsx',
            },
          })}
        >
          Tải file mẫu
        </Button>
      </Box>
      <Table columns={columns} data={data} alwaysShowPagination />
    </Box>
  );
}
