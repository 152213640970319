import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
  TextField,
  Box,
  NumberField,
  FormLabel,
  FormControl,
  Notification,
} from '@xbotvn/mui';
import cryptoRandomString from 'crypto-random-string';
import {
  get,
  reduce,
  size,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useState,
  useMemo,
  useEffect,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import { paginationApi, listingApi as itemsAPI } from '../../../api';
import SingleSelect from '../../../components/SingleSelect';
import Table from '../../../components/Table';
import {
  cloneDeep,
  map,
  omit,
  filter,
  values,
} from '../../../libs/lodash';
import { getBigestNum } from '../../../libs/utils';
import {
  DOCUMENT,
  STORAGE,
} from '../../../redux/actions/constants';
import * as Styles from '../styles';

export default function EditorModal({
  data, onClose,
}) {
  const dispatch = useDispatch();
  const {
    bookshelves,
    warehouses,
    documents,
    unitId,
  } = useSelector(({
    document,
    bookshelf,
    warehouse,
    user,
  }) => ({
    bookshelves: bookshelf?.data ?? {},
    warehouses: warehouse?.data ?? {},
    documents: document?.data ?? {},
    unitId: user?.activeUnit ?? '',
  }));

  const [editor, setEditor] = useState(data);
  const [page, setPage] = useState([]);
  useEffect(() => {
    paginationApi.getPage('documentItem', {
      condition: {
        unitId,
        documentId: data.documentId,
        storageId: data._id,
      },
    }).then(({ data: items }) => {
      setPage(items.records);
    });
  }, [data]);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 80,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Mã sách',
      accessor: '_id',
      style: {
        width: 250,
      },
    },
    {
      Header: 'Tình trạng',
      accessor: 'borrowed',
      style: {
        width: 250,
      },
      Cell: (rowProps) => (get(rowProps, 'value') ? 'Đang mượn' : null),
    },
  ], [data, page]);

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle onClose={onClose}>{editor?._id ? 'Sửa' : 'Thêm'}</DialogTitle>
      <DialogContent>
        <Box sx={{
          '& .MuiTextField-root': { m: 1 },
          display: 'flex',
        }}
        >
          {editor._id ? (
            <TextField
              disabled
              label="Tài liệu"
              required
              value={documents?.[editor?.documentId]?.name ?? ''}
              fullWidth
              sx={{
                margin: '8px 0px!important',
              }}
            />
          )
            : (
              <SingleSelect
                options={map(
                  ({
                    _id, name,
                  }) => ({
                    label: name,
                    value: _id,
                  }),
                  documents,
                )}
                label="Tài liệu"
                required
                value={editor?.documentId ?? ''}
                onChange={(value) => setEditor((prevData) => ({
                  ...prevData,
                  documentId: value,
                }))}
                InputProps={{
                  label: 'Tài liệu',
                  placeholder: 'Chọn tài liệu',
                  fullWidth: true,
                  sx: {
                    margin: '8px 0px!important',
                  },
                }}
              />
            )}
          <SingleSelect
            options={map(
              ({
                _id, name,
              }) => ({
                label: name,
                value: _id,
              }),
              bookshelves,
            )}
            clearable
            value={editor?.bookshelfId ?? ''}
            onChange={(value) => setEditor((prevData) => ({
              ...prevData,
              bookshelfId: value,
            }))}
            InputProps={{
              placeholder: 'Chọn Kệ sách',
              label: 'Kệ sách',
              sx: {
                margin: '8px 0px!important',
              },
            }}
          />
          <SingleSelect
            options={map(
              ({
                _id, name,
              }) => ({
                label: name,
                value: _id,
              }),
              warehouses,
            )}
            value={editor?.warehouseId ?? ''}
            onChange={(value) => setEditor((prevData) => ({
              ...prevData,
              warehouseId: value,
            }))}
            InputProps={{
              placeholder: 'Chọn Kho',
              label: 'Kho',
              sx: {
                margin: '8px 0px!important',
              },
            }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="start"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '33ch' },
          }}
        >
          <NumberField
            disabled={!!editor?._id}
            buttonPosition="none"
            max={100}
            value={editor?.amount ?? 0}
            label="Số lượng"
            onValueChange={({ floatValue }) => setEditor((prevData) => ({
              ...prevData,
              amount: floatValue,
            }))}
            sx={{
              margin: '8px 0px!important',
            }}
          />
          <Styles.InputDatePicker
            label="Ngày nhập"
            required
            dateFormat="dd/MM/yyyy"
            value={moment(editor?.dateAdded).toDate()}
            onChange={(date) => setEditor((prevValue) => ({
              ...prevValue,
              dateAdded: date ? date.toString() : '',
            }))}
            InputProps={{
              sx: {
                margin: '8px 0px!important',
              },
            }}
          />
        </Box>
        {editor._id ? (
          <>
            <Divider />
            <FormControl>
              <FormLabel>Danh sách mã</FormLabel>
            </FormControl>
            <Table columns={columns} data={values(page)} alwaysShowPagination />
            <Divider />
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            let cloned = cloneDeep(editor);
            if (!cloned.documentId) {
              Notification.warn('Chưa chọn Tài liệu.');
              return;
            }
            if (!cloned.dateAdded) {
              Notification.warn('Chưa chọn ngày nhập.');
              return;
            }
            if (!cloned.amount && !cloned._id) {
              Notification.warn('Chưa nhập số lượng.');
              return;
            }
            if (!cloned.warehouseId) {
              Notification.warn('Chưa chọn kho.');
              return;
            }
            cloned = omit('docName', cloned);
            cloned = omit('bookshelfName', cloned);
            cloned = omit('warehouseName', cloned);
            cloned.storageId = cryptoRandomString({
              length: 24,
            });
            dispatch({
              type: STORAGE.handlers.update,
              data: cloned,
              onSuccess: onClose(),
            });
            const { amount } = cloned;
            if (!cloned?._id && cloned.amount) {
              const selectedDoc = documents?.[cloned.documentId];
              const categoryDoc = filter((document) => document.categoryId === selectedDoc.categoryId)(documents);
              let registrationNumber = '';
              if (size(categoryDoc)) {
                const bigestCatRegNum = reduce(categoryDoc, (prev, cur) => (getBigestNum(cur.registrationNumber) > getBigestNum(prev.registrationNumber) ? cur : prev));
                const regNum = bigestCatRegNum.registrationNumber;
                const bigestRegNum = getBigestNum(regNum);
                if (selectedDoc.registrationNumber) {
                  registrationNumber = `${selectedDoc.registrationNumber},${amount === 1 ? bigestRegNum + 1 : `${bigestRegNum + 1}-${bigestRegNum + amount}`}`;
                } else {
                  registrationNumber = `${amount === 1 ? bigestRegNum + 1 : `${bigestRegNum + 1}-${bigestRegNum + amount}`}`;
                }
              } else {
                registrationNumber = amount === 1 ? '1' : `1-${amount}`;
              }
              dispatch({
                type: DOCUMENT.handlers.update,
                data: {
                  ...selectedDoc,
                  registrationNumber,
                },
                onSuccess: () => {
                  console.log('Updated RegNum');
                },
              });
              const records = [];
              let item = 0;
              while (item < cloned.amount) {
                records.push({
                  _id: cryptoRandomString({
                    length: 12,
                  }),
                  unitId,
                  documentId: cloned.documentId,
                  storageId: cloned.storageId,
                });
                item += 1;
              }
              itemsAPI.create({
                collection: 'documentItem',
                payload: {
                  data: records,
                },
              }).then(() => setPage(records)).catch((err) => Notification.error(err));
            }
          }}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={onClose}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditorModal.defaultProps = {
  data: {},
};

EditorModal.propTypes = {
  data: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
};
