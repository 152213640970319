import {
  all,
  put,
  takeEvery,
} from 'redux-saga/effects';

import {
  STAFF,
} from './constants';

function* storeStaff(data = {}) {
  yield put({
    type: STAFF.update,
    data,
  });
}

function* getStaff(data) {
  yield* storeStaff(data);
}

export default function* staffSaga() {
  yield all([
    yield takeEvery(STAFF.handlers.get, getStaff),
  ]);
}
