// ref: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types

export const officeFileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

export const archiveFileTypes = [
  'application/zip',
  'application/vnd.rar',
  'application/x-7z-compressed',
  'application/x-tar',
  'application/gzip',
  'application/x-bzip',
  'application/x-bzip2',
];

export const patchFileTypes = [
  'text/x-diff',
  'text/x-patch',
  'application/x-patch',
];

export const imageFileTypes = [
  'image/png',
  'image/jpeg',
  'image/jpg',
];

export const videoFileTypes = [
  'application/mp4',
  'audio/mp4',
  'video/mp4',
];

export const defaultAllowFileTypes = [
  ...officeFileTypes,
  ...archiveFileTypes,
  ...patchFileTypes,
  ...imageFileTypes,
  ...videoFileTypes,
];
