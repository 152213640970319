import styled from '@emotion/styled';

export const QR = styled.div`
width: 60%;
padding: 3px 5px 0px 8px;
text-align: center;
align-self: center;
`;

export const TitleBarcode = styled.div`
text-align: center;
font-size: 14px;
height: 35px;
font-weight: 500;
padding: 0px 5px;
`;

export const TitleQRCode = styled.div`
width: 100%;
font-size: 14px;
font-weight: 500;
`;

export const GroupQRCode = styled.div`
display: flex;
width: 100%;
`;

export const GroupCode = styled.div`
overflow: auto;
height: 500px;

.page-break {
  padding-top: 10px;
}
svg {
  width: 180px;
  height: 50px;
}
`;

export const BC = styled.div`
  text-align: center;
`;

export const Code = styled.div`
  margin: 3px;
  border: solid 1px;
  display: inline-flex;
  width: 32%;
  height: 128px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px;
`;

export const NumberPage = styled.p`
  text-align: center;
`;

export const UnitName = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  padding: 5px;
  border-bottom: solid 1px #000;
  text-align: center;
  font-weight: 500;
`;
