export const DEFAULT_ROUTE = '/';

export const Routes = {
  home: '/',
  login: '/login',
  borrow: '/borrow',
  admin: '/admin',
  xbot: '/xbot',
  children: '/children',
};
