import { UilCreditCard, UilBox } from '@iconscout/react-unicons';
import {
  Button,
  Toolbar,
  Box,
} from '@xbotvn/mui';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import Members from './Members';

export default function User() {
  const {
    userInitial,
  } = useSelector(({
    user,
  }) => ({
    userInitial: user,
  }));

  const [menuActive, setMenu] = useState('current');

  const renderContent = useMemo(() => <Members type={menuActive} />, [menuActive]);

  return userInitial._id ? (
    <div>
      <Toolbar sx={{
        display: 'flex',
        minHeight: '56px !important',
        justifyContent: 'space-between',
        boxShadow:
        'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
      }}
      >
        <Box>
          <Button
            variant="text"
            startIcon={<UilCreditCard />}
            color={menuActive === 'current' ? 'primary' : 'inherit'}
            onClick={() => setMenu('current')}
          >
            Hiện tại
          </Button>
          <Button
            variant="text"
            startIcon={<UilBox />}
            color={menuActive === 'expire' ? 'primary' : 'inherit'}
            onClick={() => setMenu('expire')}
          >
            Hết hạn
          </Button>
        </Box>
      </Toolbar>
      {renderContent}
    </div>
  ) : null;
}
