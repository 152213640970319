import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Notification,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import SingleSelect from '../../../components/SingleSelect';
import {
  cloneDeep,
  find,
  get,
  getOr,
  keyBy,
  map,
  filter,
} from '../../../libs/lodash';

export default function BorrowerInfoModal({
  data,
  onClose,
  onSubmit,
}) {
  const {
    units,
    members,
  } = useSelector(({
    unit,
    user,
    member,
  }) => ({
    units: get(user.activeUnit, unit.data),
    members: member.data,
  }));

  const [borrowerInfo, setBorrowerInfo] = useState();

  return (
    <Dialog
      open
      onClose={() => {
        setBorrowerInfo();
        onClose();
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        onClose={() => {
          setBorrowerInfo();
          onClose();
        }}
      >
        Chọn thông tin người mượn và ngày trả tài liệu.
      </DialogTitle>
      <DialogContent>
        <SingleSelect
          value={getOr('', ['userInfo', 'email'], borrowerInfo)}
          InputProps={{
            placeholder: 'Chọn người mượn',
            label: 'Tên người mượn',
            required: true,
          }}
          options={map(
            ({
              name, email,
            }) => ({
              label: name, value: email,
            }),
            {
              ...keyBy('email', getOr([], 'members', units)),
              ...keyBy('email', filter((mb) => new Date() < new Date(mb.endDate), members)),
            },
          )}
          onChange={(value) => setBorrowerInfo((prevValue) => ({
            ...prevValue,
            userInfo: find((obj) => obj.email === value, {
              ...keyBy('email', getOr([], 'members', units)),
              ...keyBy('email', filter((mb) => new Date() < new Date(mb.endDate), members)),
            }),
          }))}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            if (!borrowerInfo?.userInfo) {
              Notification.warn('Chưa chọn người mượn.');
            } else {
              onSubmit(map(
                (obj) => {
                  const addUserInfo = cloneDeep(obj);
                  addUserInfo.userInfo = borrowerInfo.userInfo;
                  return addUserInfo;
                },
                data,
              ));
              setBorrowerInfo();
              onClose();
            }
          }}
        >
          Thêm
        </Button>
        <Button
          color="error"
          onClick={() => {
            setBorrowerInfo();
            onClose();
          }}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
}

BorrowerInfoModal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
