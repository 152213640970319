import {
  api, getOptions,
} from './helpers';

const get = (info) => {
  const {
    collection, payload,
  } = info;
  return api.post(`/${collection}/get`, payload, getOptions());
};

const create = (info) => {
  const {
    collection, payload,
  } = info;
  return api.post(`/${collection}/create`, payload, getOptions());
};

const update = (info) => {
  const {
    collection, payload,
  } = info;
  return api.post(`/${collection}/update`, payload, getOptions());
};

const updateRecords = (info) => {
  const {
    collection, payload,
  } = info;
  return api.post(`/${collection}/updateRecords`, payload, getOptions());
};

const remove = (info) => {
  const {
    collection, payload,
  } = info;
  return api.post(`/${collection}/remove`, payload, getOptions());
};

const importData = (info) => {
  const {
    collection, payload,
  } = info;
  return api.post(`/${collection}/import`, payload, getOptions());
};

const updates = (info) => {
  const {
    collection, payload,
  } = info;
  return api.post(`/${collection}/updates`, payload, getOptions());
};

const checkInfo = (info) => {
  const {
    collection, payload,
  } = info;
  return api.post(`/${collection}/check`, payload, getOptions());
};

const register = (info) => {
  const {
    collection, payload,
  } = info;
  return api.post(`/${collection}/register`, payload, getOptions());
};

export default {
  get,
  create,
  update,
  updateRecords,
  remove,
  register,
  importData,
  checkInfo,
  updates,
};
