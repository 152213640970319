import { parse, addDays } from '@xbotvn/utils/date';
import cryptoRandomString from 'crypto-random-string';
import { intToExcelCol } from 'excel-column-name';
import _ from 'lodash';
import moment from 'moment';
import XLSX from 'xlsx';

import {
  cloneDeep,
  find,
  flow,
  forEach,
  getOr,
  groupBy,
  map,
  replace,
  split,
} from '../../libs/lodash';
import { SUBJECTS_BOOK } from '../../libs/utils';

const importExcelOld = (value) => {
  const {
    files, activeUnit, config, onSuccess,
  } = value;
  const reader = new FileReader();
  reader.onload = () => {
    const wb = XLSX.read(reader.result, {
      type: 'binary', cellDates: true,
    });
    const data = XLSX.utils.sheet_to_json(
      wb.Sheets[wb.SheetNames[0]],
      {
        header: 1,
        skipHeader: true,
        blankrows: false,
        range: 1,
      },
    );
    if (data.length) {
      const records = [];
      forEach(
        (rc) => {
          const obj = {};
          forEach(
            ({
              key, no, format,
            }) => {
              if (format === 'date') {
                obj[key] = moment(getOr('', no, rc), 'DD/MM/YYYY').toDate().toString();
              } else {
                obj[key] = getOr('', no, rc);
              }
            },
            config,
          );
          obj._id = cryptoRandomString({
            length: 12,
          });
          obj.unitId = activeUnit;
          records.push(obj);
        },
        data,
      );
      if (records.length) {
        onSuccess(records);
      }
    }
  };
  reader.readAsBinaryString(files[0]);
};

const importExcel = (value, startRow, documentData) => {
  const {
    files, activeUnit, config, onSuccess,
  } = value;
  const reader = new FileReader();
  reader.onload = () => {
    const wb = XLSX.read(reader.result, {
      type: 'binary', cellDates: true,
    });
    const data = XLSX.utils.sheet_to_json(
      wb.Sheets[wb.SheetNames[0]],
      {
        header: 'A',
        skipHeader: true,
        range: startRow ?? 1,
        raw: false,
      },
    );
    if (data.length) {
      const records = [];
      forEach(
        (rc) => {
          const obj = {};
          const errors = [];
          obj._id = cryptoRandomString({
            length: 12,
          });
          obj.unitId = activeUnit;
          forEach(
            ({
              key,
              no,
              format,
              required,
              name,
              code,
              related,
            }) => {
              let cell = getOr('', intToExcelCol(no + 1), rc);
              let symbol;
              switch (format) {
                case 'number':
                  cell = parseFloat(cell);
                  break;
                case 'date':
                  cell = (cell && cell instanceof Date
                    ? addDays(cell, 1)
                    : parse(cell, 'dd/MM/yyyy', new Date())).getTime();
                  break;
                case 'dateString':
                  cell = cell && cell instanceof Date
                    ? addDays(cell, 1)
                    : moment(cell).format();
                  break;
                case 'array':
                  cell = cell.split(',').map((field) => Object.values(documentData?.[code] ?? {}).find(({ name: title }) => replace(/\s/g, '', field).toLocaleLowerCase() === replace(/\s/g, '', title)?.toLocaleLowerCase())?._id ?? field);
                  break;
                default:
                  break;
              }
              if (related) {
                if (cell) {
                  obj[related] = !obj[related] ? {
                    [key]: cell,
                    _id: cryptoRandomString({
                      length: 12,
                    }),
                  } : obj[related] = {
                    ...obj[related],
                    [key]: cell,
                  };
                }
              } else if (code && format !== 'array') {
                cell = Object.values(documentData?.[code] ?? {}).find(({ name: title }) => replace(/\s/g, '', cell).toLocaleLowerCase() === replace(/\s/g, '', title)?.toLocaleLowerCase())?._id;
              }
              if (cell && !related) {
                obj[key] = cell;
              }
              if (key === 'subject') {
                obj[key] = _.findKey(SUBJECTS_BOOK, (subjectName) => subjectName === cell) || '';
              }
              if (code === 'categories') {
                symbol = documentData?.categories?.[cell]?.symbol;
              }
              if (symbol) obj.symbol = symbol;
              if (required) {
                if (!cell) {
                  errors.push(`${name} là thông tin bắt buộc`);
                } else if (!cell) {
                  errors.push(`${name} chưa điền đúng thông tin theo danh mục`);
                }
              }
            },
            config,
          );
          if (errors.length) obj.errors = errors.join('; ');
          records.push(obj);
        },
        data,
      );
      if (records.length) {
        onSuccess(cloneDeep(records));
      }
    }
  };
  reader.readAsBinaryString(files[0]);
};

const handleCategoryRegNumber = ({
  data, store,
}) => {
  const newData = [];
  flow(
    groupBy('categoryId'),
    map.convert({
      cap: false,
    })((records, key) => {
      let count = getOr(0, [key, 'addTo'], store.category);
      forEach(
        (record) => {
          const registrationNumber = getOr('', [key, 'symbol'], store.category);
          const newRecord = {
            ...record,
            registrationNumber: `${registrationNumber}-${count + 1}`,
          };
          count += 1;
          newData.push(newRecord);
        },
        records,
      );
    }),
  )(data);
  return newData;
};

const getCategorySymbol = (value) => {
  let symbol = '';
  forEach(
    (val) => {
      symbol += val.charAt(0).toLocaleUpperCase();
    },
    split(' ', value),
  );
  return symbol;
};

const getNewId = () => cryptoRandomString({
  length: 24,
});

const handleRow = ({
  record, stores, newListing, unitId,
}) => {
  const listing = {};
  const newRecord = cloneDeep(record);
  const newStores = {
    category: {
      ...stores.category,
      ...newListing.category,
    },
    type: {
      ...stores.type,
      ...newListing.type,
    },
    author: {
      ...stores.author,
      ...newListing.author,
    },
    producer: {
      ...stores.producer,
      ...newListing.producer,
    },
  };

  const findCategoryId = find((obj) => replace(/\s/g, '', obj.name).toLocaleLowerCase() === replace(/\s/g, '', newRecord.categoryId).toLocaleLowerCase(),
    newStores.category);

  if (findCategoryId) {
    newRecord.categoryId = findCategoryId._id;
  } else {
    const newCategoryId = getNewId();
    newRecord.categoryId = newCategoryId;
    listing.category = {
      [newCategoryId]: {
        _id: newCategoryId,
        unitId,
        name: record.categoryId,
        symbol: getCategorySymbol(record.categoryId),
      },
    };
  }

  const findTypeId = find((obj) => replace(/\s/g, '', obj.name).toLocaleLowerCase() === replace(/\s/g, '', newRecord.typeId).toLocaleLowerCase(),
    newStores.type);
  if (findTypeId) {
    newRecord.typeId = findTypeId._id;
  } else {
    const newTypeId = getNewId();
    newRecord.typeId = newTypeId;
    listing.type = {
      [newTypeId]: {
        _id: newTypeId,
        unitId,
        name: record?.typeId ?? '',
      },
    };
  }
  const findAuthorId = find((obj) => replace(/\s/g, '', obj.name).toLocaleLowerCase() === replace(/\s/g, '', newRecord.authorId).toLocaleLowerCase(),
    newStores.author);
  if (findAuthorId) {
    newRecord.authorId = findAuthorId._id;
  } else {
    const newAuthorId = getNewId();
    newRecord.authorId = newAuthorId;
    listing.author = {
      [newAuthorId]: {
        _id: newAuthorId,
        unitId,
        name: record.authorId,
      },
    };
  }

  const findProducerId = find((obj) => replace(/\s/g, '', obj.name).toLocaleLowerCase() === replace(/\s/g, '', newRecord.producerId).toLocaleLowerCase(),
    newStores.producer);
  if (findProducerId) {
    newRecord.producerId = findProducerId._id;
  } else {
    const newProducerId = getNewId();
    newRecord.producerId = newProducerId;
    listing.producer = {
      [newProducerId]: {
        _id: newProducerId,
        unitId,
        name: record.producerId,
      },
    };
  }

  return {
    newRecord,
    listing,
  };
};

const handleRows = ({
  records, stores, unitId,
}) => {
  const newRecords = [];
  let newListing = {};
  forEach(
    (rc) => {
      const {
        newRecord,
        listing,
      } = handleRow({
        record: rc, stores, newListing, unitId,
      });
      newRecords.push(newRecord);
      newListing = {
        category: {
          ...newListing.category,
          ...listing.category,
        },
        author: {
          ...newListing.author,
          ...listing.author,
        },
        producer: {
          ...newListing.producer,
          ...listing.producer,
        },
        type: {
          ...newListing.type,
          ...listing.type,
        },
      };
    },
    records,
  );
  const categoryData = {
    data: newRecords,
    store: {
      category: {
        ...stores.category,
        ...newListing.category,
      },
      author: {
        ...stores.author,
        ...newListing.author,
      },
      producer: {
        ...stores.producer,
        ...newListing.producer,
      },
    },
  };
  let newData = [];
  const storageData = [];
  const documentItems = [];
  categoryData.store.typeId = {
    ...stores.type,
    ...newListing.type,
  };
  newData = handleCategoryRegNumber(categoryData);

  return {
    newData,
    newListing,
    storageData,
    documentItems,
  };
};

export {
  importExcel,
  handleRows,
  importExcelOld,
};
