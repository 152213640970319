import {
  UilImageUpload, UilPlusCircle, UilEditAlt, UilTrashAlt,
} from '@iconscout/react-unicons';
import {
  Box,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Notification, Stack, TextField, Typography, DialogContentText, ConfirmDialog, Divider, ButtonGroup,
} from '@xbotvn/mui';
import React, {
  useMemo, useState,
} from 'react';
import Dropzone from 'react-dropzone';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { v4 as uuid } from 'uuid';

import Table from '../../../components/Table';
import {
  RESOURCES,
} from '../../../libs/config';
import {
  cloneDeep,
  filter,
  getOr,
  keyBy,
  sortBy,
  values,
} from '../../../libs/lodash';
import {
  imageExists,
  uploadFileHandler,
} from '../../../libs/utils';
import {
  UNIT,
} from '../../../redux/actions/constants';

export default function Links() {
  const dispatch = useDispatch();

  const {
    activeUnit,
    activeUnitData,
  } = useSelector(({
    user, unit,
  }) => ({
    activeUnit: user.activeUnit,
    activeUnitData: unit.data[user.activeUnit],
  }));

  const [edit, setEdit] = useState();
  const [removeConfirm, setRemoveConfirm] = useState(false);

  const coverPage = useMemo(() => `${RESOURCES}/${activeUnit}/${getOr('', 'cover', edit)}`, [edit]);

  const partnerLinks = useMemo(() => getOr([], 'partnerLinks', activeUnitData), [activeUnitData]);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      accessor: 'no',
      disableFilters: true,
      style: {
        width: 100,
      },
    },
    {
      Header: 'Đường dẫn',
      accessor: 'url',
      style: {
        width: 350,
      },
    },
    {
      Header: 'Ảnh bìa',
      accessor: 'cover',
      style: {
        width: 350,
      },
    },
    {
      id: 'add',
      style: {
        width: 250,
      },
      Header: () => (
        <Button
          color="primary"
          size="small"
          sx={{ mt: 2 }}
          startIcon={<UilPlusCircle />}
          onClick={() => setEdit({
            url: '',
            cover: '',
            no: 1,
          })}
        >
          Thêm
        </Button>
      ),
      Cell: (rowProps) => {
        const rowValue = getOr({}, 'row.original', rowProps);
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => {
                setEdit(cloneDeep(rowValue));
              }}
              color="primary"
              size="small"
              sx={{ mr: '5px' }}
            >
              <UilEditAlt />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              color="error"
              size="small"
              sx={{ ml: '5px' }}
              onClick={() => {
                setRemoveConfirm(rowValue?._id);
              }}
            >
              <UilTrashAlt />
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ], []);

  const renderEditModal = useMemo(() => (
    <Dialog
      open
      onClose={() => setEdit()}
      fullWidth
    >
      <DialogTitle>{getOr('', '_id', edit) ? 'Chỉnh sửa' : 'Thêm mới'}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Ảnh bìa
          </Typography>
          {imageExists(coverPage) ? (
            <img
              style={{
                width: '100%',
                height: '50%',
              }}
              src={coverPage}
              alt="banner"
            />
          ) : null}
          <TextField
            fullWidth
            label="Đường dẫn"
            required
            value={getOr('', 'url', edit)}
            onChange={(val) => setEdit((prev) => ({
              ...prev,
              url: val.target.value,
            }))}
          />
          <Stack
            direction="row"
            alignItems="flex-end"
          >
            <TextField
              type="number"
              label="STT"
              value={getOr('', 'no', edit)}
              onChange={(val) => setEdit((prev) => ({
                ...prev,
                no: val.target.value,
              }))}
            />
            <Dropzone
              accept=".jpg, .jpeg, .png"
              multiple={false}
              maxSize={5 * 1024 * 1024 * 1024} // 5MB
              onDrop={(files) => {
                uploadFileHandler({
                  unitID: activeUnit,
                  files,
                  onSuccess: (fileName) => setEdit((prev) => ({
                    ...prev,
                    cover: fileName,
                  })),
                });
              }}
            >
              {({
                getRootProps,
                getInputProps,
              }) => ((
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button
                    sx={
                      { mb: 0.85 }
                    }
                    color="primary"
                    startIcon={<UilImageUpload />}
                  >
                    Tải ảnh lên
                  </Button>
                </div>
              ))}
            </Dropzone>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            if (!getOr('', 'url', edit)) {
              Notification.warn('Đường dẫn không được trống.');
              return;
            }
            const newData = keyBy('_id', cloneDeep(partnerLinks));
            const {
              _id, url, no, cover,
            } = edit;
            if (_id) {
              newData[_id].url = url;
              newData[_id].no = no;
              newData[_id].cover = cover;
            } else {
              const id = uuid();
              newData[id] = { ...edit, _id: id };
            }
            dispatch({
              type: UNIT.handlers.update,
              data: {
                _id: activeUnit,
                partnerLinks: values(newData),
              },
            });
            setEdit();
          }}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={() => setEdit()}
        >
          Thoát
        </Button>
      </DialogActions>
    </Dialog>
  ), [edit]);

  const renderConfirmDialog = useMemo(() => (
    <ConfirmDialog
      title="Bạn có chắc chắn muốn xóa?"
      color="error"
      Icon={UilTrashAlt}
      confirmLabel="Xoá"
      cancelLabel="Huỷ"
      maxWidth="sm"
      fullWidth
      onConfirm={async () => {
        dispatch({
          type: UNIT.handlers.update,
          data: {
            _id: activeUnit,
            partnerLinks: filter((link) => link?._id !== removeConfirm, partnerLinks),
          },
        });
        setRemoveConfirm();
      }}
      open
      onClose={() => setRemoveConfirm()}
    >
      <DialogContentText />
    </ConfirmDialog>
  ));

  return (
    <>
      {removeConfirm && renderConfirmDialog}
      {edit && renderEditModal}
      <Table columns={columns} data={sortBy(['no'], partnerLinks)} alwaysShowPagination />
    </>
  );
}
