import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@xbotvn/mui';
import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  RESOURCES,
} from '../../libs/config';
import { getSubjectImage, SUBJECTS_BOOK } from '../../libs/utils';

import Layout from './Layout';

export default function Children() {
  const {
    unitData, activeUnit,
  } = useSelector(({
    unit, user,
  }) => ({
    unitData: unit.data?.[user?.activeUnit] || {},
    activeUnit: user?.activeUnit,
  }));

  const [subject, setSubject] = useState();

  if (subject) {
    return (
      <Layout subject={subject} setSubject={setSubject} />
    );
  }
  return (
    <div style={{ padding: 100, paddingTop: 20 }}>
      <Box textAlign="center">
        {_.map(SUBJECTS_BOOK, (name, key) => {
          const file = unitData?.subject?.[key];
          const imageURL = file ? `${RESOURCES}/${activeUnit}/${file}` : '';
          return (
            <Box component="div" width="25%" key={name} sx={{ display: 'inline-block' }} margin={2}>
              <Card>
                <CardActionArea onClick={() => setSubject(key)}>
                  <CardMedia
                    component="img"
                    height="200px"
                    image={getSubjectImage(imageURL, `${key}.png`)}
                    alt={name}
                    style={{ objectFit: 'fill' }}
                  />
                  <CardContent>
                    <Typography align="center" gutterBottom variant="h5" component="div">{name}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>
          );
        })}
      </Box>
    </div>
  );
}
