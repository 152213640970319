/* eslint-disable no-param-reassign */
import {
  Button, Grid, Typography, Waiting,
} from '@xbotvn/mui';
import _ from 'lodash';
import { getOr } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isValidUrl, SUBJECTS_BOOK } from '../../libs/utils';

export default function Books({ subject }) {
  const {
    handling, childrenBooks,
  } = useSelector(({
    childrenBook,
  }) => ({
    handling: getOr(false, 'handling', childrenBook),
    childrenBooks: childrenBook.data,
  }));
  if (handling) {
    return <Waiting fullscreen />;
  }

  const data = useMemo(() => _.filter(childrenBooks, ({
    subject: bookSubject, name, coverLink, bookLink,
  }) => bookSubject === subject && name && coverLink && bookLink && isValidUrl(coverLink)), [subject, childrenBooks]);

  return (
    <div>
      <Typography variant="h5">{SUBJECTS_BOOK?.[subject]}</Typography>
      <Grid container spacing={5} mt={2}>
        {_.map(data, (item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item._id}>
            <Button
              onClick={() => window.open(item.bookLink, '_blank')}
              variant="text"
              style={{
                height: 250,
                width: '100%',
                marginBottom: 20,
                borderStyle: 'solid',
                borderWidth: 1,
                borderRadius: 5,
              }}
              startIcon={(
                <img
                  alt={item.name}
                  loading="lazy"
                  src={item.coverLink}
                  width="100%"
                  height={248}
                  style={{
                    objectFit: 'fill',
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = `${subject}.png`;
                  }}
                />
              )}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

Books.propTypes = {
  subject: PropTypes.string.isRequired,
};
