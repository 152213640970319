import {
  UilTrash,
  UilPen,
  UilUpload,
  UilImport,
  UilPlus,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  ConfirmDialog,
  Divider,
  TextField,
  IconButton,
  Box,
  Notification,
  Waiting,
} from '@xbotvn/mui';
import React, {
  useMemo, useState,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import { paginationApi } from '../../../../api';
import DropzoneModal from '../../../../components/DropzoneModal';
import Table from '../../../../components/Table';
import {
  LISTING,
} from '../../../../libs/config';
import {
  cloneDeep,
  getOr,
  values,
} from '../../../../libs/lodash';
import {
  FILE,
  PRODUCER,
} from '../../../../redux/actions/constants';
import {
  importExcel,
} from '../../Helpers';

export default function Producer() {
  const dispatch = useDispatch();

  const {
    producerData,
    handling,
    activeUnit,
  } = useSelector(({
    producer, user,
  }) => ({
    producerData: producer.data,
    handling: producer.handling,
    activeUnit: user.activeUnit,
  }));

  const [editor, setEditor] = useState();
  const [showImport, setShowImport] = useState();
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 100,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Tên',
      accessor: 'name',
      style: {
        width: 350,
      },
    },
    {
      Header: () => (
        <Button
          startIcon={<UilPlus />}
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => setEditor({
            unitId: activeUnit,
            name: '',
          })}
        >
          Thêm
        </Button>
      ),
      id: 'add',
      style: {
        width: 250,
      },
      Cell: (rowProps) => {
        const rowValue = getOr({}, 'row.original', rowProps);
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => setEditor(cloneDeep(rowValue))}
              color="primary"
              size="small"
              sx={{ marginRight: '5px' }}
            >
              <UilPen />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              size="small"
              color="error"
              onClick={() => {
                setRemoveConfirm(rowValue._id);
              }}
              sx={{ marginLeft: '5px' }}
            >
              <UilTrash />
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ], []);

  const renderEditor = () => (
    <Dialog
      open
      onClose={() => setEditor()}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={() => setEditor()}>{editor._id ? 'Sửa' : 'Thêm'}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          required
          value={editor.name || ''}
          label="Tên"
          onChange={(e) => setEditor((prev) => ({
            ...prev,
            name: e.target.value,
          }))}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            if (!editor.name) {
              Notification.warn('Tên không được trống.');
              return;
            }
            dispatch({
              type: PRODUCER.handlers.update,
              data: editor,
              onSuccess: () => setEditor(),
            });
          }}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={() => setEditor()}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box>
      {editor ? renderEditor() : null}
      {(handling || waiting) && <Waiting />}
      {removeConfirm ? (
        <ConfirmDialog
          title="Bạn có chắc chắn muốn xóa?"
          color="error"
          confirmLabel="Xoá"
          cancelLabel="Huỷ"
          maxWidth="sm"
          fullWidth
          open
          onClose={() => setRemoveConfirm()}
          onConfirm={async () => {
            setWaiting(true);
            const { data } = await paginationApi.getCount('document', {
              condition: {
                unitId: activeUnit,
                typeId: removeConfirm,
                _id: { $ne: removeConfirm },
              },
            });
            const {
              count,
            } = data;
            if (count) {
              setWaiting(false);
              Notification.warn(`Có ${count} tài liệu đang sử dụng loại sách này.`);
            } else {
              dispatch({
                type: PRODUCER.handlers.remove,
                _id: removeConfirm,
              });
              setWaiting(false);
            }
            setRemoveConfirm();
          }}
        >
          <DialogContent />
        </ConfirmDialog>
      ) : null}
      {showImport && (
        <DropzoneModal
          onConfirm={(files) => {
            if (!files.length) return;
            importExcel({
              files,
              activeUnit,
              config: LISTING,
              onSuccess: (records) => {
                dispatch({
                  type: PRODUCER.handlers.import,
                  data: records,
                });
                setShowImport();
              },
            });
          }}
          onClose={() => setShowImport()}
        />
      )}
      <Box padding={1} marginLeft={1}>
        <Button
          startIcon={<UilUpload />}
          color="success"
          size="small"
          onClick={() => setShowImport(true)}
          sx={{ marginRight: 1 }}
        >
          Nhập từ Excel
        </Button>
        <Button
          startIcon={<UilImport />}
          color="primary"
          size="small"
          onClick={() => dispatch({
            type: FILE.handlers.download,
            data: {
              unitId: activeUnit,
              fileName: 'Mau_NXB.xlsx',
            },
          })}
        >
          Tải file mẫu
        </Button>
      </Box>
      <Table
        columns={columns}
        data={values(producerData)}
        alwaysShowPagination
      />
    </Box>
  );
}
