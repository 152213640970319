import PropTypes from 'prop-types';
import React from 'react';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';

import { map } from '../../libs/lodash';

import * as Styled from './styles';

function ExportCode({ data, unitName, typeCode }) {
  const displayTitle = (value) => {
    if (value.length > 65) return `${value.slice(0, 65)}...`;
    return value;
  };
  return typeCode === 'qr' ? (
    <Styled.GroupCode>
      {map.convert({ cap: false })(
        (codes, index) => (
          <div key={index} className={index ? 'page-break' : 'group-codes'}>
            {map(
              ({ name, _id }) => (
                <Styled.Code key={_id}>
                  <Styled.GroupQRCode>
                    <Styled.TitleQRCode>
                      {name}
                    </Styled.TitleQRCode>
                    <Styled.QR>
                      <QRCode size={70} value={_id} />
                    </Styled.QR>
                  </Styled.GroupQRCode>
                </Styled.Code>
              ),
              codes,
            )}
          </div>
        ),
        data,
      )}
    </Styled.GroupCode>
  ) : (
    <Styled.GroupCode>
      {map.convert({ cap: false })(
        (codes, index) => (
          <div key={index} className={index ? 'page-break' : 'group-codes'}>
            {map(
              ({ name, _id }) => (
                <Styled.Code key={_id}>
                  <div style={{ width: '100%' }}>
                    <Styled.UnitName>
                      {unitName.toUpperCase()}
                    </Styled.UnitName>
                    <Styled.BC>
                      <Barcode fontSize={50} value={_id} />
                    </Styled.BC>
                    <Styled.TitleBarcode>
                      {displayTitle(name)}
                    </Styled.TitleBarcode>
                  </div>
                </Styled.Code>
              ),
              codes,
            )}
            <Styled.NumberPage>{index + 1}</Styled.NumberPage>
          </div>
        ),
        data,
      )}
    </Styled.GroupCode>
  );
}

ExportCode.propTypes = {
  unitName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape())).isRequired,
  typeCode: PropTypes.string.isRequired,
};

export default ExportCode;
