import {
  Autocomplete,
  TextField,
  Waiting,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import {
  filterApi,
} from '../../../api';
import {
  forEach,
  getOr,
  map,
} from '../../../libs/lodash';

import * as Styles from './styles';

export default function Filter({
  filters,
  onChange,
  filterName,
  filterType,
}) {
  const {
    unitId,
    filterData,
    handling,
    uid,
    listing,
  } = useSelector(({
    user,
    ...reducer
  }) => ({
    uid: user._id,
    unitId: user.activeUnit,
    filterData: reducer?.[filterType]?.data,
    handling: reducer?.[filterType]?.handling,
    listing: reducer?.listing?.data,
  }));
  const [countRecord, setCountRecord] = useState({});
  const [open, setOpen] = useState(false);

  const selectFilterData = useMemo(() => (['age', 'documentSubject', 'subCategory'].includes(filterType) ? listing?.[filterType] : filterData), [filterType, listing, filterData]);

  useEffect(() => {
    if (open) {
      const conditions = map(({
        _id,
      }) => ({
        [`${filterType}Id`]: _id,
        unitId,
      }), selectFilterData);
      filterApi.getCountMultiple({
        conditions,
      }).then((result) => {
        const converted = {};
        const count = getOr([], ['data', 'count'], result);
        forEach.convert({
          cap: false,
        })(
          (obj, index) => {
            converted[obj?.[`${filterType}Id`]] = count[index];
          },
          conditions,
        );
        setCountRecord(converted);
      });
    }
  }, [open]);

  const renderFilter = useMemo(() => (
    <Autocomplete
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      multiple
      options={Object.values(selectFilterData ?? {}).map(({ _id, name }) => ({ label: name, value: _id }))}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={filterName}
        />
      )}
      onChange={(e, data) => {
        const newFilters = {};
        data.forEach(({ value }) => {
          newFilters[value] = selectFilterData?.[value];
        });
        onChange(newFilters);
      }}
      value={Object.values(filters).map(({ _id, name }) => ({ label: name, value: _id }))}
      renderOption={(props, option) => (
        <li {...props}>
          {option.label}
          {' '}
          (
          {countRecord?.[option?.value] ?? 0}
          )
        </li>
      )}
      isOptionEqualToValue={(option, { value }) => option.value === value}
    />
  ), [filters, selectFilterData, countRecord]);

  if (handling) return <Waiting fullscreen />;
  return (
    <Styles.Container>
      { uid && renderFilter}
    </Styles.Container>
  );
}

Filter.defaultProps = {
  filters: {},
};

Filter.propTypes = {
  filters: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  filterName: PropTypes.string.isRequired,
  filterType: PropTypes.string.isRequired,
};
