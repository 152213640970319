import {
  Checkbox,
  Typography,
  Box,
  FormLabel,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import {
  filterApi,
} from '../../../api';
import {
  cloneDeep,
} from '../../../libs/lodash';

import * as Styles from './styles';

export default function FilterNewDocs({
  filters,
  onChange,
}) {
  const {
    unitId,
    uid,
  } = useSelector(({
    user,
  }) => ({
    uid: user._id,
    unitId: user.activeUnit,
  }));

  const [countRecord, setCountRecord] = useState(0);

  useEffect(() => {
    const conditions = [{
      new: true,
      unitId,
    }];
    filterApi.getCountMultiple({
      conditions,
    }).then((result) => {
      setCountRecord(result?.data?.count?.[0] ?? 0);
    });
  }, []);
  return (
    <Box sx={{
      marginBottom: '5px',
      marginLeft: '10px',
      padding: 1,
      boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
      borderRadius: 1,
    }}
    >
      {
        uid && (
          <>
            <FormLabel>Sách</FormLabel>
            <Styles.GroupCheckbox>
              <Checkbox
                checked={filters?.new ?? false}
                onChange={() => {
                  const newFilters = cloneDeep(filters);
                  if (newFilters.new) {
                    delete newFilters.new;
                  } else {
                    newFilters.new = true;
                  }
                  onChange(newFilters);
                }}
              />
              <Styles.Name>
                <Typography>Mới</Typography>
              </Styles.Name>
              <Styles.Count>
                <Typography>{countRecord}</Typography>
              </Styles.Count>
            </Styles.GroupCheckbox>
          </>
        )
      }

    </Box>
  );
}

FilterNewDocs.defaultProps = {
  filters: {},
};

FilterNewDocs.propTypes = {
  filters: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
};
