import {
  UilFileExport,
} from '@iconscout/react-unicons';
import {
  ButtonGroup,
  IconButton,
  Button,
  Waiting,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
  Typography,
} from '@xbotvn/mui';
import html2pdf from 'html2pdf.js';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Table from '../../../components/Table';
import {
  getOr, cloneDeep, compact, values,
} from '../../../libs/lodash';

import { StyledTable } from './styles';

function Cabinet() {
  const {
    cabinetData,
    storageData,
    documentData,
    categoryData,
    handling,
    warehouseData,
  } = useSelector(({
    cabinet, storage, document, category, warehouse,
  }) => ({
    cabinetData: cabinet.data,
    storageData: storage.data,
    documentData: document.data,
    categoryData: category.data,
    handling: cabinet.handling ?? false,
    warehouseData: warehouse.data,
  }));

  const [printer, setPrinter] = useState();
  const data = useMemo(() => values(cabinetData)?.map((row) => ({ ...row, warehouse: warehouseData[row.warehouseId].name })), [cabinetData, warehouseData]);
  const cols = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 100,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Ký hiệu',
      accessor: 'symbol',
      disableFilters: true,
      style: {
        width: 350,
      },
    },
    {
      Header: 'tên',
      accessor: 'name',
      disableFilters: true,
      style: {
        width: 350,
      },
    },
  ], []);

  const rows = useMemo(() => {
    const storages = Object.values(storageData).filter(({ cabinetId }) => cabinetId === printer?._id);
    return compact(storages.map(({ documentId }) => categoryData?.[documentData?.[documentId]?.categoryId]));
  }, [printer, documentData, storageData]);

  const PosterPreview = () => (
    <Dialog open={!!printer} maxWidth="lg" fullWidth onClose={() => setPrinter()}>
      <DialogTitle onClose={() => setPrinter()}>Ký Hiệu</DialogTitle>
      <DialogContent>
        <Box id="symbol-area" paddingLeft={2}>
          <Box textAlign="center" marginBottom={2}>
            <Typography variant="h3">Danh sách ký hiệu</Typography>
          </Box>
          <StyledTable columns={cols} data={rows} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={async () => {
            const printNode = document.getElementById('symbol-area').cloneNode(true);
            Object.values(printNode.children || {}).forEach((element) => {
              // eslint-disable-next-line no-param-reassign
              element.style.height = 'auto';
            });
            await html2pdf()
              .set({
                filename: 'Danh sách ký hiệu.pdf',
                html2canvas: { scale: 1.2 },
                jsPDF: { format: 'a4', orientation: 'portrait', unit: 'pt' },
                pagebreak: { mode: 'avoid-all' },
              })
              .from(printNode)
              .save();
          }}
        >
          In

        </Button>
        <Button color="error" onClick={() => setPrinter()}>Đóng</Button>
      </DialogActions>
    </Dialog>
  );

  const columns = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 100,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Tên',
      accessor: 'name',
      style: {
        width: 350,
      },
    },
    {
      Header: 'Ký hiệu',
      accessor: 'short',
      style: {
        width: 350,
      },
    },
    {
      Header: 'Kho',
      accessor: 'warehouse',
      style: {
        width: 350,
      },
    },
    {
      id: 'add',
      style: {
        width: 250,
      },
      Cell: (rowProps) => {
        const rowValue = getOr({}, 'row.original', rowProps);
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => setPrinter(cloneDeep(rowValue))}
              color="primary"
              size="small"
              sx={{ marginRight: '5px' }}
            >
              <UilFileExport />
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ], []);
  return (
    <>
      {handling && <Waiting fullscreen />}
      <Table columns={columns} data={data} alwaysShowPagination showPaginationTop={false} />
      {PosterPreview()}
    </>
  );
}

Cabinet.propTypes = {

};

export default Cabinet;
