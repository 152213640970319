import {
  combineReducers,
} from 'redux';

import author from './author';
import bookshelf from './bookshelf';
import borrow from './borrow';
import cabinet from './cabinet';
import catalogs from './catalogs';
import category from './category';
import childrenBook from './childrenBook';
import document from './document';
import level from './level';
import listing from './listing';
import member from './member';
import producer from './producer';
import staff from './staff';
import storage from './storage';
import storageType from './storageType';
import subject from './subject';
import type from './type';
import unit from './unit';
import user from './user';
import warehouse from './warehouse';

const rootReducer = combineReducers({
  user,
  staff,
  unit,
  category,
  type,
  warehouse,
  bookshelf,
  producer,
  author,
  document,
  storage,
  borrow,
  level,
  member,
  cabinet,
  storageType,
  subject,
  catalogs,
  childrenBook,
  listing,
});

export default rootReducer;
