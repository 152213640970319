import {
  UilUser,
  UilUsersAlt,
  UilListUl,
  UilBook,
  UilDatabase,
  UilFileRedoAlt,
  UilComparison,
  UilShareAlt,
  UilBookReader,
} from '@iconscout/react-unicons';
import { Toolbar, Button, Box } from '@xbotvn/mui';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import {
  find,
  getOr,
} from '../../libs/lodash';
import { hasChildrenUnit } from '../../libs/utils';

import Borrow from './Borrow';
import ChildrenBook from './ChildrenBook';
import Document from './Document';
import Export from './Export';
import Listing from './Listing';
import Member from './Member';
import Other from './Other';
import Statistic from './Statistic';
import Storage from './Storage';
import User from './User';

export default function Admin() {
  const {
    permission,
    staffActive,
    unitInfo,
  } = useSelector(({
    user, unit, staff,
  }) => ({
    permission: find(
      (obj) => obj.email === getOr('', 'email', user),
      getOr({}, getOr('', 'activeUnit', user), unit.data).members,
    ),
    staffActive: staff.data,
    unitInfo: unit.data?.[user.activeUnit],
  }));
  const [menuActive, setMenu] = useState('member');

  const renderContent = useMemo(() => {
    switch (menuActive) {
      case 'member': return <Member />;
      case 'user': return <User />;
      case 'listing': return <Listing />;
      case 'document': return <Document />;
      case 'storage': return <Storage />;
      case 'borrow': return <Borrow />;
      case 'other': return <Other />;
      case 'chart': return <Statistic />;
      case 'export': return <Export />;
      case 'children': return <ChildrenBook />;
      default: return <div />;
    }
  }, [menuActive]);

  return (staffActive?.support || permission?.role === 'admin') ? (
    <div style={{
      marginBottom: 50,
    }}
    >
      <Toolbar sx={{
        display: 'flex',
        minHeight: '56px !important',
        justifyContent: 'space-between',
        boxShadow:
        'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
      }}
      >
        <Box>
          <Button
            color={menuActive === 'member' ? 'primary' : 'inherit'}
            onClick={() => setMenu('member')}
            variant="text"
            startIcon={<UilUsersAlt />}
          >
            Nhân viên
          </Button>
          <Button
            variant="text"
            startIcon={<UilUser />}
            color={menuActive === 'user' ? 'primary' : 'inherit'}
            onClick={() => setMenu('user')}
          >
            Học viên
          </Button>
          <Button
            variant="text"
            startIcon={<UilListUl />}
            color={menuActive === 'listing' ? 'primary' : 'inherit'}
            onClick={() => setMenu('listing')}
          >
            Danh mục
          </Button>
          <Button
            startIcon={<UilBook />}
            variant="text"
            color={menuActive === 'document' ? 'primary' : 'inherit'}
            onClick={() => setMenu('document')}
          >
            Tài liệu
          </Button>
          {hasChildrenUnit(unitInfo) && (
          <Button
            startIcon={<UilBookReader />}
            variant="text"
            color={menuActive === 'children' ? 'primary' : 'inherit'}
            onClick={() => setMenu('children')}
          >
            Sách cho trẻ
          </Button>
          )}
          <Button
            startIcon={<UilDatabase />}
            variant="text"
            color={menuActive === 'storage' ? 'primary' : 'inherit'}
            onClick={() => setMenu('storage')}
          >
            Tình trạng Lưu trữ
          </Button>
          <Button
            startIcon={<UilFileRedoAlt />}
            variant="text"
            color={menuActive === 'borrow' ? 'primary' : 'inherit'}
            onClick={() => setMenu('borrow')}
          >
            Tình trạng Mượn/Trả
          </Button>
          <Button
            startIcon={<UilComparison />}
            variant="text"
            color={menuActive === 'chart' ? 'primary' : 'inherit'}
            onClick={() => setMenu('chart')}
          >
            Báo cáo thống kê
          </Button>
          <Button
            startIcon={<UilShareAlt />}
            variant="text"
            color={menuActive === 'other' ? 'primary' : 'inherit'}
            onClick={() => setMenu('other')}
          >
            Khác
          </Button>
        </Box>
      </Toolbar>
      {renderContent}
    </div>
  ) : null;
}
