import styled from '@emotion/styled';
import React from 'react';
import {
  useSelector,
} from 'react-redux';

import {
  RESOURCES,
} from '../../libs/config';
import {
  getOr,
  map,
} from '../../libs/lodash';

export default function Links() {
  const {
    links, unitId,
  } = useSelector(({
    unit, user,
  }) => ({
    links: getOr({}, user.activeUnit, unit.data).partnerLinks || [],
    unitId: getOr('', 'activeUnit', user),
  }));

  const coverPage = (fileName) => `${RESOURCES}/${unitId}/${fileName}`;

  return (
    <div>
      {map(
        (rc, index) => (
          <Image key={index}>
            {getOr('', 'cover', rc) ? (
              <a
                rel="noreferrer"
                target="_blank"
                href={getOr('', 'url', rc)}
              >
                <img
                  width="100%"
                  height="100px"
                  src={coverPage(getOr('', 'cover', rc))}
                  alt={getOr('', 'cover', rc)}
                />
              </a>
            ) : (
              <a
                rel="noreferrer"
                target="_blank"
                href={getOr('', 'url', rc)}
              >
                {getOr('', 'url', rc)}
              </a>
            )}
          </Image>
        ),
        links,
      )}
    </div>
  );
}

const Image = styled.div`
  padding-top: 7px;
`;
