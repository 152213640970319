import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Box,
  ConfirmDialog,
} from '@xbotvn/mui';
import cryptoRandomString from 'crypto-random-string';
import PropTypes from 'prop-types';
import React, {
  useState,
  useMemo,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import {
  getOr,
  map,
  forEach,
  replace,
  find,
  join,
  get,
} from '../../../libs/lodash';

export default function ListingModal({ onClose, onConfirm }) {
  const {
    categories,
    authors,
    producers,
    types,
    unitId,
  } = useSelector(({
    category,
    author,
    producer,
    type,
    user,
  }) => ({
    categories: category.data,
    authors: author.data,
    producers: producer.data,
    types: type.data,
    unitId: user.activeUnit,
  }));

  const [isOpen, setIsOpen] = useState(false);
  const [display, setDisplay] = useState({});
  const [data, setData] = useState();

  const listings = useMemo(() => [{
    title: 'Loại sách',
    key: 'typeId',
    store: types,
  }, {
    title: 'Danh mục sách',
    key: 'categoryId',
    store: categories,
  }, {
    title: 'Tác giả',
    key: 'authorId',
    store: authors,
  }, {
    title: 'Nhà sản xuất',
    key: 'producerId',
    store: producers,
  }], [types, categories, authors, producers]);

  const checkExist = useMemo(() => {
    const errors = [];
    const newData = {};
    const newListing = {};
    forEach(
      ({ key, store }) => {
        const value = get(key, display);
        if (value) {
          const findRecord = find(
            ({ name }) => replace(/\s/g, '', name).toLocaleLowerCase() === replace(/\s/g, '', value).toLocaleLowerCase(),
            map(({ name, _id }) => ({ _id, name }), store),
          );
          if (findRecord) {
            newData[key] = findRecord._id;
          } else {
            const _id = cryptoRandomString({
              length: 24,
            });
            newData[key] = _id;
            newListing[replace('Id', '', key)] = [{
              _id,
              unitId,
              name: value,
            }];
            errors.push(value);
          }
        }
      },
      listings,
    );
    setData({ result: newData, newListing });
    return errors;
  }, [listings, display, unitId]);

  return (
    <>
      {isOpen && (
        <ConfirmDialog
          confirmLabel="Cập nhật"
          cancelLabel="Huỷ"
          open={isOpen}
          color="info"
          onConfirm={() => onConfirm(data)}
          onClose={() => setIsOpen(false)}
          title={`${join(', ', checkExist)} sẽ tự động cập nhật vào danh mục.`}
        >
          <DialogContent>
            Thầy/ cô có muốn cập nhật không?
          </DialogContent>
        </ConfirmDialog>
      )}
      <Dialog
        open
        onClose={onClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle onClose={onClose}>Danh mục</DialogTitle>
        <DialogContent>
          {map(
            ({ title, key }) => (
              <Box
                key={key}
                display="inline-table"
                width="50%"
                padding="5px"
              >
                <TextField
                  value={getOr('', key, display)}
                  label={title}
                  fullWidth
                  onChange={(e) => setDisplay((prev) => ({
                    ...prev,
                    [key]: e.target.value,
                  }))}
                />
              </Box>
            ),
            listings,
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              if (!checkExist.length) {
                onConfirm(data);
              } else {
                setIsOpen(true);
              }
            }}
          >
            Cập nhật
          </Button>
          <Button
            color="error"
            onClick={onClose}
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ListingModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
