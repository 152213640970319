import {
  saveAs,
} from 'file-saver';
import XLSX from 'xlsx';
import XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';

import {
  BACKEND_URL,
} from './config';
import { exception } from './ga';

export const exportExcel = (templateName, wbHandler, onSuccess, fileName, save) => {
  const errorHandler = (err) => {
    exception(err.message);
  };
  try {
    const req = new XMLHttpRequest();
    req.open('GET', `${BACKEND_URL}/files/libot/${templateName}.xlsx`, true);
    req.responseType = 'arraybuffer';
    req.onload = () => {
      XlsxPopulate.fromDataAsync(req.response).then((wb) => {
        wbHandler(wb);
        return wb.outputAsync().then((data) => {
          if (data !== undefined) {
            if (onSuccess) {
              if (save) {
                saveAs(data, `${fileName || templateName}.xlsx`);
              }
              onSuccess(data);
            }
          } else errorHandler('Xuất excel không thành công!');
        });
      }, errorHandler);
    };
    req.onerror = (err) => {
      errorHandler(err);
    };
    req.send();
  } catch (err) {
    errorHandler(err);
  }
};

export const exportToCSV = (csvData, fileName = 'export') => {
  // csvData is an array of object, e.g. [{firstName: 'a1', lastName: 'b1'}, {firstName: 'a2', lastName: 'b2'}]
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = {
    Sheets: {
      data: ws,
    },
    SheetNames: ['data'],
  };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx', type: 'array',
  });
  const data = new Blob([excelBuffer], {
    type: fileType,
  });
  saveAs(data, fileName + fileExtension);
};
