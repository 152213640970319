import {
  all,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import {
  listingApi,
} from '../../api';
import {
  errorHandler, successHandler,
} from '../../libs/ga';
import {
  cloneDeep,
  keyBy,
  map,
} from '../../libs/lodash';

import {
  BORROW,
} from './constants';

function* storeBorrow(data = {}, merge = true) {
  yield put({
    type: BORROW.update,
    data,
    merge,
  });
}

function* fetchBorrow({
  data,
}) {
  yield* storeBorrow(data);
}

function* updateBorrow({
  data, onSuccess,
}) {
  try {
    const {
      borrow,
    } = yield select();
    const newBorrow = cloneDeep(borrow.data);
    const {
      _id,
      ...rest
    } = data;
    if (_id) {
      yield call(() => new Promise((resolve, reject) => {
        listingApi.update({
          collection: 'borrow',
          payload: {
            _id,
            data: rest,
          },
        }).then(() => resolve(true))
          .catch((error) => reject(error));
      }));
      newBorrow[_id] = data;
      yield* storeBorrow(newBorrow);
    } else {
      const {
        newData,
      } = yield call(() => new Promise((resolve, reject) => {
        listingApi.create({
          collection: 'borrow',
          payload: {
            data,
          },
        }).then((res) => resolve(res.data))
          .catch((err) => reject(err));
      }));
      newBorrow[newData._id] = newData;
      yield* storeBorrow(newBorrow);
    }

    yield call(() => new Promise((resolve, reject) => {
      listingApi.update({
        collection: 'documentItem',
        payload: {
          data: [{
            _id: data.documentItem,
            borrowed: data.status !== 'done',
          }],
        },
      }).then(() => resolve(true))
        .catch((error) => reject(error));
    }));

    successHandler('Thành công', 'Cập nhật thành công', onSuccess);
  } catch (error) {
    yield* storeBorrow();
    errorHandler('Thất bại', error);
  }
}

function* removeBorrow({
  _id,
}) {
  const {
    borrow,
  } = yield select();

  const newData = cloneDeep(borrow.data);
  try {
    yield call(() => new Promise((resolve, reject) => {
      listingApi.remove({
        collection: 'borrow',
        payload: {
          _id,
        },
      }).then(() => {
        listingApi.update({
          collection: 'documentItem',
          payload: {
            data: [{
              _id: newData[_id].documentItem,
              borrowed: false,
            }],
          },
        });
        resolve(true);
      })
        .catch((error) => reject(error));
    }));

    delete newData[_id];
    yield* storeBorrow(newData, false);

    successHandler('Thành công', 'Xóa thành công');
  } catch (error) {
    yield* storeBorrow();
    errorHandler('Thất bại', error);
  }
}

function* importBorrows({
  data = [],
  onSuccess,
}) {
  const {
    borrow,
  } = yield select();
  try {
    yield call(() => new Promise((resolve, reject) => {
      listingApi.importData({
        collection: 'borrow',
        payload: {
          data,
        },
      }).then(() => {
        listingApi.update({
          collection: 'documentItem',
          payload: {
            data: map(
              ({ documentItem }) => ({
                _id: documentItem,
                borrowed: true,
              }),
              data,
            ),
          },
        });
        resolve(true);
      })
        .catch((err) => reject(err));
    }));
    yield* storeBorrow({
      ...keyBy('_id', data),
      ...cloneDeep(borrow.data),
    });
    successHandler('Thành công', 'Cập nhật thành công', onSuccess);
  } catch (error) {
    yield* storeBorrow();
    errorHandler('Thất bại', error);
  }
}

export default function* producerSaga() {
  yield all([
    yield takeEvery(BORROW.handlers.fetch, fetchBorrow),
    yield takeEvery(BORROW.handlers.update, updateBorrow),
    yield takeEvery(BORROW.handlers.import, importBorrows),
    yield takeEvery(BORROW.handlers.remove, removeBorrow),
  ]);
}
