import { Notification } from '@xbotvn/mui';
import {
  all,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import {
  authApi,
  listingApi,
  staffApi,
  unitApi,
  userApi,
} from '../../api';
import {
  app, googleProvider,
} from '../../libs/firebase';
import {
  compose,
  getOr,
  keyBy,
  map,
} from '../../libs/lodash';

import {
  AUTHOR,
  BOOKSHELF,
  BORROW,
  CABINET,
  CATEGORY,
  DOCUMENT,
  SUBJECT,
  MEMBER,
  PRODUCER,
  SIGN_OUT,
  STAFF,
  STORAGE,
  TYPE,
  UNIT,
  USER,
  WAREHOUSE,
  LEVEL,
  STORAGETYPE,
  CATALOGS,
  CHILDRENBOOK,
  LISTING,
} from './constants';

function* storeUser(data) {
  yield put({
    type: USER.update,
    data,
  });
}

function* getStaff(email) {
  const {
    data,
  } = yield staffApi.get({
    email,
  });

  yield put({
    type: STAFF.update,
    data: getOr({}, 'staff', data),
  });
}

function* getUnit() {
  const {
    data,
  } = yield unitApi.get();

  yield put({
    type: UNIT.update,
    data: keyBy('_id', data.units),
  });
}

function* getListing({
  type, collection, unitId,
}) {
  const {
    data,
  } = yield call(() => new Promise((resolve, reject) => {
    listingApi.get({
      collection,
      payload: {
        data: {
          unitId,
        },
      },
    }).then((result) => resolve(result))
      .catch((error) => reject(error));
  }));
  yield put({
    type,
    data: compose(keyBy('_id'))(data.records),
    collection,
  });
}

function* getListings({
  unitId,
}) {
  yield all(
    map(({
      type,
      collection,
    }) => getListing({
      type,
      collection,
      unitId,
    }), [
      {
        type: STORAGE.handlers.fetch,
        collection: 'storage',
      },
      {
        type: TYPE.handlers.fetch,
        collection: 'type',
      },
      {
        type: CATEGORY.handlers.fetch,
        collection: 'category',
      },
      {
        type: AUTHOR.handlers.fetch,
        collection: 'author',
      },
      {
        type: BOOKSHELF.handlers.fetch,
        collection: 'bookshelf',
      },
      {
        type: PRODUCER.handlers.fetch,
        collection: 'producer',
      },
      {
        type: BORROW.handlers.fetch,
        collection: 'borrow',
      },
      {
        type: MEMBER.handlers.fetch,
        collection: 'member',
      },
      {
        type: LEVEL.handlers.fetch,
        collection: 'level',
      },
      {
        type: WAREHOUSE.handlers.fetch,
        collection: 'warehouse',
      },
      {
        type: CABINET.handlers.fetch,
        collection: 'cabinet',
      },
      {
        type: STORAGETYPE.handlers.fetch,
        collection: 'storageType',
      },
      {
        type: SUBJECT.handlers.fetch,
        collection: 'subject',
      },
      {
        type: LISTING.handlers.fetch,
        collection: 'age',
      },
      {
        type: LISTING.handlers.fetch,
        collection: 'documentSubject',
      },
      {
        type: LISTING.handlers.fetch,
        collection: 'subCategory',
      },
    ]),
  );
}

function* loginWithGoogle() {
  return yield call(() => new Promise((resolve, reject) => {
    app.auth().signInWithPopup(googleProvider)
      .then(() => {
        app.auth().currentUser.getIdToken(true)
          .then((token) => {
            authApi.signInFirebase({
              token,
            }).then((result) => {
              const {
                token: jwtToken,
                user,
              } = result.data;
              localStorage.setItem('token', jwtToken);
              resolve(user);
            }).catch((error) => {
              Notification.error(error.message);
              reject(error);
            });
          })
          .catch((error) => {
            Notification.error(error.message);
            reject(error);
          });
      })
      .catch((error) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          Notification.error('auth/account-exists-with-different-credential');
        } else {
          Notification.error(error.message);
        }
        reject(error);
      });
  }));
}

function* reLogin() {
  const userInfoFirebase = yield call(() => new Promise((resolve, reject) => {
    app.auth().onAuthStateChanged((result) => {
      if (result) {
        resolve({
          _id: result.uid,
          email: result.email,
          name: result.displayName,
          photo: result.photoURL,
          phone: result.phoneNumber,
          provider: result.providerData && result.providerData[0] && result.providerData[0].providerId,
        });
      } else {
        resolve({});
      }
    }, (error) => {
      reject(error);
    });
  }));

  const userInfoBackend = yield call(() => new Promise((resolve, reject) => {
    userApi.me()
      .then((result) => {
        const {
          user,
        } = result.data;
        resolve(user);
      })
      .catch((error) => {
        Notification.error('Vui lòng đăng nhập lại!');
        reject(error);
      });
  }));

  return {
    ...userInfoFirebase,
    ...userInfoBackend,
  };
}

function* login({
  service,
  onSuccess,
}) {
  try {
    let user;
    switch (service) {
      case 'google':
        user = yield* loginWithGoogle();
        break;
      default:
        user = yield* reLogin();
        break;
    }

    if (user) {
      yield* getStaff(user.email);
      yield* getUnit();
      yield put({
        type: CATALOGS.handlers.getCities,
      });

      if (user.activeUnit) {
        yield* getListing({
          type: DOCUMENT.handlers.fetch,
          collection: 'document',
          unitId: user.activeUnit,
        });

        yield* getListing({
          type: CHILDRENBOOK.handlers.fetch,
          collection: 'childrenBook',
          unitId: user.activeUnit,
        });

        yield put({
          type: USER.handlers.getListings,
          unitId: user.activeUnit,
        });
      } else {
        Notification.warn('Tài khoản chưa được phân quyền. Vui lòng liên hệ tài khoản quản trị của đơn vị.');
      }
    }

    yield* storeUser(user);
  } catch (error) {
    yield* storeUser();
    yield put({
      type: SIGN_OUT,
    });
  }
  if (onSuccess) {
    onSuccess();
  }
}

function* update({
  data,
  onSuccess,
}) {
  const {
    _id,
    activeUnit,
  } = data;
  yield call(() => new Promise((resolve, reject) => {
    userApi.update({
      _id,
      data: {
        activeUnit,
      },
    }).then(() => resolve(true))
      .catch((error) => reject(error));
  }));
  yield* storeUser(data);
  if (onSuccess) {
    onSuccess();
  }
}

function* signOut() {
  const {
    user,
  } = yield select();
  if (user.socket) {
    user.socket.close();
  }
  app.auth().signOut();
}

export default function* userSaga() {
  yield all([
    yield takeEvery(USER.handlers.login, login),
    yield takeEvery(USER.handlers.update, update),
    yield takeEvery(USER.handlers.getListings, getListings),
    yield takeEvery(SIGN_OUT, signOut),
  ]);
}
