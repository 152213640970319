import {
  all,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import {
  listingApi,
} from '../../api';
import {
  errorHandler, successHandler,
} from '../../libs/ga';
import {
  cloneDeep,
  forEach,
} from '../../libs/lodash';

import {
  STORAGETYPE,
} from './constants';

function* storeStorageType(data = {}, merge = true) {
  yield put({
    type: STORAGETYPE.update,
    data,
    merge,
  });
}

function* fetchStorageType({
  data,
}) {
  yield* storeStorageType(data);
}

function* updateStorageType({
  data, onSuccess,
}) {
  try {
    const {
      _id,
      ...rest
    } = data;
    if (_id) {
      yield call(() => new Promise((resolve, reject) => {
        listingApi.update({
          collection: 'storageType',
          payload: {
            _id,
            data: rest,
          },
        }).then(() => resolve(true))
          .catch((error) => reject(error));
      }));
      yield* storeStorageType({
        [data._id]: data,
      });
    } else {
      const {
        newData,
      } = yield call(() => new Promise((resolve, reject) => {
        listingApi.create({
          collection: 'storageType',
          payload: {
            data,
          },
        }).then((res) => resolve(res.data))
          .catch((err) => reject(err));
      }));
      yield* storeStorageType({
        [newData._id]: newData,
      });
    }
    successHandler('Thành công', 'Cập nhật thành công', onSuccess);
  } catch (error) {
    yield* storeStorageType();
    errorHandler('Thất bại', error);
  }
}

function* removeStorageType({
  _id,
}) {
  const {
    storageType,
  } = yield select();
  try {
    yield call(() => new Promise((resolve, reject) => {
      listingApi.remove({
        collection: 'storageType',
        payload: {
          _id,
        },
      }).then(() => resolve(true))
        .catch((error) => reject(error));
    }));

    const newData = cloneDeep(storageType.data);
    delete newData[_id];
    yield* storeStorageType(newData, false);

    successHandler('Thành công', 'Xóa thành công');
  } catch (error) {
    yield* storeStorageType();
    errorHandler('Thất bại', error);
  }
}

function* importStorageType({
  data = [],
}) {
  const {
    storageType,
  } = yield select();
  yield call(() => new Promise((resolve, reject) => {
    listingApi.importData({
      collection: 'storageType',
      payload: {
        data,
      },
    }).then(() => resolve(true))
      .catch((err) => reject(err));
  }));
  const newData = cloneDeep(storageType.data);
  forEach(
    (obj) => {
      newData[obj._id] = obj;
    },
    data,
  );
  yield* storeStorageType(newData);
  successHandler('Thành công', 'Cập nhật thành công');
}

export default function* StorageTypeSaga() {
  yield all([
    yield takeEvery(STORAGETYPE.handlers.fetch, fetchStorageType),
    yield takeEvery(STORAGETYPE.handlers.update, updateStorageType),
    yield takeEvery(STORAGETYPE.handlers.import, importStorageType),
    yield takeEvery(STORAGETYPE.handlers.remove, removeStorageType),
  ]);
}
