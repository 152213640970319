import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';

import Dropzone from './Dropzone';

export default function DropzoneModal({
  onConfirm,
  onClose,
}) {
  const [files, setFiles] = useState([]);
  return (
    <Dialog
      open
      onClose={onClose}
    >
      <DialogTitle>Chọn File</DialogTitle>
      <DialogContent>
        <Dropzone
          handleFiles={setFiles}
          allowFileTypes={['.xlsx', '.xls']}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          icon="saved"
          onClick={() => {
            onConfirm(files);
          }}
        >
          Xác nhận
        </Button>
        <Button color="error" icon="cross" onClick={onClose}>Đóng</Button>
      </DialogActions>
    </Dialog>
  );
}

DropzoneModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
