import {
  dev,
} from './firebase';

export const ACTIVE_YEAR = 2022;

export const BACKEND_URL = (() => {
  if (process.env.REACT_APP_STAGE === 'development') return 'http://localhost:3020';
  return 'https://libotctcs.stedu.edu.vn';
})();

export const RESOURCES = `${BACKEND_URL}/resources`;

export const ROLES = {
  admin: 'Admin',
  manage: 'Quản lý',
  member: 'Thành viên',
};

export const DOCUMENTS = [
  {
    key: 'isbnCode',
    no: 1,
    format: 'string',
    name: 'Mã ISBN',
  },
  {
    key: 'name',
    no: 2,
    required: true,
    format: 'string',
    name: 'Nhan đề',
  },
  {
    key: 'titleCode',
    no: 3,
    required: true,
    format: 'string',
    name: 'Mã hoá nhan đề',
  },
  {
    key: 'parallelTitle',
    no: 4,
    format: 'string',
    name: 'Nhan đề song song',
  },
  {
    key: 'subTitle',
    no: 5,
    format: 'string',
    name: 'Phụ đề',
  },
  {
    key: 'authorId',
    no: 6,
    required: true,
    format: 'string',
    autoCreate: true,
    name: 'Tác giả chính',
    code: 'authors',
  },
  {
    key: 'authorCode',
    no: 7,
    required: true,
    format: 'string',
    name: 'Mã hoá tác giả',
  },
  {
    key: 'sameAuthor',
    no: 8,
    format: 'array',
    name: 'Đồng tác giả',
    code: 'authors',
  },
  {
    key: 'translator',
    no: 9,
    format: 'string',
    name: 'Người biên dịch',
  },
  {
    key: 'resInfo',
    no: 10,
    format: 'string',
    name: 'Thông tin trách nhiệm',
  },
  {
    key: 'content',
    no: 11,
    format: 'string',
    name: 'Tóm tắt nội dung',
  },
  {
    key: 'schoolLevel',
    no: 12,
    format: 'string',
    name: 'Cấp học',
    code: 'levels',
  },
  {
    key: 'grade',
    no: 13,
    format: 'string',
    name: 'Khối học',
    code: 'grades',
  },
  {
    key: 'subject',
    no: 14,
    format: 'string',
    name: 'Môn học',
    code: 'subjects',

  },
  {
    key: 'categoryId',
    no: 15,
    format: 'string',
    required: true,
    name: 'Loại ấn phẩm',
    code: 'categories',
  },
  {
    key: 'storageType',
    no: 16,
    format: 'string',
    required: true,
    name: 'Dạng lưu trữ',
    code: 'storageTypes',
  },
  {
    key: 'language_override',
    no: 17,
    format: 'string',
    name: 'Ngôn ngữ',
    code: 'languages',
  },
  {
    key: 'keyword',
    no: 18,
    format: 'array',
    name: 'Từ khoá',
  },
  {
    key: 'topic',
    no: 19,
    format: 'string',
    name: 'Chủ đề',
  },
  {
    key: 'chapInfo',
    no: 20,
    format: 'string',
    name: 'Thông tin tập',
  },
  {
    key: 'sol',
    no: 21,
    format: 'string',
    name: 'Tùng thư',
  },
  {
    key: 'producerId',
    no: 22,
    required: true,
    format: 'string',
    name: 'Nhà xuất bản',
    code: 'producer',
  },
  {
    key: 'pop',
    no: 23,
    required: true,
    format: 'string',
    name: 'Nơi xuất bản',
  },
  {
    key: 'edition',
    no: 24,
    format: 'string',
    name: 'Lần xuất bản',
  },
  {
    key: 'publishYear',
    no: 25,
    required: true,
    format: 'number',
    name: 'Năm xuất bản',
  },
  {
    key: 'pages',
    no: 26,
    required: true,
    format: 'number',
    name: 'Số trang',
  },
  {
    key: 'size',
    no: 27,
    required: true,
    format: 'string',
    name: 'Kích cỡ',
  },
  {
    key: 'price',
    no: 28,
    format: 'number',
    name: 'Giá bìa',
  },
  {
    key: 'attachment',
    no: 29,
    format: 'string',
    name: 'Tài liệu đính kèm',
  },
  {
    key: 'bookshelfId',
    no: 30,
    format: 'string',
    name: 'Kệ',
    related: 'storages',
    code: 'bookshelves',
  },
  {
    key: 'warehouseId',
    no: 31,
    format: 'string',
    name: 'Kho',
    related: 'storages',
    code: 'warehouses',
    required: true,
  },
  {
    key: 'cabinetId',
    no: 32,
    format: 'Phích',
    name: 'phích',
    related: 'storages',
    code: 'cabinets',
    required: true,
  },
  {
    key: 'amount',
    no: 33,
    format: 'string',
    name: 'Số lượng',
    related: 'storages',
  },
  {
    key: 'dateAdded',
    no: 34,
    format: 'date',
    name: 'Ngày nhập',
    related: 'storages',
  },
];

export const OLD_DOCUMENTS = [
  {
    key: 'name',
    no: 1,
  },
  {
    key: 'price',
    no: 2,
  },
  {
    key: 'typeId',
    no: 3,
  },
  {
    key: 'categoryId',
    no: 4,
  },
  {
    key: 'authorId',
    no: 5,
  },
  {
    key: 'producerId',
    no: 6,
  },
];

export const LISTING = [{
  key: 'name',
  no: 1,
}];

export const WAREHOUSE = [
  {
    key: 'name',
    name: 'Tên',
    no: 1,
    required: true,
  },
  {
    key: 'symbol',
    name: 'Ký hiệu',
    no: 2,
    required: true,
  },
];

export const CATEGORIES = [
  {
    key: 'name',
    no: 1,
  },
  {
    key: 'symbol',
    no: 2,
  },
];

export const MEMBERS = [
  {
    key: 'name',
    no: 1,
  },
  {
    key: 'email',
    no: 2,
  },
  {
    key: 'code',
    no: 3,
  },
  {
    key: 'class',
    no: 4,
  },
  {
    key: 'startDate',
    format: 'dateString',
    no: 5,
  },
  {
    key: 'endDate',
    format: 'dateString',
    no: 6,
  },
];

export const CHILDREN_BOOK = [
  {
    key: 'name',
    name: 'Nhan đề',
    no: 1,
    required: true,
  },
  {
    key: 'subject',
    name: 'Chủ đề',
    no: 2,
    required: true,
  },
  {
    key: 'coverLink',
    name: 'Đường link ảnh bìa',
    no: 3,
    required: true,
  },
  {
    key: 'bookLink',
    name: 'Đường link sách online',
    no: 4,
    required: true,
  },
];
