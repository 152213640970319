import styled from '@emotion/styled';

export const TitleFilter = styled.div`
  font-weight: bold;
  padding: 10px;
  background: #00000014;
  border-bottom: ${({
    isCollapse,
  }) => (isCollapse && 'groove 1px #ffffffa6')};
  margin-bottom: ${({
    isCollapse,
  }) => (isCollapse && '10px')};
  cursor: pointer;
`;

export const Count = styled.div`
  color: #acacac;
  padding-left: 5px;
  padding-top: 10px;
`;

export const Container = styled.div`
  margin-bottom: 5px;
`;

export const Content = styled.div`
  padding: 0px 10px;
`;

export const GroupCheckbox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Name = styled.div`
  flex: 1 1 0%;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
`;
