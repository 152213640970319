import {
  UilQuestionCircle,
  UilCreditCard,
  UilBox,
  UilInbox,
  UilTimesCircle,
} from '@iconscout/react-unicons';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
} from '@xbotvn/mui';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import Current from './Current';

export default function Borrow() {
  const {
    userInitial,
  } = useSelector(({
    user,
  }) => ({
    userInitial: user,
  }));

  const [menuActive, setMenu] = useState('request');
  const [position, setPosition] = useState('');

  const renderContent = useMemo(() => <Current type={menuActive} position={position} />, [menuActive, position]);

  return userInitial._id ? (
    <div style={{
      marginBottom: 50,
    }}
    >
      <Toolbar sx={{
        boxShadow:
        'rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
      }}
      >
        <Box>
          <Button
            variant="text"
            startIcon={<UilQuestionCircle />}
            color={menuActive === 'request' ? 'primary' : 'inherit'}
            onClick={() => setMenu('request')}
          >
            Yêu cầu
          </Button>
          <Button
            variant="text"
            startIcon={<UilCreditCard />}
            color={menuActive === 'new' ? 'primary' : 'inherit'}
            onClick={() => setMenu('new')}
          >
            Đang mượn
          </Button>
          <Button
            variant="text"
            startIcon={<UilBox />}
            color={menuActive === 'late' ? 'primary' : 'inherit'}
            onClick={() => setMenu('late')}
          >
            Hết hạn
          </Button>
          <Button
            variant="text"
            startIcon={<UilInbox />}
            color={menuActive === 'done' ? 'primary' : 'inherit'}
            onClick={() => setMenu('done')}
          >
            Đã trả
          </Button>
        </Box>
        <FormControl sx={{ minWidth: 180 }} size="small">
          <InputLabel id="select-small">Lọc theo chức danh</InputLabel>
          <Select
            id="select-small"
            value={position}
            onChange={(value) => setPosition(value.target.value)}
            label="Lọc theo chức danh"
          >
            <MenuItem value="staff">Nhân viên</MenuItem>
            <MenuItem value="member">Học viên</MenuItem>
          </Select>
        </FormControl>
        {position
            && (
              <Button
                variant="text"
                color="error"
                startIcon={<UilTimesCircle />}
                onClick={() => setPosition('')}
              >
                Bỏ lọc
              </Button>
            )}

      </Toolbar>
      {renderContent}
    </div>
  ) : null;
}
