import {
  SIGN_OUT,
  USER,
} from '../actions/constants';

const initialState = {
  handling: false,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case USER.handlers.login:
    case USER.handlers.update:
      return {
        ...state,
        handling: true,
      };
    case USER.update:
      return {
        ...state,
        ...action.data,
        handling: false,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
