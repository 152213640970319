import styled from '@emotion/styled';
import { UilBookReader } from '@iconscout/react-unicons';
import FileRedoIcon from '@iconscout/react-unicons/icons/uil-file-redo-alt';
import HomeIcon from '@iconscout/react-unicons/icons/uil-home';
import ShieldIcon from '@iconscout/react-unicons/icons/uil-shield';
import {
  AppBar,
  Box,
  Toolbar,
  Button,
} from '@xbotvn/mui';
import React, {
  useMemo,
} from 'react';
import {
  useSelector,
} from 'react-redux';
import {
  Link,
} from 'react-router-dom';

import {
  Routes,
} from '../Routes';
import {
  RESOURCES,
} from '../libs/config';
import {
  find,
  getOr,
} from '../libs/lodash';
import {
  hasChildrenUnit,
  imageExists,
} from '../libs/utils';

import UnitSelectorButton from './UnitSelectorButton';
import UserAccountButton from './UserAccountButton';

const Image = styled.img`
  width: 100%;
  height: 180px;
`;

export default function Header() {
  const {
    banner,
    unitID,
    permission,
    staffActive,
    unitInfo,
  } = useSelector(({
    user, unit, staff,
  }) => ({
    banner: getOr({}, getOr('', 'activeUnit', user), unit.data).banner,
    unitID: getOr('', 'activeUnit', user),
    permission: find(
      (obj) => obj.email === getOr('', 'email', user),
      getOr({}, getOr('', 'activeUnit', user), unit.data).members,
    ),
    staffActive: staff.data,
    unitInfo: unit.data?.[user?.activeUnit],
  }));

  const source = useMemo(() => {
    if (banner) {
      return `${RESOURCES}/${unitID}/${banner}`;
    }
    return 'banner.png';
  }, [banner]);

  return (
    <div style={{
      minHeight: 250,
    }}
    >
      {imageExists(source)
        ? (
          <Image
            src={source}
            centered
          />
        ) : null}
      <AppBar
        position="static"
        sx={{
          background: 'white',
          borderRadius: 0,
          boxShadow:
          '0px 4px 8px -2px rgba(16, 24, 64, 0.1), 0px 2px 4px -2px rgba(16, 24, 64, 0.06);',
        }}
      >
        <Toolbar sx={{
          display: 'flex',
          justifyContent: 'stretch',
        }}
        >
          <Box width="80%">
            <Link to={Routes.home}>
              <Button variant="text" startIcon={<HomeIcon />} disableRipple>Trang chủ</Button>
            </Link>
            {
             ((staffActive?.support ?? false) || (permission?.role ?? 'member') !== 'member')
               ? (
                 <Link to={Routes.admin}>
                   <Button variant="text" startIcon={<ShieldIcon />} disableRipple>Quản lý</Button>
                 </Link>
               )
               : (
                 <Link to={Routes.borrow}>
                   <Button variant="text" startIcon={<FileRedoIcon />} disableRipple>Tình Trạng mượn/ trả</Button>
                 </Link>
               )
           }
            {hasChildrenUnit(unitInfo)
              ? (
                <Link to={Routes.children}>
                  <Button variant="text" startIcon={<UilBookReader />} disableRipple>Sách cho trẻ</Button>
                </Link>
              )
              : null}
          </Box>
          <Box position="absolute" right={0}>
            <UnitSelectorButton />
            <UserAccountButton />
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}
