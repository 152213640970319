import { TextField } from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useState,
} from 'react';

import {
  debounce,
} from '../../../libs/lodash';

export default function Scanner({
  value: valueProp,
  inputRef,
  onChange,
  ...props
}) {
  const [valueState, setValueState] = useState();

  const value = valueState !== undefined ? valueState : valueProp;

  const onChangeDebounce = useCallback(debounce(500, onChange), []);

  const onChangeLocal = (event) => {
    setValueState(event.target.value);
    onChangeDebounce(event.target.value);
  };

  return (
    <TextField
      placeholder="Mã sách"
      ref={inputRef}
      value={value}
      onChange={onChangeLocal}
      {...props}
    />
  );
}

Scanner.defaultProps = {
  value: '',
  onChange: () => { },
  inputRef: {},
};

Scanner.propTypes = {
  inputRef: PropTypes.shape(),
  value: PropTypes.string,
  onChange: PropTypes.func,
};
