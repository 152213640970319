import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Notification,
  DatePicker,
  Typography,
  Stack,

} from '@xbotvn/mui';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useState,
  useMemo,
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import SingleSelect from '../../../components/SingleSelect';
import {
  getOr,
  keyBy,
  map,
  findIndex,
  filter,
} from '../../../libs/lodash';
import {
  BORROW,
} from '../../../redux/actions/constants';

import * as Styles from './styles';

export default function Storage({
  data,
  onClose,
}) {
  const dispatch = useDispatch();

  const {
    selectedDocument,
    membersUnit,
    members,
    types,
    activeUnit,
    userInfo,
    staffActive,
  } = useSelector(({
    unit,
    user,
    member,
    type,
    document,
    staff,
  }) => ({
    types: type.data,
    members: member.data,
    selectedDocument: getOr({}, data.documentId, document.data),
    membersUnit: getOr([], ['data', user.activeUnit, 'members'], unit),
    activeUnit: user.activeUnit,
    userInfo: user,
    staffActive: staff.data,
  }));

  const isUnitMember = findIndex((member) => member.email === userInfo.email)(membersUnit);
  const isStudent = isUnitMember === -1 && !staffActive?.support;

  const [selectedUser, setSelectedUser] = useState('');
  const [borrowDate, setBorrowDate] = useState(new Date());
  const [requiredReturnDate, setRequiredReturnDate] = useState(new Date());

  useEffect(() => {
    setRequiredReturnDate(moment(borrowDate)
      .add(getOr(15, [selectedDocument.typeId, 'duration'], types), 'days')
      .toString());
  }, [borrowDate]);

  useEffect(() => {
    if (isStudent) setSelectedUser(userInfo.email);
    else setSelectedUser('');
  }, [isStudent]);

  const borrowers = useMemo(() => {
    if (isStudent) {
      return {
        [userInfo.email]: userInfo,
        ...keyBy('email', members),
      };
    } return ({
      ...keyBy('email', membersUnit),
      ...keyBy('email', filter((mb) => new Date() < new Date(mb.endDate), members)),
    });
  }, [members, membersUnit]);

  return (
    <Dialog
      open
      fullWidth
      onClose={onClose}
    >
      <DialogTitle>{`${selectedDocument?.name ?? ''} - ${selectedDocument?._id ?? ''}`}</DialogTitle>
      <DialogContent>
        <Styles.Notifications>
          <Typography variant="action1">{`Số lượng tối đa cho mượn: ${getOr(5, [selectedDocument.typeId, 'maxAmount'], types)}`}</Typography>
        </Styles.Notifications>
        <Styles.Notifications>
          <Typography variant="action1">{`Thời hạn mượn tối đa (ngày): ${getOr(15, [selectedDocument.typeId, 'duration'], types)}`}</Typography>
        </Styles.Notifications>

        <Stack direction="row">
          <DatePicker
            label="Ngày mượn"
            value={moment(borrowDate).toDate()}
            onChange={(date) => setBorrowDate(date)}
          />
          <DatePicker
            label="Ngày Phải trả"
            value={moment(requiredReturnDate).toDate()}
            onChange={(date) => setRequiredReturnDate(date)}
          />
        </Stack>
        <FormControl sx={{ minWidth: 250 }} size="small">
          <SingleSelect
            disabled={isStudent}
            options={map(({ name, email }) => (
              {
                label: name,
                value: email,
              }
            ),
            borrowers)}
            placeholder="Chọn người mượn"
            onChange={setSelectedUser}
            value={selectedUser}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            const filterUser = filter(({ email }) => email === selectedUser, borrowers);
            if (new Date() > new Date(filterUser[0]?.endDate)) {
              Notification.warn('Tài khoản đã hết hạn vui lòng liên hệ quản lý thư viện để gia hạn.');
              return;
            }
            if (borrowDate > requiredReturnDate) {
              Notification.warn('Ngày phải trả phải từ ngày cho mượn trở đi.');
              return;
            }
            if (!borrowers[selectedUser]) {
              Notification.warn('Chưa chọn người mượn.');
              return;
            }
            if ((moment(new Date(requiredReturnDate)).diff(moment(new Date(borrowDate)), 'days')) > getOr(15, 'duration', types[selectedDocument.typeId])) {
              Notification.warn('Vượt quá thời hạn tối đa.');
              return;
            }
            dispatch({
              type: BORROW.handlers.update,
              data: {
                documentItem: data._id,
                documentId: selectedDocument._id,
                borrowDate: borrowDate.toString(),
                requiredReturnDate: requiredReturnDate.toString(),
                status: 'request',
                userInfo: borrowers[selectedUser],
                unitId: activeUnit,
              },
            });
            onClose();
          }}
        >
          Mượn
        </Button>
        <Button
          onClick={onClose}
        >
          Huỷ
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Storage.propTypes = {
  data: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
};
