import {
  getOr,
} from '../../../libs/lodash';

const findStorage = ({
  data, storages,
}) => Object.values(storages).find((obj) => obj.documentId === data.documentId);

const selectedDocument = ({ data, documents, types }) => types?.[documents?.[data.documentId]?.typeId ?? ''] ?? {};

const validates = ({
  data,
  addDocs,
  documents,
  types,
}) => {
  if (!getOr('', 'documentId', data)) {
    return 'Chưa chọn tài liệu.';
  }
  if (!getOr('', 'documentItem', data)) {
    return 'Chưa chọn mã sách.';
  }
  if (!getOr('', ['userInfo', 'name'], data) && !addDocs) {
    return 'Chưa chọn người mượn.';
  }
  if (!getOr('', 'requiredReturnDate', data)) {
    return 'Chưa chọn ngày phải trả.';
  }
  if (new Date(data.borrowDate) >= new Date(data.requiredReturnDate)) {
    return 'Ngày phải trả phải lớn hơn ngày mượn.';
  }
  if ((new Date(data.requiredReturnDate).getDate() - new Date(data.borrowDate).getDate()) > getOr(15, 'duration', selectedDocument({ data, documents, types }))) {
    return 'Vượt quá thời hạn mượn tối đa.';
  }
  if ((getOr('', 'returnDate', data) && (new Date(data.returnDate) <= new Date(data.borrowDate)))) {
    return 'Ngày trả thực tế phải lớn hơn ngày mượn.';
  }
  return '';
};

export {
  validates,
  findStorage,
  selectedDocument,
};
