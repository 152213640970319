import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';

import Documents from './Documents';
import Filters from './Filters';
import Links from './Links';

export default function Home({ categoryId }) {
  const [filters, setFilters] = useState({});

  return (
    <div>
      <div style={{
        display: 'flex',
        marginBottom: 50,
      }}
      >
        <StyledFilters>
          <Filters filters={filters} setFilters={setFilters} categoryId={categoryId} />
          <Links />
        </StyledFilters>
        <StyledRecords>
          <Documents filters={filters} setFilters={setFilters} categoryId={categoryId} />
        </StyledRecords>
      </div>
    </div>
  );
}

Home.defaultProps = {
  categoryId: undefined,
};

Home.propTypes = {
  categoryId: PropTypes.string,
};

const StyledFilters = styled.div`
  padding: 10px;
  width: 250px;
`;

const StyledRecords = styled.div`
  padding: 10px;
  flex: 1;
`;
