import {
  UilQrcodeScan,
  UilBrowser,
  UilText,
} from '@iconscout/react-unicons';
import {
  Box,
  Tabs,
  Tab,
} from '@xbotvn/mui';
// import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Code from './Code';
import PosterList from './PosterList';
import SymbolList from './SymbolList';

function Export() {
  const [active, setActive] = useState(0);

  const TabPanel = (props) => {
    const {
      children,
      value, index,
      ...other
    } = props ?? {};
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{
            p: 1, overflowY: 'scroll', height: window.innerHeight - 400, position: 'relative',
          }}
          >
            {children}
          </Box>
        )}
      </div>
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="space-around"
    >
      <Box
        width="100%"
        height={40}
        sx={{
          '& .MuiTabs-flexContainer': {
            height: 40,
          },
          '& .MuiButtonBase-root': {
            minHeight: 40,
          },
          '& .MuiTabs-scroller': {
            height: 40,
          },
        }}
      >
        <Tabs
          value={active}
          onChange={(e, value) => setActive(value)}
          variant="fullWidth"
          scrollButtons="auto"
          sx={{
            height: '40px',
          }}
        >
          <Tab icon={<UilText />} iconPosition="start" label="Xuất bảng ký hiệu" />
          <Tab icon={<UilBrowser />} iconPosition="start" label="Xuất Mã phích" />
          <Tab icon={<UilQrcodeScan />} iconPosition="start" label="Xuất mã code" />
        </Tabs>
        <TabPanel value={active} index={0}>
          <SymbolList />
        </TabPanel>
        <TabPanel value={active} index={1}>
          <PosterList />
        </TabPanel>
        <TabPanel value={active} index={2}>
          <Code />
        </TabPanel>
      </Box>
    </Box>
  );
}

Export.propTypes = {

};

export default Export;
