import assign from 'lodash/fp/assign';
import chunk from 'lodash/fp/chunk';
import cloneDeep from 'lodash/fp/cloneDeep';
import compact from 'lodash/fp/compact';
import compose from 'lodash/fp/compose';
import concat from 'lodash/fp/concat';
import countBy from 'lodash/fp/countBy';
import debounce from 'lodash/fp/debounce';
import every from 'lodash/fp/every';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import findIndex from 'lodash/fp/findIndex';
import first from 'lodash/fp/first';
import flatten from 'lodash/fp/flatten';
import flattenDeep from 'lodash/fp/flattenDeep';
import flow from 'lodash/fp/flow';
import forEach from 'lodash/fp/forEach';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import groupBy from 'lodash/fp/groupBy';
import head from 'lodash/fp/head';
import includes from 'lodash/fp/includes';
import indexOf from 'lodash/fp/indexOf';
import intersection from 'lodash/fp/intersection';
import isArray from 'lodash/fp/isArray';
import isEmpty from 'lodash/fp/isEmpty';
import isNumber from 'lodash/fp/isNumber';
import join from 'lodash/fp/join';
import keyBy from 'lodash/fp/keyBy';
import keys from 'lodash/fp/keys';
import last from 'lodash/fp/last';
import defaultMap from 'lodash/fp/map';
import maxBy from 'lodash/fp/maxBy';
import now from 'lodash/fp/now';
import omit from 'lodash/fp/omit';
import orderBy from 'lodash/fp/orderBy';
import pull from 'lodash/fp/pull';
import range from 'lodash/fp/range';
import reduce from 'lodash/fp/reduce';
import replace from 'lodash/fp/replace';
import set from 'lodash/fp/set';
import size from 'lodash/fp/size';
import some from 'lodash/fp/some';
import sortBy from 'lodash/fp/sortBy';
import split from 'lodash/fp/split';
import sumBy from 'lodash/fp/sumBy';
import toArray from 'lodash/fp/toArray';
import toLower from 'lodash/fp/toLower';
import toUpper from 'lodash/fp/toUpper';
import trim from 'lodash/fp/trim';
import trimEnd from 'lodash/fp/trimEnd';
import uniq from 'lodash/fp/uniq';
import uniqBy from 'lodash/fp/uniqBy';
import unset from 'lodash/fp/unset';
import values from 'lodash/fp/values';

const map = defaultMap.convert({
  cap: false,
});

export {
  toUpper,
  chunk,
  sumBy,
  compose,
  omit,
  keyBy,
  get,
  getOr,
  some,
  range,
  indexOf,
  includes,
  isArray,
  map,
  forEach,
  keys,
  trimEnd,
  toArray,
  join,
  find,
  filter,
  isNumber,
  cloneDeep,
  concat,
  debounce,
  uniqBy,
  orderBy,
  flow,
  set,
  unset,
  split,
  findIndex,
  every,
  compact,
  trim,
  isEmpty,
  reduce,
  flatten,
  uniq,
  sortBy,
  first,
  head,
  intersection,
  assign,
  values,
  countBy,
  flattenDeep,
  replace,
  groupBy,
  pull,
  size,
  now,
  last,
  maxBy,
  toLower,
};
