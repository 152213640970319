import {
  all,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import {
  listingApi,
} from '../../api';
import {
  errorHandler, successHandler,
} from '../../libs/ga';
import {
  cloneDeep,
  forEach,
  values,
  getOr,
  groupBy,
  map,
  filter,
  flow,
} from '../../libs/lodash';

import {
  AUTHOR,
  CATEGORY,
  DOCUMENT,
  LISTING,
  PRODUCER,
  TYPE,
  STORAGE,
} from './constants';

function* storeDocument(data = {}, merge = true) {
  yield put({
    type: DOCUMENT.update,
    data,
    merge,
  });
}

function* fetchDocument({
  data,
}) {
  yield* storeDocument(data);
}

function* updateDocument({
  data, onSuccess,
}) {
  try {
    const {
      _id,
      ...rest
    } = data;
    let id = _id;
    if (_id) {
      const {
        document,
      } = yield select();
      const updatedData = cloneDeep(document.data);
      yield call(() => new Promise((resolve, reject) => {
        listingApi.update({
          collection: 'document',
          payload: {
            _id,
            data: rest,
          },
        }).then(() => resolve(true))
          .catch((error) => reject(error));
      }));
      updatedData[_id] = data;
      yield* storeDocument(updatedData);
    } else {
      const {
        newData,
      } = yield call(() => new Promise((resolve, reject) => {
        listingApi.create({
          collection: 'document',
          payload: {
            data,
          },
        }).then((res) => resolve(res.data))
          .catch((err) => reject(err));
      }));
      id = newData._id;
      yield* storeDocument({
        [newData._id]: newData,
      });
    }
    successHandler('Thành công', 'Cập nhật thành công', onSuccess(id));
  } catch (error) {
    yield* storeDocument();
    errorHandler('Thất bại', error);
  }
}

function* removeDocument({
  _id,
  onSuccess,
}) {
  const {
    document,
    user,
    storage,
  } = yield select();
  const { activeUnit } = user;
  try {
    yield call(() => new Promise((resolve, reject) => {
      listingApi.remove({
        collection: 'document',
        payload: {
          _id,
        },
      }).then(() => resolve(true))
        .catch((error) => reject(error));
    }));

    yield call(() => new Promise((resolve, reject) => {
      listingApi.remove({
        collection: 'documentItem',
        payload: {
          conditions: {
            unitId: activeUnit,
            documentId: {
              $in: [_id],
            },
          },
        },
      }).then(() => resolve(true))
        .catch((error) => reject(error));
    }));

    yield put({
      type: STORAGE.handlers.remove,
      ids: flow(
        filter(({ documentId }) => documentId === _id),
        map(({ _id: docId }) => docId),
      )(storage?.data ?? {}),
    });

    const newData = cloneDeep(document.data);
    delete newData[_id];
    yield* storeDocument(newData, false);

    successHandler('Thành công', 'Xóa thành công', onSuccess);
  } catch (error) {
    yield* storeDocument();
    errorHandler('Thất bại', error);
  }
}
function* updateCategory(data) {
  const {
    category,
  } = yield select();
  const updated = cloneDeep(category.data);
  const groupData = groupBy('categoryId', data);
  yield forEach.convert({
    cap: false,
  })(
    (arr, key) => {
      updated[key].addTo = getOr(0, [key, 'addTo'], updated) + (arr.length || 0);
    },
    groupData,
  );
  yield call(() => new Promise((resolve, reject) => {
    listingApi.updateRecords({
      collection: 'category',
      payload: {
        data: map.convert({
          cap: false,
        })(
          (arr, key) => ({
            [key]: {
              addTo: (arr.length || 0) + getOr(0, ['data', key, 'addTo'], category),
            },
          }),
          groupData,
        ),
      },
    }).then(() => resolve(true))
      .catch((error) => reject(error));
  }));
}

function* handlerCreate({
  data: record,
}) {
  const {
    data, collection,
  } = record;
  let type;
  switch (collection) {
    case 'category': type = CATEGORY.handlers.import;
      break;
    case 'type': type = TYPE.handlers.import;
      break;
    case 'author': type = AUTHOR.handlers.import;
      break;
    default: type = PRODUCER.handlers.import;
      break;
  }
  yield put({
    type,
    data: values(data),
  });
}

function* importDocument({
  data = [], storageData = [], documentItems = [],
}) {
  const newStorages = storageData;
  const newDocuments = [];
  const updateDocuments = [];
  data.forEach(({ storages, ...doc }) => {
    if (storages && !storageData.length) newStorages.push({ ...storages, unitId: doc.unitId, documentId: doc._id });
    if (doc?.createdAt) {
      updateDocuments.push(doc);
    } else {
      newDocuments.push(doc);
    }
  });

  yield call(() => new Promise((resolve, reject) => {
    listingApi.importData({
      collection: 'document',
      payload: {
        data: newDocuments,
      },
    }).then(() => resolve(true))
      .catch((err) => reject(err));
  }));

  if (updateDocuments.length) {
    yield call(() => new Promise((resolve, reject) => {
      listingApi.updates({
        collection: 'document',
        payload: {
          data: updateDocuments,
        },
      }).then(() => resolve(true))
        .catch((err) => reject(err));
    }));
  }

  if (newStorages.length || storageData.length) {
    yield call(() => new Promise((resolve, reject) => {
      listingApi.importData({
        collection: 'storage',
        payload: {
          data: newStorages,
        },
      }).then(() => resolve(true))
        .catch((err) => reject(err));
    }));
  }

  if (documentItems.length) {
    yield call(() => new Promise((resolve, reject) => {
      listingApi.create({
        collection: 'documentItem',
        payload: {
          data: documentItems,
        },
      }).then(() => resolve(true))
        .catch((err) => reject(err));
    }));
  }

  const {
    document,
    storage,
  } = yield select();
  const newDocs = cloneDeep(document.data);
  const updatedStorages = cloneDeep(storage.data);
  forEach(
    (obj) => {
      newDocs[obj._id] = obj;
      if (obj.storages && !storageData.length) {
        const updatedStorage = { ...obj.storages, unitId: obj.unitId, documentId: obj._id };
        updatedStorages[obj?.storages?._id] = updatedStorage;
      }
    },
    data,
  );
  forEach(
    (item) => {
      updatedStorages[item._id] = item;
    },
    storageData,
  );
  yield* storeDocument(newDocs);
  yield* updateCategory(newDocuments);
  if (newStorages.length) {
    yield put({
      type: STORAGE.update,
      data: updatedStorages,
      merge: true,
    });
  }
  successHandler('Thành công', 'Cập nhật thành công');
}

export default function* categorySaga() {
  yield all([
    yield takeEvery(DOCUMENT.handlers.fetch, fetchDocument),
    yield takeEvery(DOCUMENT.handlers.update, updateDocument),
    yield takeEvery(DOCUMENT.handlers.import, importDocument),
    yield takeEvery(DOCUMENT.handlers.remove, removeDocument),
    yield takeEvery(LISTING.handlers.create, handlerCreate),
  ]);
}
