import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { hasChildrenUnit } from '../../../libs/utils';

import Filter from './Filter';
import FilterNewDocs from './FilterNewDocs';

export default function Filters({
  setFilters,
  filters,
  categoryId,
}) {
  const unitInfo = useSelector(({ unit, user }) => unit.data?.[user?.activeUnit]);

  const filterList = [];

  if (!categoryId) {
    filterList.push({
      filterType: 'category',
      type: 'categories',
      name: 'Danh mục sách',
    });
  }

  filterList.push(
    {
      type: 'authors',
      filterType: 'author',
      name: 'Tác giả',
    },
    {
      type: 'producers',
      filterType: 'producer',
      name: 'Nhà xuất bản',
    },
  );
  filterList.splice(1, 0,
    {
      type: 'types',
      filterType: 'type',
      name: 'Loại sách',
    });
  if (hasChildrenUnit(unitInfo)) {
    filterList.push(
      {
        type: 'age',
        filterType: 'age',
        name: 'Độ tuổi',
      },
      {
        type: 'documentSubject',
        filterType: 'documentSubject',
        name: 'Chủ đề',
      },
    );
  }

  return (
    <div>
      <FilterNewDocs
        filters={filters.docs}
        onChange={(docs) => {
          setFilters((prev) => ({
            ...prev,
            docs,
          }));
        }}
      />
      {
        filterList.map(({ name, type, filterType }) => (
          <Filter
            key={type}
            filters={filters?.[type]}
            onChange={(data) => {
              setFilters((prev) => ({
                ...prev,
                [type]: data,
              }));
            }}
            filterName={name}
            filterType={filterType}
          />
        ))
      }
    </div>
  );
}

Filters.defaultProps = {
  categoryId: undefined,
};

Filters.propTypes = {
  filters: PropTypes.shape().isRequired,
  setFilters: PropTypes.func.isRequired,
  categoryId: PropTypes.string,
};
