import './style.css';

import { Notification } from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, {
  useCallback, useMemo,
} from 'react';
import {
  useDropzone,
} from 'react-dropzone';

import {
  defaultAllowFileTypes,
} from './FileTypes';

const baseStyle = {
  padding: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#137CC0',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  outline: 'none',
  transition: 'all .1s linear',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function Dropzone({
  handleFiles,
  allowFileTypes,
  maxSize,
  allowMultipleFiles,
  styleOverride,
}) {
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      console.log(rejectedFiles);
      Notification.error(`Không hợp lệ: ${rejectedFiles.map((rejectedFile) => `${rejectedFile.name} (${rejectedFile.type})`).join(', ')}`);
      Notification.warn(`Dung lượng tối đa cho phép: ${maxSize / 1024 / 1024}MB, định dạng cho phép: ${allowFileTypes.join(', ')}`);
      return;
    }
    handleFiles(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    ...(allowFileTypes.length && {
      accept: allowFileTypes,
    }),
    maxSize,
    multiple: allowMultipleFiles,
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...styleOverride,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isDragActive,
    isDragReject,
  ]);

  return (
    <div>
      <div {...getRootProps({
        className: 'dropzone dropzone-hoverarea',
        style,
      })}
      >
        <input {...getInputProps()} />
        <p>Kéo thả file vô đây, hoặc bấm để chọn file</p>
      </div>
      {acceptedFiles.length > 0 && (
        <aside>
          <h4>Files đã chọn</h4>
          <ul>
            {acceptedFiles.map((file) => (
              <li key={file.name}>
                {`${file.name} - ${Math.round((file.size * 100) / 1024) / 100} KB`}
              </li>
            ))}
          </ul>
        </aside>
      )}
    </div>
  );
}

Dropzone.defaultProps = {
  handleFiles: () => { },
  allowFileTypes: defaultAllowFileTypes,
  maxSize: 50 * 1024 * 1024, // 50 MB
  allowMultipleFiles: false,
  styleOverride: undefined,
};

Dropzone.propTypes = {
  handleFiles: PropTypes.func,
  allowFileTypes: PropTypes.arrayOf(PropTypes.string),
  maxSize: PropTypes.number,
  allowMultipleFiles: PropTypes.bool,
  styleOverride: PropTypes.shape({}),
};
