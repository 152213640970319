import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Waiting,
} from '@xbotvn/mui';
import html2pdf from 'html2pdf.js';
import PropTypes from 'prop-types';
import React, {
  useMemo,
  useState,
} from 'react';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import {
  useSelector,
} from 'react-redux';

import { listingApi as itemsAPI } from '../../../api';
import {
  map,
  chunk,
} from '../../../libs/lodash';
import * as Styled from '../styles';

export default function ExportDocumentCode({ onClose, data }) {
  const {
    unitName,
  } = useSelector(({
    user,
    unit,
  }) => ({
    unitName: unit?.data?.[user.activeUnit]?.name ?? '',
  }));

  const [options, setOptions] = useState('bar');
  const [handling, setHandling] = useState(false);

  const displayTitle = (value) => {
    if (value.length > 65) return `${value.slice(0, 65)}...`;
    return value;
  };

  const renderQRCode = useMemo(() => (
    <Styled.GroupCode>
      {map.convert({ cap: false })(
        (codes, index) => (
          <div className={index ? 'page-break' : 'group-codes'} key={index}>
            {map(
              ({ _id, name }) => (
                <Styled.Code key={_id}>
                  <Styled.GroupQRCode>
                    <Styled.TitleQRCode>
                      {name}
                    </Styled.TitleQRCode>
                    <Styled.QR>
                      <QRCode size={70} value={_id} />
                    </Styled.QR>
                  </Styled.GroupQRCode>
                </Styled.Code>
              ),
              codes,
            )}
            <Styled.NumberPage>{index + 1}</Styled.NumberPage>
          </div>
        ),
        chunk(24, data),
      )}
    </Styled.GroupCode>
  ), [options, data]);

  const renderBarCode = useMemo(() => (
    <Styled.GroupCode>
      {map.convert({ cap: false })(
        (codes, index) => (
          <div className={index ? 'page-break' : 'group-codes'}>
            {map(
              ({ _id, name }) => (
                <Styled.Code key={_id}>
                  <div style={{ width: '100%' }}>
                    <Styled.UnitName>
                      {unitName.toUpperCase()}
                    </Styled.UnitName>
                    <Styled.BC>
                      <Barcode fontSize={50} value={_id} />
                    </Styled.BC>
                    <Styled.TitleBarcode>
                      {displayTitle(name)}
                    </Styled.TitleBarcode>
                  </div>

                </Styled.Code>
              ),
              codes,
            )}
            <Styled.NumberPage>{index + 1}</Styled.NumberPage>
          </div>
        ),
        chunk(24, data),
      )}
    </Styled.GroupCode>
  ), [options, data, unitName]);

  return (
    <>
      <Dialog
        open
        maxWidth="md"
        fullWidth
        onClose={onClose}
      >
        <DialogTitle onClose={onClose}>Danh sách mã tài liệu</DialogTitle>
        <DialogContent sx={{
          overflowY: 'scroll',
        }}
        >
          {handling ? <Waiting fullscreen /> : null}
          <Box style={{ display: 'flex' }}>
            <FormControl>
              <FormLabel>Chọn loại code:</FormLabel>
              <RadioGroup
                row
                onChange={(e) => setOptions(e.target.value)}
                value={options}
              >
                <FormControlLabel label="Barcode" control={<Radio value="bar" />} />
                <FormControlLabel label="QR code" control={<Radio value="qr" />} />
              </RadioGroup>
            </FormControl>
          </Box>
          <Styled.Container id="print-area">
            {options === 'bar' ? renderBarCode : renderQRCode}
          </Styled.Container>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={async () => {
              setHandling(true);
              const clonedNode = document.getElementById('print-area').cloneNode(true);
              Object.values(clonedNode.children || {}).forEach((element) => {
              // eslint-disable-next-line no-param-reassign
                element.style.height = 'auto';
              });
              await html2pdf()
                .set({
                  filename: `Danh sách ${options} code.pdf`,
                  image: { type: 'jpeg', quality: 1 },
                  html2canvas: { scale: 1 },
                  jsPDF: { format: 'a4', orientation: 'portrait' },
                  pagebreak: { mode: 'avoid-all' },
                })
                .from(clonedNode)
                .save();
              await itemsAPI.update({
                collection: 'documentItem',
                payload: {
                  data: data.map(({ _id }) => ({ _id, printed: true })),
                },
              });
              setHandling(false);
            }}
          >
            Xuất
          </Button>
          <Button
            color="error"
            onClick={onClose}
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ExportDocumentCode.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
};
