import {
  UilTrash,
  UilPen,
  UilUpload,
  UilImport,
  UilPlus,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  ConfirmDialog,
  Divider,
  TextField,
  IconButton,
  Box,
  Waiting,
  FormLabel,
  Notification,
} from '@xbotvn/mui';
import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import DropzoneModal from '../../../../components/DropzoneModal';
import Table from '../../../../components/Table';
import {
  WAREHOUSE as WAREHOUSECONFIG,
} from '../../../../libs/config';
import {
  cloneDeep,
  filter,
  getOr,
  values,
  forEach,
  find,
} from '../../../../libs/lodash';
import {
  FILE,
  WAREHOUSE,
} from '../../../../redux/actions/constants';
import {
  importExcel,
} from '../../Helpers';

export default function Warehouse() {
  const dispatch = useDispatch();

  const {
    warehouseData,
    storages,
    documents,
    handling,
    activeUnit,
  } = useSelector(({
    warehouse, storage, document, user,
  }) => ({
    warehouseData: warehouse.data,
    storages: storage.data,
    documents: document.data,
    handling: warehouse.handling,
    activeUnit: user.activeUnit,
  }));

  const [editor, setEditor] = useState();
  const [showImport, setShowImport] = useState();
  const [removeConfirm, setRemoveConfirm] = useState(false);

  const data = filter((rc) => rc.warehouseId === getOr('', '_id', editor), storages);

  useEffect(() => {
    forEach((storage) => {
      storages[storage?._id] = {
        ...storage,
        name: documents[storage.documentId].name,
      };
    }, storages);
  }, [documents, storages]);

  const cols = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 100,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Tài liệu',
      accessor: 'name',
      style: {
        width: 350,
      },
    },
    {
      Header: 'Số lượng',
      accessor: 'amount',
      style: {
        width: 150,
      },
    },
  ], []);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 100,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Tên',
      accessor: 'name',
      style: {
        width: 350,
      },
    },
    {
      Header: 'Ký hiệu',
      accessor: 'symbol',
    },
    {
      Header: () => (
        <Button
          startIcon={<UilPlus />}
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => setEditor({
            unitId: activeUnit,
            name: '',
            symbol: '',
          })}
        >
          Thêm
        </Button>
      ),
      id: 'add',
      style: {
        width: 250,
      },
      Cell: (rowProps) => {
        const rowValue = getOr({}, 'row.original', rowProps);
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => setEditor(cloneDeep(rowValue))}
              color="primary"
              size="small"
              sx={{ marginRight: '5px' }}
            >
              <UilPen />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              size="small"
              color="error"
              onClick={() => {
                setRemoveConfirm(rowValue._id);
              }}
              sx={{ marginLeft: '5px' }}
            >
              <UilTrash />
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ], []);

  const renderEditor = () => (
    <Dialog
      open
      onClose={() => setEditor()}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={() => setEditor()}>{editor._id ? 'Sửa' : 'Thêm'}</DialogTitle>
      <DialogContent>
        <TextField
          label="Tên"
          required
          fullWidth
          value={editor.name || ''}
          onChange={(e) => setEditor((prev) => ({
            ...prev,
            name: e.target.value,
          }))}
        />
        <TextField
          label="Ký hiệu"
          required
          fullWidth
          value={editor.symbol || ''}
          onChange={(e) => setEditor((prev) => ({
            ...prev,
            symbol: e.target.value,
          }))}
        />
        {editor._id && (
          <FormLabel label="Thông tin kho">
            <Table columns={cols} data={values(data)} alwaysShowPagination />
          </FormLabel>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            if (!editor.name) {
              Notification.warn('Tên không được trống.');
              return;
            }
            if (!editor.symbol) {
              Notification.warn('Ký hiệu không được trống.');
              return;
            }
            dispatch({
              type: WAREHOUSE.handlers.update,
              data: editor,
              onSuccess: () => setEditor(),
            });
          }}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={() => setEditor()}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box>
      {editor ? renderEditor() : null}
      {handling && <Waiting />}
      {removeConfirm ? (
        <ConfirmDialog
          title="Bạn có chắc chắn muốn xóa?"
          color="error"
          confirmLabel="Xoá"
          cancelLabel="Huỷ"
          maxWidth="sm"
          fullWidth
          open
          onClose={() => setRemoveConfirm()}
          onConfirm={async () => {
            dispatch({
              type: WAREHOUSE.handlers.remove,
              _id: removeConfirm,
            });
            setRemoveConfirm();
          }}
        >
          <DialogContent />
        </ConfirmDialog>
      ) : null}
      {showImport && (
        <DropzoneModal
          onConfirm={(files) => {
            if (!files.length) return;
            importExcel({
              files,
              activeUnit,
              config: WAREHOUSECONFIG,
              onSuccess: (records) => {
                if (records.some(({ errors }) => errors)) {
                  const errorStr = find((rec) => rec.errors)(records)?.errors || '';
                  Notification.error(errorStr);
                } else {
                  dispatch({
                    type: WAREHOUSE.handlers.import,
                    data: records,
                  });
                  setShowImport();
                }
              },
            });
          }}
          onClose={() => setShowImport()}
        />
      )}
      <Box marginLeft={1} padding={1}>
        <Button
          startIcon={<UilUpload />}
          color="success"
          size="small"
          onClick={() => setShowImport(true)}
          sx={{
            marginRight: 1,
          }}
        >
          Nhập từ Excel
        </Button>
        <Button
          startIcon={<UilImport />}
          color="primary"
          size="small"
          onClick={() => dispatch({
            type: FILE.handlers.download,
            data: {
              unitId: activeUnit,
              fileName: 'Mau_Kho.xlsx',
            },
          })}
        >
          Tải file mẫu
        </Button>
      </Box>
      <Table columns={columns} data={values(warehouseData)} alwaysShowPagination />
    </Box>
  );
}
