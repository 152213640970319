import { UilQrcodeScan } from '@iconscout/react-unicons';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Box,
  FormGroup,
  Notification,
  Grid,
  Stack,
} from '@xbotvn/mui';
import cryptoRandomString from 'crypto-random-string';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import SingleSelect from '../../../components/SingleSelect';
import {
  cloneDeep,
  filter,
  find,
  get,
  getOr,
  keyBy,
  map,
  includes,
  omit,
} from '../../../libs/lodash';
import {
  BORROW,
} from '../../../redux/actions/constants';
import * as Styles from '../styles';

import {
  validates,
} from './Helpers';
import Records from './Records';
import Scanner from './Scanner';

const styleForm = {
  padding: '5px 30px',
  display: 'inline-table',
};

export default function BorrowModal({
  data,
  type,
  items,
  onClose,
  currentList,
}) {
  const dispatch = useDispatch();
  const inputScanner = useRef(null);

  const {
    units,
    documents,
    members,
    types,
  } = useSelector(({
    unit,
    user,
    document,
    member,
    type: initialTypes,
  }) => ({
    units: get(user.activeUnit, unit.data),
    documents: document.data,
    members: member.data,
    types: initialTypes.data,
  }));

  const [doc, setDoc] = useState(data || {});
  const [records, setRecords] = useState([]);
  const [valueScanner, setValueScanner] = useState('');
  const [isScan, setIsScan] = useState(true);

  const clearScanner = () => {
    if (inputScanner.current !== null) {
      setTimeout(() => {
        setValueScanner('');
        inputScanner.current.value = '';
        inputScanner.current.focus();
      }, 1000);
    }
  };

  const itemsInitial = () => keyBy('_id', items);

  useEffect(() => {
    if (valueScanner) {
      setDoc((prevValue) => ({
        ...prevValue,
        documentItem: valueScanner,
        documentId: itemsInitial()?.[valueScanner]?.documentId ?? '',
      }));
    }
    clearScanner();
  }, [valueScanner, documents]);

  const getReturnDate = useMemo(() => {
    if (doc.requiredReturnDate) return new Date(doc.requiredReturnDate);
    if (!getOr('', 'documentId', doc)) return new Date();
    const selectedDoc = getOr({}, [getOr('', 'documentId', doc), 'typeId'], documents);
    return moment(new Date())
      .add(getOr(15, [selectedDoc, 'duration'], types), 'days')
      .toString();
  }, [doc, documents, types]);

  const listSDKCB = useMemo(() => map(
    ({
      _id, registrationNumber,
    }) => ({
      label: registrationNumber, value: _id,
    }),
    filter(({
      registrationNumber: code,
    }) => code, documents),
  ), [documents]);

  const codeBookData = useMemo(() => {
    const itemsHandle = (itemsInitial());
    if (records) {
      map((item) => {
        itemsHandle[item.documentItem] = {
          ...itemsHandle[item.documentItem],
          borrowed: true,
        };
      }, records);
    }
    return itemsHandle;
  }, [items, records, documents, data, doc]);

  return (
    <>
      <Dialog
        open
        onClose={onClose}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle onClose={onClose}>{doc._id ? 'Cập nhật' : 'Thêm mới'}</DialogTitle>
        <DialogContent>
          {!doc._id
            ? (
              <Box display="flex" alignItems="center">
                <Button
                  variant="outlined"
                  startIcon={<UilQrcodeScan />}
                  color="primary"
                  onClick={() => {
                    if (!isScan) {
                      clearScanner();
                    }
                    setIsScan(!isScan);
                  }}
                >
                  {`${!isScan ? 'Mở' : 'Tắt'}`}
                </Button>

                {isScan
                  ? (
                    <FormGroup
                      style={styleForm}
                      label="Mã sách"
                      labelInfo="*"
                    >
                      <Scanner
                        inputRef={inputScanner}
                        value={valueScanner}
                        onChange={(value) => setValueScanner(value)}
                      />
                    </FormGroup>
                  )
                  : (
                    <Box sx={{
                      '& .MuiTextField-root': { m: 1, width: '25ch' },
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                    >
                      <SingleSelect
                        value={doc?.documentId ?? ''}
                        required
                        options={map(
                          ({
                            _id, name,
                          }) => ({
                            label: name, value: _id,
                          }),
                          documents,
                        )}
                        onChange={(value) => setDoc((prevValue) => ({
                          ...prevValue,
                          documentId: value,
                        }))}
                        InputProps={{
                          placeholder: 'Chọn tài liệu',
                          required: true,
                        }}
                      />
                      <SingleSelect
                        value={doc?.documentItem ?? ''}
                        options={map(
                          ({
                            _id,
                          }) => ({
                            label: _id, value: _id,
                          }),
                          filter(({ documentId, borrowed }) => (documentId === doc?.documentId && !borrowed) ?? '', codeBookData),
                        )}
                        onChange={(value) => setDoc((prevValue) => ({
                          ...prevValue,
                          documentItem: value,
                        }))}
                        InputProps={{
                          placeholder: 'Chọn mã sách',
                          required: true,
                        }}
                      />
                    </Box>
                  )}
              </Box>
            ) : null}
          <Box sx={{
            '& .MuiTextField-root': { m: 1, width: '200px' },
            display: 'flex',
          }}
          >
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {(isScan || doc._id) ? (
                <TextField
                  disabled
                  label="Tên tài liệu"
                  value={getOr('', [getOr('', 'documentId', doc), 'name'], documents)}
                />
              ) : null}
              {doc._id
                ? (
                  <TextField
                    disabled
                    label="Mã sách"
                    value={doc?.documentItem ?? ''}
                  />
                ) : null}
              <Stack direction="row">
                <SingleSelect
                  disabled={!!doc._id}
                  placeholder="Chọn SĐKCB"
                  value={doc.documentId}
                  options={listSDKCB}
                  onChange={(value) => setDoc((prevValue) => ({
                    ...prevValue,
                    documentId: value,
                  }))}
                  InputProps={{
                    label: 'SĐKCB',
                    fullWidth: false,
                  }}
                />
                <SingleSelect
                  placeholder="Chọn người mượn"
                  value={getOr('', 'email', doc.userInfo)}
                  options={map(
                    ({
                      name, email,
                    }) => ({
                      label: name, value: email,
                    }),
                    {
                      ...keyBy('email', getOr([], 'members', units)),
                      ...keyBy('email', filter((mb) => new Date() < new Date(mb.endDate), members)),
                    },
                  )}
                  onChange={(value) => setDoc((prevValue) => ({
                    ...prevValue,
                    userInfo: find((obj) => obj.email === value, {
                      ...keyBy('email', getOr([], 'members', units)),
                      ...keyBy('email', filter((mb) => new Date() < new Date(mb.endDate), members)),
                    }),
                  }))}
                  InputProps={{
                    label: 'Tên người mượn',
                    required: true,
                  }}
                />
              </Stack>
              <Grid item>
                <Styles.InputDatePicker
                  dateFormat="dd/MM/yyyy"
                  value={moment(new Date(doc.borrowDate)).toDate()}
                  onChange={(date) => setDoc((prevValue) => ({
                    ...prevValue,
                    borrowDate: date ? date.toString() : '',
                  }))}
                  label="Ngày mượn"
                />
                <Styles.InputDatePicker
                  dateFormat="dd/MM/yyyy"
                  value={moment(getReturnDate).toDate()}
                  onChange={(date) => setDoc((prevValue) => ({
                    ...prevValue,
                    requiredReturnDate: date ? date.toString() : '',
                  }))}
                  label="Ngày phải trả"
                  labelInfo="*"
                />
                {(!doc.addNew && includes(type, ['new', 'late']))
                  ? (
                    <Styles.InputDatePicker
                      label="Ngày trả thực tế"
                      dateFormat="dd/MM/yyyy"
                      value={doc.returnDate ? moment(new Date(doc.returnDate)).toDate() : ''}
                      onChange={(date) => setDoc((prevValue) => ({
                        ...prevValue,
                        returnDate: date ? date.toString() : '',
                      }))}
                    />
                  )
                  : null}
              </Grid>
            </Grid>
          </Box>
          {doc.addNew && (<Records data={records} setData={setRecords} setEdit={setDoc} />)}
        </DialogContent>
        <DialogActions>
          {(doc.addNew && !getOr(false, 'edit', doc)) && (
          <Button
            color="primary"
            onClick={() => {
              const newRecords = cloneDeep(records);
              doc.status = type;
              doc.requiredReturnDate = getReturnDate;
              const checkDoc = validates({
                data: doc,
                addDocs: true,
                documents,
                types,
              });
              if (currentList.find(({ documentItem }) => documentItem === doc.documentItem)) {
                Notification.warn('Mã sách này đã mượn, vui lòng chọn mã sách khác');
                return;
              }
              if (checkDoc) {
                Notification.warn(checkDoc);
              } else {
                doc._id = cryptoRandomString({
                  length: 24,
                });
                newRecords.push(doc);
                setRecords(newRecords);
                setDoc(data);
                if (isScan) {
                  clearScanner();
                }
              }
            }}
          >
            Thêm tài liệu
          </Button>
          )}
          {getOr(false, 'edit', doc) && (
          <Button
            color="primary"
            onClick={() => {
              const checkDoc = validates({
                data: doc,
                addDocs: true,
                documents,
                types,
              });
              if (checkDoc) {
                Notification.warn(checkDoc);
              } else {
                setRecords(records.filter(({ _id }) => _id !== doc?._id ?? '').concat(doc));
                setDoc(data);
              }
            }}
          >
            Cập nhật tài liệu
          </Button>
          )}
          <Button
            color="success"
            onClick={() => {
              if (records.length) {
                if (records.filter(({ userInfo }) => !userInfo).length) {
                  Notification.warn('Có tài liệu chưa chọn người mượn.');
                } else {
                  const dataOmitAddNew = map((record) => omit('addNew', record), records);
                  dispatch({
                    type: BORROW.handlers.import,
                    data: dataOmitAddNew,
                  });
                  onClose();
                }
              } else {
                doc.requiredReturnDate = getReturnDate;
                const checkDoc = validates({
                  data: doc,
                  documents,
                  types,
                });
                if (checkDoc) {
                  Notification.warn(checkDoc);
                } else {
                  let cloned = cloneDeep(doc);
                  if (type !== 'done' && currentList.find(({ documentItem, _id }) => (documentItem === cloned.documentItem && _id !== cloned?._id))) {
                    Notification.warn('Mã sách này đã mượn, vui lòng chọn mã sách khác');
                    return;
                  }

                  if (new Date() > new Date(cloned.requiredReturnDate)) {
                    cloned.status = 'late';
                  }
                  if (cloned.returnDate) {
                    cloned.status = 'done';
                  }
                  cloned = omit('addNew', cloned);
                  dispatch({
                    type: BORROW.handlers.update,
                    data: cloned,
                  });
                  onClose();
                  setDoc({});
                }
              }
            }}
          >
            Lưu
          </Button>
          <Button color="error" onClick={onClose}>Đóng</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

BorrowModal.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  data: PropTypes.shape().isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  currentList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
