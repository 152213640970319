import {
  api, getOptions,
} from './helpers';

// const getCsrfToken = () => api.get('/auth/csrf-token', getOptions()).then((result) => {
//   if (result.status === 200) {
//     getOptions().headers['X-CSRF-TOKEN'] = result.data.csrfToken;
//   }
// });

const signUp = (payload) => api.post('/auth/signup', payload, getOptions());

const signIn = (payload) => api.post('/auth/signin', payload, getOptions());

const signInAsUser = (payload) => api.post('/auth/signin-as-user', payload, getOptions());

const signInFirebase = (payload) => api.post('/auth/signin-firebase', payload, getOptions());

const logout = (payload) => api.post('/auth/logout', payload, getOptions());

export default {
  // getCsrfToken,
  signUp,
  signIn,
  signInAsUser,
  signInFirebase,
  logout,
};
