/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import { DatePicker } from '@xbotvn/mui';

import Table from '../../../components/Table';

export const StyledTable = styled(Table)`
  tr, th, td {
    border: 1px solid;
  }
`;

export const InputDatePicker = styled(DatePicker)`
  -webkit-appearance: none;
  appearance: none;
  background: #ffffff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 10px;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align: middle;
`;
