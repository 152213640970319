import { ACTIVE_YEAR } from '../../libs/config';
import {
  CATALOGS, SIGN_OUT,
} from '../actions/constants';

const initialState = {
  system: {
    cities: {
      data: {},
    },
    year: {
      data: ACTIVE_YEAR,
    },
  },
};

export default function catalogs(state = initialState, action) {
  switch (action.type) {
    case CATALOGS.handlers.getCities:
      return {
        ...state,
        system: {
          ...state.system,
          [action.id]: {
            handling: true,
          },
        },
      };

    case CATALOGS.system:
      return {
        ...state,
        system: {
          ...state.system,
          [action.id]: {
            data: action.data,
            handling: false,
          },
        },
      };

    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
