import styled from '@emotion/styled';
import { CircularProgress } from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React from 'react';

const Dimmer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff87;
  z-index: 1000;
  text-align: center;

  .bp3-spinner {
    margin-top: 15%;
  }
`;

export default function Waiting({
  message,
}) {
  return (
    <Dimmer>
      <CircularProgress color="inherit" />
      <div>{message}</div>
    </Dimmer>
  );
}

Waiting.propTypes = {
  message: PropTypes.string,
};

Waiting.defaultProps = {
  message: 'Đang xử lý...',
};
