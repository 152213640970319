import { UilExchange, UilEye } from '@iconscout/react-unicons';
import {
  Button,
  Typography,
  Card,
  Grid,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import {
  getOr,
  size,
  forEach,
  some,
  map,
} from '../../../libs/lodash';
import {
  documentCode, languages,
} from '../../../libs/options';
import { getISBD } from '../../../libs/utils';

import * as Styles from './styles';

export default function DocumentCard({
  doc,
  categories,
  types,
  authors,
  producers,
  setBorrow,
  viewMode,
  subject,
}) {
  const [isReadMore, setIsReadMore] = useState(false);
  const [readMarc21, setReadMarc21] = useState([]);

  const readMoreText = useMemo(() => {
    const docContent = doc?.content ?? '';
    const splitted = docContent.split('');
    if (size(splitted) > 100) {
      let content = splitted;
      if (!isReadMore) {
        content = splitted.slice(0, 200);
      }
      return (
        <>
          {content.join('')}
          {!isReadMore ? <span> ...</span> : null}
          <br />
          <Button
            variant="text"
            color="success"
            size="small"
            onClick={() => setIsReadMore(!isReadMore)}
            sx={{
              padding: '0 5px',
              minHeight: '0px',
            }}
          >
            {isReadMore ? 'thu gọn' : 'xem thêm'}
          </Button>
        </>
      );
    } return docContent;
  }, [doc, isReadMore]);

  const dataISBD = useMemo(() => {
    const document = { ...doc, authorName: authors?.[doc.authorId]?.name };
    const nameISBD = getISBD(document, authors, producers);
    const priceISBD = (document.price) ? `Giá tiền: ${document.price}` : '';
    return {
      nameISBD,
      priceISBD,
    };
  }, [viewMode]);

  const converData = (element, item) => {
    const data = {
      authors,
      producers,
      categories,
      subject,
    };

    if (element?.data) { return data?.[element?.data]?.[item]?.name; }
    if (element?.value === 'sameAuthor') { return (map((author) => authors?.[author]?.name)(item)).join(', '); }
    if (element?.value === 'keyword') { return (map((keyword) => keyword)(item)).join(', '); }
    if (element?.value === 'language_override') return (languages?.[item]?.label);
    return item;
  };
  const dataMarc21 = useMemo(() => {
    let dataArr = [];
    forEach.convert({ cap: false })((element, key) => {
      const item = doc?.[key];

      if (item) {
        if (!some((value) => value.codePrimary === element.codePrimary, dataArr)) {
          dataArr = [
            ...dataArr,
            {
              codePrimary: element.codePrimary,
              codeSub: element.codeSub,
              value: converData(element, item),
            },
          ];
        } else {
          dataArr = [
            ...dataArr,
            {
              codeSub: element.codeSub,
              value: converData(element, item),
            },
          ];
        }
      }
    }, documentCode);
    if (dataArr.length > 3) setReadMarc21([dataArr[0], dataArr[1], dataArr[2], dataArr[3]]);
    else setReadMarc21(dataArr);
    return dataArr;
  }, [viewMode]);

  const onClickMoreMarc21 = () => {
    if (readMarc21?.length < dataMarc21?.length) setReadMarc21(dataMarc21);
    else setReadMarc21([dataMarc21[0], dataMarc21[1], dataMarc21[2], dataMarc21[3]]);
    setIsReadMore(!isReadMore);
  };

  const readMoreMarc21 = useMemo(() => {
    const lengData = dataMarc21?.length;
    if (lengData > 3) {
      return (
        <>
          {!isReadMore ? <span> ...</span> : null}
          <br />
          <Button
            variant="text"
            color="success"
            size="small"
            onClick={onClickMoreMarc21}
            sx={{
              padding: '0 5px',
              minHeight: '0px',
            }}
          >
            {isReadMore ? 'thu gọn' : 'xem thêm'}
          </Button>
        </>
      );
    } return null;
  }, [isReadMore, dataMarc21]);

  const columns = useMemo(() => [
    {
      Header: '',
      accessor: 'codePrimary',
      disableFilters: true,
      style: {
        width: 0,
      },
    },
    {
      Header: '',
      accessor: 'codeSub',
      disableFilters: true,
      style: {
        width: 30,
      },
    },
    {
      Header: '',
      accessor: 'value',
      disableFilters: true,
      style: {
        width: 300,
      },
    }], [viewMode]);

  return (
    <>
      <Card
        elevation={6}
        key={doc._id}
        sx={{
          margin: 1,
        }}
      >
        <Grid container>
          <Grid item xs={10} style={{ textAlign: 'left' }}>
            <Styles.Header>
              <Typography
                variant="h4"
                gutterBottom
              >
                {getOr('', 'name', doc)}

              </Typography>
            </Styles.Header>
            {(viewMode === 'marc21') ? (
              <Grid sx={{ ml: 2, mb: 2 }} style={{ textAlign: 'left' }}>
                <Typography>
                  <Styles.TableMarc21 columns={columns} data={readMarc21} />
                  {readMoreMarc21}
                </Typography>
              </Grid>
            ) : ''}
            {(viewMode === 'isbd')
              ? (
                <Grid style={{ textAlign: 'left' }}>
                  <Typography sx={{ ml: 2 }}>{`${dataISBD.nameISBD}`}</Typography>
                  <Typography sx={{ ml: 2, mb: 2 }}>{dataISBD.priceISBD}</Typography>
                </Grid>
              )

              : ''}
            {(viewMode === 'friendly') ? (
              <Grid>
                <Styles.Container>
                  <Styles.Group>
                    <Styles.TitleTypo variant="action1" gutterBottom>Danh mục:</Styles.TitleTypo>
                    <Typography ml={1} variant="body1" gutterBottom>{getOr('', 'name', getOr('', doc.categoryId, categories))}</Typography>
                  </Styles.Group>

                  <Styles.Group>
                    <Styles.TitleTypo variant="action1" gutterBottom>Loại:</Styles.TitleTypo>
                    <Typography ml={1} variant="body1" gutterBottom>{getOr('', 'name', getOr('', doc.typeId, types))}</Typography>
                  </Styles.Group>

                  <Styles.Group>
                    <Styles.TitleTypo variant="action1" gutterBottom>Tác giả:</Styles.TitleTypo>
                    <Typography ml={1} variant="body1" gutterBottom>{getOr('', 'name', getOr('', doc.authorId, authors))}</Typography>
                  </Styles.Group>
                </Styles.Container>

                <Styles.Container>
                  <Styles.Group>
                    <Styles.TitleTypo variant="action1" gutterBottom>Nhà xuất bản:</Styles.TitleTypo>
                    <Typography ml={1} variant="body1" gutterBottom>{getOr('', 'name', getOr('', doc.producerId, producers))}</Typography>
                  </Styles.Group>

                  <Styles.Group>
                    <Styles.TitleTypo variant="action1" gutterBottom>SĐKCB:</Styles.TitleTypo>
                    <Typography ml={1} variant="body1" gutterBottom>{(doc?.symbol) ? `${doc?.symbol ?? ''}-${doc?.registrationNumber ?? ''}` : doc?.registrationNumber}</Typography>
                  </Styles.Group>
                </Styles.Container>
                <Styles.Container>
                  <Styles.GroupContent>
                    <Styles.TitleTypo variant="action1" gutterBottom>Nội dung tóm tắt:</Styles.TitleTypo>
                    <Typography ml={1} variant="body1" gutterBottom>{readMoreText}</Typography>
                  </Styles.GroupContent>
                </Styles.Container>
              </Grid>
            ) : ''}
          </Grid>

          <Grid item xs={2}>
            <Styles.GroupButton>
              <Button
                color="primary"
                onClick={() => setBorrow()}
              >
                <UilExchange />
                Mượn tài liệu
              </Button>
              {doc?.url
              && (
              <Button
                variant="outlined"
                onClick={() => window.open(doc.url, '_blank')}
                style={{
                  marginTop: 5,
                }}
              >
                <UilEye />
                Xem tài liệu
              </Button>
              )}
            </Styles.GroupButton>
          </Grid>
        </Grid>

      </Card>
    </>
  );
}

DocumentCard.propTypes = {
  doc: PropTypes.shape().isRequired,
  categories: PropTypes.shape().isRequired,
  types: PropTypes.shape().isRequired,
  authors: PropTypes.shape().isRequired,
  producers: PropTypes.shape().isRequired,
  setBorrow: PropTypes.func.isRequired,
  viewMode: PropTypes.string.isRequired,
  subject: PropTypes.shape().isRequired,
};
