import { UilExchange } from '@iconscout/react-unicons';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
} from '@xbotvn/mui';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';

import { paginationApi } from '../../../api';
import PaginationClient from '../../../components/PaginationClient';
import Table from '../../../components/Table';
import {
  getOr,
  map,
} from '../../../libs/lodash';

import * as Styles from './styles';

export default function DocumentItemModal({
  onClose,
  onBorrow,
  data,
}) {
  const {
    unitId,
    bookshelves,
    warehouses,
    storages,
  } = useSelector(({
    user, bookshelf, warehouse, storage,
  }) => ({
    unitId: user.activeUnit,
    bookshelves: bookshelf.data,
    warehouses: warehouse.data,
    storages: storage.data,
  }));

  const [pageItem, setPageItem] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    paginationApi.getPage('documentItem', {
      condition: {
        unitId,
        documentId: data._id,
      },
    }).then(({ data: result }) => {
      setItems(result.records);
    });
  }, [data]);

  const filtered = useMemo(() => items, [items]);

  const renderPaginationItems = useMemo(() => (
    <PaginationClient
      rows={filtered}
      onChange={({
        activeRows,
      }) => {
        const showRows = map((row) => ({
          ...row,
          bookshelfId: getOr('', [row?.storageId, 'bookshelfId'])(storages),
          warehouseId: getOr('', [row?.storageId, 'warehouseId'])(storages),
        }))((activeRows));
        setPageItem(showRows);
      }}
    />
  ), [filtered]);

  const colsItem = useMemo(() => [
    {
      Header: 'QR Code',
      disableFilters: true,
      Cell: (rowProps) => (get(rowProps, 'row.original._id') ? <QRCode size={60} value={get(rowProps, 'row.original._id') || get(rowProps, 'row.original._id')} /> : ''),
    },
    {
      Header: 'Bar Code',
      disableFilters: true,
      Cell: (rowProps) => (get(rowProps, 'row.original._id') ? (
        <Barcode
          width={1}
          height={50}
          value={get(rowProps, 'row.original._id') || get(rowProps, 'row.original._id')}
        />
      ) : ''),
    },
    {
      Header: 'Kệ sách',
      disableFilters: true,
      accessor: 'bookshelfId',
      Cell: (rowProps) => getOr('', 'name', getOr('', rowProps.value, bookshelves)),
    },
    {
      Header: 'Kho',
      disableFilters: true,
      accessor: 'warehouseId',
      Cell: (rowProps) => getOr('', 'name', getOr('', rowProps.value, warehouses)),
    },
    {
      Header: 'Thao tác',
      disableFilters: true,
      accessor: 'borrowed',
      Cell: (rowProps) => (
        <Button
          color="primary"
          disabled={get(rowProps, 'value')}
          icon="changes"
          onClick={() => onBorrow(get(rowProps, 'row.original'))}
        >
          <UilExchange />
          {get(rowProps, 'value') ? 'Đang được mượn' : 'Mượn tài liệu' }
        </Button>
      ),
    },
  ], [items]);

  return (
    <>
      <Dialog
        open
        onClose={onClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle onClose={onClose}>Mượn tài liệu</DialogTitle>
        <Styles.Header>
          <Typography variant="h6" gutterBottom>{`Tên tài liệu: ${getOr('', 'name', data)}`}</Typography>
        </Styles.Header>
        <DialogContent sx={{ overflowY: 'scroll' }}>
          <Table columns={colsItem} data={pageItem} />
          {renderPaginationItems}
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={onClose}
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DocumentItemModal.propTypes = {
  onBorrow: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
};
