import {
  UilTrash,
  UilPen,
  UilUpload,
  UilImport,
  UilPlus,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  ConfirmDialog,
  Divider,
  TextField,
  IconButton,
  Box,
  Notification,
  Waiting,
} from '@xbotvn/mui';
import React, {
  useMemo, useState,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import { paginationApi } from '../../../../api';
import DropzoneModal from '../../../../components/DropzoneModal';
import Table from '../../../../components/Table';
import {
  LISTING as LISTING_CONFIG,
} from '../../../../libs/config';
import {
  cloneDeep,
  getOr,
  values,
} from '../../../../libs/lodash';
import {
  LISTING,
  FILE,
} from '../../../../redux/actions/constants';
import {
  importExcel,
} from '../../Helpers';

export default function SubCategory() {
  const dispatch = useDispatch();

  const {
    subCategoryData,
    handling,
    activeUnit,
  } = useSelector(({
    listing, user,
  }) => ({
    subCategoryData: listing.data.subCategory,
    handling: listing.handling,
    activeUnit: user.activeUnit,
  }));

  const [editor, setEditor] = useState();
  const [showImport, setShowImport] = useState();
  const [removeConfirm, setRemoveConfirm] = useState(false);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 100,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Tên',
      accessor: 'name',
      style: {
        width: 350,
      },
    },
    {
      Header: () => (
        <Button
          startIcon={<UilPlus />}
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => setEditor({
            unitId: activeUnit,
            name: '',
          })}
        >
          Thêm
        </Button>
      ),
      id: 'add',
      style: {
        width: 250,
      },
      Cell: (rowProps) => {
        const rowValue = getOr({}, 'row.original', rowProps);
        return (
          <ButtonGroup>
            <IconButton
              color="primary"
              size="small"
              onClick={() => setEditor(cloneDeep(rowValue))}
              sx={{ marginRight: '5px' }}
            >
              <UilPen />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              color="error"
              size="small"
              sx={{ marginLeft: '5px' }}
              onClick={() => {
                setRemoveConfirm(rowValue._id);
              }}
            >
              <UilTrash />
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ], []);

  const renderEditor = () => (
    <Dialog
      open
      onClose={() => setEditor()}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={() => setEditor()}>{editor._id ? 'Sửa' : 'Thêm'}</DialogTitle>
      <DialogContent>
        <TextField
          label="Tên"
          required
          fullWidth
          value={editor.name || ''}
          onChange={(e) => setEditor((prev) => ({
            ...prev,
            name: e.target.value,
          }))}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            if (!editor.name) {
              Notification.warn('Tên không được trống.');
              return;
            }
            dispatch({
              type: LISTING.handlers.update,
              collection: 'subCategory',
              data: editor,
              onSuccess: () => setEditor(),
            });
          }}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={() => setEditor()}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      {(handling) && <Waiting message="Đang tải dữ liệu ..." />}
      {removeConfirm ? (
        <ConfirmDialog
          title="Bạn có chắc chắn muốn xóa?"
          color="error"
          confirmLabel="Xoá"
          cancelLabel="Huỷ"
          maxWidth="sm"
          fullWidth
          open
          onClose={() => setRemoveConfirm()}
          onConfirm={async () => {
            const { data } = await paginationApi.getCount('document', {
              condition: {
                unitId: activeUnit,
                subCategory: removeConfirm,
              },
            });
            const {
              count,
            } = data;
            if (count) {
              Notification.warn(`Có ${count} tài liệu đang sử dụng lĩnh vực phát triển này.`);
            } else {
              dispatch({
                type: LISTING.handlers.remove,
                _id: removeConfirm,
                collection: 'subCategory',
              });
              setRemoveConfirm();
            }
          }}
        >
          <DialogContent />
        </ConfirmDialog>
      ) : null}
      {editor ? renderEditor() : null}
      {showImport && (
        <DropzoneModal
          onConfirm={(files) => {
            if (!files.length) return;
            importExcel({
              files,
              activeUnit,
              config: LISTING_CONFIG,
              onSuccess: (records) => {
                dispatch({
                  type: LISTING.handlers.import,
                  data: records,
                });
                setShowImport();
              },
            });
          }}
          onClose={() => setShowImport()}
        />
      )}
      <Box marginLeft={1} padding={1}>
        <Button
          startIcon={<UilUpload />}
          color="success"
          size="small"
          onClick={() => setShowImport(true)}
          sx={{ marginRight: 1 }}
        >
          Nhập từ Excel
        </Button>
        <Button
          startIcon={<UilImport />}
          color="primary"
          size="small"
          onClick={() => dispatch({
            type: FILE.handlers.download,
            data: {
              unitId: activeUnit,
              fileName: 'Mau_DanhMuc_Chung.xlsx',
            },
          })}
        >
          Tải file mẫu
        </Button>
      </Box>
      <Table columns={columns} data={values(subCategoryData)} alwaysShowPagination />
    </div>
  );
}
