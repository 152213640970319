import { UilSearch, UilTimes } from '@iconscout/react-unicons';
import {
  Button, TextField, Grid,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import Waiting from '../../../components/Waiting';
import {
  orderBy,
  filter,
  includes,
  cloneDeep,
} from '../../../libs/lodash';
import { convertViToEn } from '../../../libs/utils';

import DocumentCard from './DocumentCard';
import DocumentItemModal from './DocumentItemModal';
import DocumentList from './DocumentList';
import Storage from './Storage';
import Tags from './Tags';
import * as Styles from './styles';

export default function Documents({
  filters,
  setFilters,
  categoryId,
}) {
  const {
    handling,
    categories,
    authors,
    bookshelves,
    producers,
    types,
    warehouses,
    unitId,
    subjectData,
    documents,
  } = useSelector(({
    user,
    document,
    category,
    author,
    bookshelf,
    producer,
    type,
    warehouse,
    subject,
  }) => ({
    unitId: user.activeUnit,
    handling: document.handling,
    categories: category.data,
    authors: author.data,
    bookshelves: bookshelf.data,
    producers: producer.data,
    types: type.data,
    warehouses: warehouse.data,
    subjectData: subject.data,
    documents: document.data,
  }));

  const [search, setSearch] = useState('');
  const [document, setDocument] = useState();
  const [borrow, setBorrow] = useState();
  const [pageSearch, setPageSearch] = useState(false);
  const [viewMode] = useState('friendly');

  const filterTerms = {
    authors: 'authorId',
    categories: 'categoryId',
    producers: 'producerId',
    types: 'typeId',
    docs: 'doc',
    age: 'ageId',
    documentSubject: 'documentSubjectId',
    subCategory: 'subCategoryId',
  };

  const dataDocument = orderBy(
    ['registrationNumber'],
    ['desc'],
    categoryId ? filter((doc) => doc.categoryId === categoryId)(documents) : documents,
  ).map((row) => ({
    ...row,
    typeName: types?.[row.typeId]?.name ?? '',
    authorName: authors?.[row.authorId]?.name ?? '',
    categoryName: categories?.[row.categoryId]?.name ?? '',
    producerName: producers?.[row.producerId]?.name ?? '',
  }));

  const dataTable = filter((d) => includes(convertViToEn(search.toLowerCase()), convertViToEn(d.name.toLowerCase())), dataDocument);

  const filterDocs = (docs, filterFields) => {
    const filterKeys = cloneDeep(filterFields);
    let results = docs;
    if (filterKeys[0] && Object.keys(filters[filterKeys[0]] ?? {}).length > 0) {
      results = docs.filter((item) => {
        if (filterTerms?.[filterKeys[0]] === 'doc') {
          return item.new;
        }
        const filterKey = filterTerms?.[filterKeys[0]];
        return filters?.[filterKeys[0]]?.[item?.[filterKey]];
      });
      filterKeys.shift();
    } else filterKeys.shift();
    if (filterKeys.length) return filterDocs(results, filterKeys);
    return results;
  };

  const renderSearchDocs = useMemo(() => (
    unitId && (
    <Grid
      container
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={5}>
        <TextField
          placeholder="Tìm tài liệu"
          fullWidth
          value={search}
          onChange={(e) => { setSearch(e.target.value); setPageSearch(!pageSearch); }}
        />
      </Grid>
      <Grid item xs={5}>
        <Button
          color="primary"
          sx={{ marginRight: 1 }}
          onClick={() => {
            setPageSearch(!pageSearch);
          }}
        >
          <UilSearch />
          Tìm kiếm
        </Button>
        {search !== '' && (
        <Button
          color="warning"
          onClick={() => {
            setSearch('');
          }}
        >
          <UilTimes />
          Bỏ tìm kiếm
        </Button>
        )}
      </Grid>
    </Grid>
    )
  ), [filters, search, viewMode, unitId]);

  const renderStorage = useMemo(() => {
    if (document) {
      return (
        <Storage
          data={document}
          onClose={() => {
            setBorrow();
            setDocument();
          }}
        />
      );
    }
    return null;
  }, [document]);

  const renderBorrow = useMemo(() => {
    if (borrow) {
      return (
        <DocumentItemModal
          data={borrow}
          onClose={() => {
            setBorrow();
          }}
          onBorrow={(item) => {
            setDocument(item);
          }}
        />
      );
    }
    return null;
  }, [borrow]);

  if (handling) return <Waiting message="Đang tải dữ liệu ..." />;

  return (
    <>
      <Tags filters={filters} setFilters={setFilters} />
      {renderSearchDocs}
      {renderStorage}
      {renderBorrow}
      {(unitId
      && (
      <Styles.DocumentList>
        <DocumentList
          data={filterDocs(dataTable, Object.keys(filters))}
          pageSearch={pageSearch}
          customRender={{
            renderHeader: () => null,
            renderRow: (cells) => {
              const doc = cells[0].value;
              return (
                <td>
                  <DocumentCard
                    subject={subjectData}
                    viewMode={viewMode}
                    doc={doc}
                    categories={categories}
                    types={types}
                    authors={authors}
                    bookshelves={bookshelves}
                    producers={producers}
                    warehouses={warehouses}
                    setBorrow={() => setBorrow(doc)}
                  />
                </td>
              );
            },
          }}
          columns={[
            {
              Header: 'Card',
              accessor: (row) => row,
              Cell: ({
                row,
              }) => row.original,
            },
          ]}
        />
      </Styles.DocumentList>
      )
      )}
    </>
  );
}

Documents.defaultProps = {
  categoryId: undefined,
};

Documents.propTypes = {
  filters: PropTypes.shape().isRequired,
  setFilters: PropTypes.func.isRequired,
  categoryId: PropTypes.string,
};
