import {
  UilTrash,
  UilPen,
  UilPlus,
  UilCheckCircle,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  DialogContent,
  ConfirmDialog,
  Divider,
  IconButton,
  Waiting,
} from '@xbotvn/mui';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { paginationApi as itemsAPI } from '../../../api';
import Table from '../../../components/Table';
import {
  cloneDeep,
  filter,
  find,
  forEach,
  getOr,
  intersection,
  isEmpty,
  map,
  values,
  omit,
} from '../../../libs/lodash';
import {
  BORROW,
} from '../../../redux/actions/constants';

import BorrowModal from './BorrowModal';

export default function Current({
  type,
  position,
}) {
  const dispatch = useDispatch();

  const {
    borrows,
    documents,
    handling,
    activeUnit,
    members,
    users,
  } = useSelector(({
    borrow,
    document,
    user,
    member,
    unit,
  }) => ({
    borrows: borrow.data,
    documents: document.data,
    handling: borrow.handling,
    activeUnit: user.activeUnit,
    members: member.data,
    users: getOr([], ['data', user.activeUnit, 'members'], unit),
  }));

  const [editData, setEditData] = useState();
  const [items, setItems] = useState({});
  const [isWaiting, setIsWaiting] = useState(false);
  const [removeConfirm, setRemoveConfirm] = useState(false);

  useEffect(() => {
    setIsWaiting(true);
    itemsAPI.getPage('documentItem', {
      condition: {
        documentId: {
          $in: Object.keys(documents),
        },
        unitId: activeUnit,
      },
    }).then(({ data }) => {
      setItems(filter(({ borrowed }) => !borrowed, data.records));
      setIsWaiting(false);
    });
  }, [documents, borrows, position]);

  const convertData = useMemo(() => {
    const newData = cloneDeep(borrows);
    forEach(
      (obj) => {
        const newObj = obj;
        const checkMember = find((mb) => mb.email === getOr('', 'email', newObj.userInfo), values(members));
        if (isEmpty(checkMember)) {
          const checkStaff = find((user) => user.email === getOr('', 'email', newObj.userInfo), users);
          if (!isEmpty(checkStaff)) {
            newObj.position = 'staff';
          }
        } else {
          newObj.position = 'member';
        }
        return newObj;
      },
      newData,
    );
    return newData;
  }, [borrows, members]);

  const filterByType = useMemo(
    () => filter((obj) => {
      switch (type) {
        case 'new': return new Date() <= new Date(obj.requiredReturnDate) && obj.status === type;
        case 'late': return new Date() > new Date(obj.requiredReturnDate) && obj.status !== 'done';
        default: return obj.status === type;
      }
    }, map((data) => {
      const {
        updatedAt,
        ...rest
      } = data;
      return {
        updatedDay: new Date(updatedAt || '').getDay(),
        updatedMonth: new Date(updatedAt || '').getMonth(),
        updatedYear: new Date(updatedAt || '').getFullYear(),
        updatedTime: new Date(updatedAt || '').getTime(),
        ...rest,
      };
    }, convertData)),
    [borrows, convertData, type],
  );

  const filterByPosition = useMemo(() => {
    const converted = map(
      (elm) => ({
        ...elm,
        docName: getOr('', [elm.documentId, 'name'], documents),
        userName: getOr('', ['userInfo', 'name'], elm),
      }),
      filterByType,
    );
    if (position) {
      return filter((obj) => obj.position === position, converted);
    }
    return converted;
  }, [documents, borrows, filterByType, position]);

  const columns = useMemo(() => {
    const defaultCols = [
      {
        Header: 'STT',
        disableFilters: true,
        style: {
          width: 80,
        },
        Cell: ({
          row,
        }) => row.index + 1,
      },
      {
        Header: 'Tài liệu',
        accessor: 'docName',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Mã sách',
        accessor: 'documentItem',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Tên người mượn',
        accessor: 'userName',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Chức danh',
        accessor: 'position',
        disableFilters: true,
        style: {
          width: 250,
        },
        Cell: (rowProps) => {
          switch (rowProps.value) {
            case 'member': return 'Học viên';
            case 'staff': return 'Nhân viên';
            default: return null;
          }
        },
      },
      {
        Header: 'Ngày mượn',
        accessor: 'borrowDate',
        style: {
          width: 250,
        },
        Cell: (rowProps) => {
          const value = get(rowProps, 'value');
          if (!value) return null;
          return moment(value).format('DD/MM/YYYY');
        },
      },
      {
        Header: 'Ngày phải trả',
        accessor: 'requiredReturnDate',
        style: {
          width: 250,
        },
        Cell: (rowProps) => {
          const value = get(rowProps, 'value');
          if (!value) return null;
          return moment(value).format('DD/MM/YYYY');
        },
      },
      {
        Header: 'Ngày trả thực tế',
        accessor: 'returnDate',
        style: {
          width: 250,
        },
        Cell: (rowProps) => {
          const value = get(rowProps, 'value');
          if (!value) return null;
          return moment(value).format('DD/MM/YYYY');
        },
      },
    ];

    if (type === 'request') {
      defaultCols.push(
        {
          Header: 'Xác nhận yêu cầu',
          accessor: 'confirm',
          disableFilters: true,
          style: {
            width: 250,
          },
          Cell: (rowProps) => {
            const rowValue = getOr({}, 'row.original', rowProps);
            return (
              <IconButton
                onClick={() => {
                  const changed = cloneDeep(rowValue);
                  changed.status = 'new';
                  dispatch({
                    type: BORROW.handlers.update,
                    data: changed,
                  });
                }}
                color="warning"
                size="small"
              >
                <UilCheckCircle />
              </IconButton>
            );
          },
        },
      );
    }

    defaultCols.push(
      {
        Header: () => {
          if (intersection([type], ['done', 'late']).length) return null;
          return (
            <Button
              startIcon={<UilPlus />}
              size="small"
              color="primary"
              variant="outlined"
              onClick={() => setEditData({
                unitId: activeUnit,
                borrowDate: new Date().toString(),
                status: type,
                addNew: true,
              })}
            >
              Thêm
            </Button>
          );
        },
        accessor: 'id',
        disableFilters: true,
        style: {
          width: 150,
        },
        Cell: (rowProps) => {
          const rowValue = getOr({}, 'row.original', rowProps);
          return (
            <ButtonGroup>
              <IconButton
                onClick={() => {
                  let cloned = cloneDeep(rowValue);
                  cloned = omit('docName', cloned);
                  cloned = omit('userName', cloned);
                  setEditData(cloned);
                }}
                color="primary"
                size="small"
                sx={{ marginRight: '5px' }}
              >
                <UilPen />
              </IconButton>
              <Divider orientation="vertical" flexItem />
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  setRemoveConfirm(rowValue._id);
                }}
                sx={{ marginLeft: '5px' }}
              >
                <UilTrash />
              </IconButton>
            </ButtonGroup>
          );
        },
      },
    );

    return defaultCols;
  }, [borrows, documents, type, items]);

  return (
    <div>
      {(handling || isWaiting) && <Waiting fullscreen />}
      {removeConfirm ? (
        <ConfirmDialog
          title="Bạn có chắc chắn muốn xóa?"
          color="error"
          confirmLabel="Xoá"
          cancelLabel="Huỷ"
          maxWidth="sm"
          fullWidth
          open
          onClose={() => setRemoveConfirm()}
          onConfirm={async () => {
            dispatch({
              type: BORROW.handlers.remove,
              _id: removeConfirm,
            });
            setRemoveConfirm();
          }}
        >
          <DialogContent />
        </ConfirmDialog>
      ) : null}
      {editData && (
      <BorrowModal
        type={type}
        data={editData}
        items={items}
        onClose={() => setEditData()}
        currentList={filterByPosition}
      />
      )}
      <Table columns={columns} data={values(filterByPosition)} alwaysShowPagination />
    </div>
  );
}

Current.propTypes = {
  type: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};
