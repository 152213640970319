import {
  api, getOptions,
} from './helpers';

const getCount = (route, payload) => api.post(`/${route}/getCount`, payload, getOptions());

const getPage = (route, payload) => api.post(`/${route}/getPage`, payload, getOptions());

export default {
  getCount,
  getPage,
};
