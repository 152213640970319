import styled from '@emotion/styled';

export const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  display: flex;
`;

export const ButtonGroup = styled.div`
  padding: 10px;
`;
