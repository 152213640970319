import PropTypes from 'prop-types';
import React from 'react';

import Footer from './Footer';
import Header from './Header';

export default function Layout({
  children,
}) {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
