import PropTypes from 'prop-types';
import React, {
  useEffect, useState,
} from 'react';

import Pagination from './Pagination';

export default function PaginationClient({
  rows,
  onChange,
}) {
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
    pageCount: Math.ceil(rows.length / 10),
  });

  const {
    pageSize,
    pageIndex,
    pageCount,
  } = pagination;

  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      pageIndex: 0,
      pageCount: Math.ceil(rows.length / prevState.pageSize),
    }));
  }, [rows]);

  useEffect(() => {
    onChange({
      activeRows: rows.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
    });
  }, [pagination]);

  const renderPagination = () => (
    <Pagination
      pageIndex={pageIndex}
      pageCount={pageCount}
      pageSize={pageSize}
      setPageSize={(value) => {
        setPagination({
          ...pagination,
          pageSize: value,
          pageIndex: 0,
          pageCount: Math.ceil(rows.length / value),
        });
      }}
      gotoPage={(value) => {
        setPagination({
          ...pagination,
          pageIndex: value,
        });
      }}
      previousPage={() => {
        setPagination({
          ...pagination,
          pageIndex: pageIndex - 1,
        });
      }}
      nextPage={() => {
        setPagination({
          ...pagination,
          pageIndex: pageIndex + 1,
        });
      }}
      canPreviousPage={pageIndex > 0}
      canNextPage={pageIndex < pageCount - 1}
    />
  );

  return (
    <div>
      {renderPagination()}
    </div>
  );
}

PaginationClient.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
};
