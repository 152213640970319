import styled from '@emotion/styled';
import { DatePicker } from '@xbotvn/mui';

export const ButtonGroup = styled.div`
  padding: 5px;
  display: flex;

  .bp3-button {
    margin: 10px 0px 10px 20px;
  }
`;

export const InputDatePicker = styled(DatePicker)`
  -webkit-appearance: none;
  appearance: none;
  background: #ffffff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 10px;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align: middle;
`;

export const Filters = styled.div`
  padding: 10px;
  width: 45%;
  display: inline-flex;
`;

export const Container = styled.div`
  // padding: 10px;
`;

export const Code = styled.div`
  margin: 3px;
  border: solid 1px;
  display: inline-flex;
  width: 32%;
  height: 128px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const BC = styled.div`
  text-align: center;
`;

export const QR = styled.div`
  width: 60%;
  padding: 3px 5px 0px 8px;
`;

export const TitleBarcode = styled.div`
  text-align: center;
  font-size: 14px;
  height: 35px;
  font-weight: 500;
  padding: 0px 5px;
`;

export const TitleQRCode = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
`;

export const GroupQRCode = styled.div`
  display: flex;
  width: 100%;
`;

export const GroupCode = styled.div`
  overflow: auto;
  height: 500px;

  .page-break {
    padding-top: 10px;
  }
  svg {
    width: 180px;
    height: 50px;
  }
`;

export const NumberPage = styled.p`
  text-align: center;
`;

export const UnitName = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  padding: 5px;
  border-bottom: solid 1px #000;
  text-align: center;
  font-weight: 500;
`;
