import {
  UilLayerGroup,
  UilListUl,
  UilEdit,
  UilServerAlt,
  UilShop,
  UilHome,
} from '@iconscout/react-unicons';
import { Toolbar, Box, Button } from '@xbotvn/mui';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Age from './Age';
import Author from './Author';
import Bookshelf from './Bookshelf';
import Category from './Category';
import DocumentSubject from './DocumentSubject';
import Level from './Level';
import PosterCabinet from './PosterCabinet';
import Producer from './Producer';
import StorageType from './StorageType';
import SubCategory from './SubCategory';
import Subject from './Subject';
import Type from './Type';
import Warehouse from './Warehouse';

export default function Listing() {
  const {
    userInitial,
  } = useSelector(({
    user,
  }) => ({
    userInitial: user,
  }));

  const [menuActive, setMenu] = useState('type');

  const renderContent = useMemo(() => {
    switch (menuActive) {
      case 'type':
        return <Type />;
      case 'level':
        return <Level />;
      case 'category':
        return <Category />;
      case 'author':
        return <Author />;
      case 'producer':
        return <Producer />;
      case 'bookshelf':
        return <Bookshelf />;
      case 'warehouse':
        return <Warehouse />;
      case 'cabinet':
        return <PosterCabinet />;
      case 'subject':
        return <Subject />;
      case 'storageType':
        return <StorageType />;
      case 'age':
        return <Age />;
      case 'documentSubject':
        return <DocumentSubject />;
      case 'subCategory':
        return <SubCategory />;
      default:
        return <div />;
    }
  }, [menuActive]);

  return userInitial._id ? (
    <div>
      <Toolbar>
        <Box>
          <Button
            variant="text"
            startIcon={<UilLayerGroup />}
            color={menuActive === 'type' ? 'primary' : 'inherit'}
            onClick={() => setMenu('type')}
          >
            Loại sách
          </Button>
          <Button
            variant="text"
            startIcon={<UilListUl />}
            color={menuActive === 'category' ? 'primary' : 'inherit'}
            onClick={() => setMenu('category')}
          >
            Danh mục sách
          </Button>
          <Button
            variant="text"
            startIcon={<UilEdit />}
            color={menuActive === 'author' ? 'primary' : 'inherit'}
            onClick={() => setMenu('author')}
          >
            Tác giả
          </Button>
          <Button
            variant="text"
            startIcon={<UilServerAlt />}
            color={menuActive === 'producer' ? 'primary' : 'inherit'}
            onClick={() => setMenu('producer')}
          >
            Nhà xuất bản
          </Button>
          <Button
            variant="text"
            startIcon={<UilShop />}
            color={menuActive === 'bookshelf' ? 'primary' : 'inherit'}
            onClick={() => setMenu('bookshelf')}
          >
            Kệ sách
          </Button>
          <Button
            variant="text"
            startIcon={<UilHome />}
            color={menuActive === 'warehouse' ? 'primary' : 'inherit'}
            onClick={() => setMenu('warehouse')}
          >
            Kho
          </Button>
        </Box>
      </Toolbar>
      <div
        style={{
          paddingBottom: 15,
        }}
      >
        {renderContent}
      </div>
    </div>
  ) : null;
}
