import PropTypes from 'prop-types';
import React, {
  useMemo,
} from 'react';
import Chart from 'react-apexcharts';
import {
  useSelector,
} from 'react-redux';

import {
  compose,
  flow,
  forEach,
  getOr,
  groupBy,
  keyBy,
  map,
  sumBy,
} from '../../../libs/lodash';

export default function ChartData({
  data,
  type,
}) {
  const {
    storages,
    documents,
  } = useSelector(({
    document,
    storage,
  }) => ({
    documents: document.data,
    storages: storage.data,
  }));

  const sumAmount = compose(keyBy('key'))(flow(
    groupBy('documentId'),
    map.convert({
      cap: false,
    })((record, key) => ({
      key,
      total: sumBy('amount', record),
    })),
  )(storages));

  const statistics = flow(
    groupBy(type),
    map.convert({
      cap: false,
    })((record, key) => {
      let total = 0;
      forEach(
        (elm) => {
          total += getOr(0, [elm._id, 'total'], sumAmount);
        },
        record,
      );
      return {
        key,
        total,
      };
    }),
  )(documents);

  const getName = useMemo(() => map(
    (key) => getOr('', 'name', data[key]),
    map((elm) => elm.key, statistics),
  ), [statistics]);

  const options = useMemo(() => ({
    chart: {
      id: 'Chart-data',
    },
    xaxis: {
      categories: getName,
    },
  }), [type, data]);

  const series = useMemo(() => [{
    name: 'Số lượng',
    data: map((elm) => elm.total, statistics),
  }], [type, data]);

  return (
    <div>
      <Chart
        options={options}
        series={series}
        width={1000}
        height={500}
        type="bar"
      />
    </div>
  );
}

ChartData.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  type: PropTypes.string.isRequired,
};
