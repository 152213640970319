import {
  all,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import {
  listingApi,
} from '../../api';
import {
  errorHandler, successHandler,
} from '../../libs/ga';
import {
  cloneDeep,
  forEach,
  getOr,
} from '../../libs/lodash';

import {
  LISTING,
} from './constants';

function* storeListing(data = {}, collection, merge = true) {
  yield put({
    type: LISTING.update,
    collection,
    data,
    merge,
  });
}

function* fetchListing({
  data, collection,
}) {
  yield* storeListing(data, collection);
}

function* updateListing({
  data, onSuccess, collection,
}) {
  try {
    const {
      _id,
      ...rest
    } = data;
    if (_id) {
      yield call(() => new Promise((resolve, reject) => {
        listingApi.update({
          collection,
          payload: {
            _id,
            data: rest,
          },
        }).then(() => resolve(true))
          .catch((error) => reject(error));
      }));
      yield* storeListing({
        [data._id]: data,
      }, collection);
    } else {
      const {
        newData,
      } = yield call(() => new Promise((resolve, reject) => {
        listingApi.create({
          collection,
          payload: {
            data,
          },
        }).then((res) => resolve(res.data))
          .catch((err) => reject(err));
      }));
      yield* storeListing({
        [newData._id]: newData,
      }, collection);
    }
    successHandler('Thành công', 'Cập nhật thành công', onSuccess);
  } catch (error) {
    yield* storeListing();
    errorHandler('Thất bại', error);
  }
}

function* removeListing({
  _id,
  collection,
}) {
  try {
    yield call(() => new Promise((resolve, reject) => {
      listingApi.remove({
        collection,
        payload: {
          _id,
        },
      }).then(() => resolve(true))
        .catch((error) => reject(error));
    }));
    const allData = yield select();
    const newData = cloneDeep(getOr({}, ['listing', 'data', collection])(allData));
    delete newData[_id];
    yield* storeListing(newData, collection, false);

    successHandler('Thành công', 'Xóa thành công');
  } catch (error) {
    yield* storeListing();
    errorHandler('Thất bại', error);
  }
}

function* importListing({
  data = [],
  collection,
}) {
  yield call(() => new Promise((resolve, reject) => {
    listingApi.importData({
      collection,
      payload: {
        data,
      },
    }).then(() => resolve(true))
      .catch((err) => reject(err));
  }));
  const allData = yield select();
  const newData = cloneDeep(getOr({}, ['listing', 'data', collection])(allData));
  forEach(
    (obj) => {
      newData[obj._id] = obj;
    },
    data,
  );
  yield* storeListing(newData, collection);
  successHandler('Thành công', 'Cập nhật thành công');
}

export default function* listingSaga() {
  yield all([
    yield takeEvery(LISTING.handlers.fetch, fetchListing),
    yield takeEvery(LISTING.handlers.import, importListing),
    yield takeEvery(LISTING.handlers.update, updateListing),
    yield takeEvery(LISTING.handlers.remove, removeListing),
  ]);
}
