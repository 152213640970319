import {
  Notification,
} from '@xbotvn/mui';
import {
  all,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { listingApi } from '../../api';
import {
  cloneDeep,
  forEach,
} from '../../libs/lodash';

import {
  MEMBER,
} from './constants';

function* storeMember(data = {}, merge = true) {
  yield put({
    type: MEMBER.update,
    data,
    merge,
  });
}

function* fetchMember({
  data,
}) {
  yield* storeMember(data);
}

function* updateMember({
  data, onSuccess,
}) {
  try {
    const {
      member,
    } = yield select();
    const {
      _id,
      ...rest
    } = data;
    if (_id) {
      yield call(() => new Promise((resolve, reject) => {
        listingApi.update({
          collection: 'member',
          payload: {
            _id,
            data: rest,
          },
        }).then(() => resolve(true))
          .catch((error) => reject(error));
      }));
      const newMember = cloneDeep(member);
      newMember[_id] = data;
      yield* storeMember(newMember);
    } else {
      const {
        newData,
      } = yield call(() => new Promise((resolve, reject) => {
        listingApi.create({
          collection: 'member',
          payload: {
            data,
          },
        }).then((res) => resolve(res.data))
          .catch((err) => reject(err));
      }));
      yield* storeMember({
        [newData._id]: newData,
      });
    }
    Notification.success('Thành công', 'Cập nhật thành công', onSuccess);
  } catch (error) {
    yield* storeMember();
    Notification.error('Thất bại', error);
  }
}

function* removeMember({
  _id,
}) {
  const {
    member,
  } = yield select();
  try {
    yield call(() => new Promise((resolve, reject) => {
      listingApi.remove({
        collection: 'member',
        payload: {
          _id,
        },
      }).then(() => resolve(true))
        .catch((error) => reject(error));
    }));

    const newData = cloneDeep(member.data);
    delete newData[_id];
    yield* storeMember(newData, false);

    Notification.success('Thành công', 'Xóa thành công');
  } catch (error) {
    yield* storeMember();
    Notification.error('Thất bại', error);
  }
}

function* importMember({
  data = [],
}) {
  const {
    member,
  } = yield select();
  yield call(() => new Promise((resolve, reject) => {
    listingApi.importData({
      collection: 'member',
      payload: {
        data,
      },
    }).then(() => resolve(true))
      .catch((err) => reject(err));
  }));
  const newData = cloneDeep(member.data);
  forEach(
    (obj) => {
      newData[obj._id] = obj;
    },
    data,
  );
  yield* storeMember(newData);
  Notification.success('Thành công', 'Cập nhật thành công');
}

function* handlerCheckMember(data) {
  const {
    result,
  } = yield call(() => new Promise((resolve, reject) => {
    listingApi.checkInfo({
      collection: 'member',
      payload: {
        data,
      },
    }).then((res) => resolve(res.data))
      .catch((err) => reject(err));
  }));
  return result;
}

function* checkMember({
  data,
  onSuccess,
}) {
  const {
    email,
    ...rest
  } = data;
  try {
    const checkEmail = yield handlerCheckMember({
      email,
    });
    if (checkEmail) {
      Notification.warn('Email đã được đăng ký.', ' Vui lòng đăng nhập để truy cập hệ thống.');
    } else {
      const checkExist = yield handlerCheckMember(rest);
      if (checkExist) {
        listingApi.register({
          collection: 'member',
          payload: {
            data,
          },
        });
        Notification.success('Đăng ký thành công.', ' Vui lòng đăng nhập để truy cập hệ thống.', onSuccess);
      } else {
        Notification.error('Thông tin đăng ký không có trong hệ thống.', ' Vui lòng liên hệ Quản lý thư viện để có thông tin chi tiết.');
      }
    }
  } catch (error) {
    Notification.error('Thất bại', error);
  }
}

export default function* memberSaga() {
  yield all([
    yield takeEvery(MEMBER.handlers.fetch, fetchMember),
    yield takeEvery(MEMBER.handlers.check, checkMember),
    yield takeEvery(MEMBER.handlers.update, updateMember),
    yield takeEvery(MEMBER.handlers.import, importMember),
    yield takeEvery(MEMBER.handlers.remove, removeMember),
  ]);
}
