import {
  Grid,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  map,
  orderBy,
} from '../libs/lodash';

import SingleSelect from './SingleSelect';

export default function AddressSelector({
  cities,
  province,
  district,
  ward,
  onAddressChange,
}) {
  const [address, setAddress] = useState({ province, district, ward });

  useEffect(() => {
    onAddressChange(address);
  }, [address]);

  const provinceOptions = map((data, key) => ({ label: data.name, value: key }))(cities);
  const districtOptions = orderBy(['label'], ['asc'])(map((data, key) => ({ label: data.name, value: key }))(cities?.[province]?.districts || []));
  const wardOptions = orderBy(['label'], ['asc'])(map((data, key) => ({ label: data.name, value: key }))(cities?.[province]?.districts?.[district]?.wards || []));

  return (
    <Grid container>
      <Grid item xs={4}>
        <SingleSelect
          options={provinceOptions}
          value={address?.province}
          onChange={(value) => {
            setAddress({ province: value });
          }}
          InputProps={{
            placeholder: 'Chọn tỉnh',
            required: true,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <SingleSelect
          options={districtOptions}
          value={address?.district}
          onChange={(value) => {
            setAddress((prev) => ({ ...prev, district: value, ward: '' }));
          }}
          InputProps={{
            placeholder: 'Chọn quận/huyện',
            required: true,
          }}
          clearable
        />
      </Grid>
      <Grid item xs={4}>
        <SingleSelect
          options={wardOptions}
          value={address?.ward}
          onChange={(value) => setAddress((prev) => ({ ...prev, ward: value }))}
          InputProps={{
            placeholder: 'Chọn phường/xã',
            required: true,
          }}
          clearable
        />
      </Grid>
    </Grid>
  );
}

AddressSelector.defaultProps = {
  onAddressChange: () => { },
  province: '',
  district: '',
  ward: '',
};

AddressSelector.propTypes = {
  onAddressChange: PropTypes.func,
  cities: PropTypes.shape({}).isRequired,
  province: PropTypes.string,
  district: PropTypes.string,
  ward: PropTypes.string,
};
