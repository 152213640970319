export const schoolLevels = {
  mn: 'Mầm non',
  th: 'Tiểu học',
  thcs: 'Trung học Cơ sở',
  thpt: 'Trung học Phổ thông',
  tc: 'Trung cấp',
  cd: 'Cao đẳng',
  dh: 'Đại học',
  khac: 'Khác',
};

export const grades = {
  mn: {
    khac: 'Khác',
  },
  th: {
    khoi1: 'Khối 1',
    khoi2: 'Khối 2',
    khoi3: 'Khối 3',
    khoi4: 'Khối 4',
    khoi5: 'Khối 5',
  },
  thcs: {
    khoi6: 'Khối 6',
    khoi7: 'Khối 7',
    khoi8: 'Khối 8',
    khoi9: 'Khối 9',
  },
  thpt: {
    khoi10: 'Khối 10',
    khoi11: 'Khối 11',
    khoi12: 'Khối 12',
  },
  ch: {
    khac: 'Khác',
  },
  khac: {
    khac: 'Khác',
  },
  tc: {
    khac: 'Khác',
  },
  cd: {
    khac: 'Khác',
  },
  dh: {
    khac: 'Khác',
  },
};
export const documentCode = {
  isbnCode: {
    codePrimary: '020',
    codeSub: 'a',
    value: 'isbnCode',
  },
  price: {
    codePrimary: '020',
    codeSub: 'c',
    value: 'price',
  },
  language_override: {
    codePrimary: '041',
    codeSub: 'a',
    value: 'language_override',
  },
  symbol: {
    codePrimary: '082',
    codeSub: 'a',
    value: 'symbol',
  },
  authorCode: {
    codePrimary: '083',
    codeSub: 'b',
    value: 'authorCode',
  },
  authorId: {
    codePrimary: '100',
    codeSub: 'a',
    value: 'authorId',
    data: 'authors',
  },
  name: {
    codePrimary: '245',
    codeSub: 'a',
    value: 'name',
  },
  subTitle: {
    codePrimary: '245',
    codeSub: 'b',
    value: 'subTitle',
  },
  resInfo: {
    codePrimary: '245',
    codeSub: 'c',
    value: 'resInfo',
  },
  chapInfo: {
    codePrimary: '245',
    codeSub: 'n',
    value: 'chapInfo',
  },
  parallelTitle: {
    codePrimary: '246',
    codeSub: 'a',
    value: 'parallelTitle',
  },
  edition: {
    codePrimary: '250',
    codeSub: 'a',
    value: 'edition',
  },
  pop: {
    codePrimary: '260',
    codeSub: 'a',
    value: 'pop',
  },
  producerId: {
    codePrimary: '260',
    codeSub: 'b',
    value: 'producerId',
    data: 'producers',
  },
  publishYear: {
    codePrimary: '260',
    codeSub: 'c',
    value: 'publishYear',
  },
  pages: {
    codePrimary: '300',
    codeSub: 'a',
    value: 'pages',
  },
  size: {
    codePrimary: '300',
    codeSub: 'b',
    value: 'size',
  },
  attachment: {
    codePrimary: '300',
    codeSub: 'e',
    value: 'attachment',
  },
  sol: {
    codePrimary: '490',
    codeSub: 'a',
    value: 'sol',
  },
  content: {
    codePrimary: '520',
    codeSub: 'a',
    value: 'content',
  },
  subject: {
    codePrimary: '526',
    codeSub: 'a',
    value: 'subject',
    data: 'subject',
  },
  topic: {
    codePrimary: '650',
    codeSub: 'a',
    value: 'topic',
  },
  keyword: {
    codePrimary: '653',
    codeSub: 'a',
    value: 'keyword',
  },
  sameAuthor: {
    codePrimary: '700',
    codeSub: 'a',
    value: 'sameAuthor',
  },
};

export const languages = {
  en: {
    label: 'Tiếng Anh',
    code: 'en',
  },
  zh: {
    label: 'Tiếng Trung Quốc',
    code: 'zh',
  },
  hi: {
    label: 'Tiếng Hindi',
    code: 'hi',
  },
  es: {
    label: 'Tiếng Tây Ban Nha',
    code: 'es',
  },
  fr: {
    label: 'Tiếng Pháp',
    code: 'fr',
  },
  ar: {
    label: 'Tiếng Ả Rập',
    code: 'ar',
  },
  bn: {
    label: 'Tiếng Bengal',
    code: 'bn',
  },
  ru: {
    label: 'Tiếng Nga',
    code: 'ru',
  },
  pt: {
    label: 'Tiếng Bồ Đào Nha',
    code: 'pt',
  },
  id: {
    label: 'Tiếng Indonesia',
    code: 'id',
  },
  ur: {
    label: 'Tiếng Urdu',
    code: 'ur',
  },
  de: {
    label: 'Tiếng Đức',
    code: 'de',
  },
  ja: {
    label: 'Tiếng Nhật',
    code: 'ja',
  },
  sw: {
    label: 'Tiếng Swahili',
    code: 'sw',
  },
  mr: {
    label: 'Tiếng Marathi',
    code: 'mr',
  },
  te: {
    label: 'Tiếng Telugu',
    code: 'te',
  },
  tr: {
    label: 'Tiếng Thổ Nhĩ Kì',
    code: 'tr',
  },
  ta: {
    label: 'Tiếng Tamil',
    code: 'ta',
  },
  ko: {
    label: 'Tiếng Hàn',
    code: 'ko',
  },
  vi: {
    label: 'Tiếng Việt',
    code: 'vi',
  },
  jv: {
    label: 'Tiếng Java',
    code: 'jv',
  },
  it: {
    label: 'Tiếng Italia',
    code: 'it',
  },
  th: {
    label: 'Tiếng Thái',
    code: 'th',
  },
};

// these grades and levels is use temporarily for demo sessions, change if near future
export const tempGrades = {
  khoi1: {
    name: 'Khối 1',
    _id: 'khoi1',
  },
  khoi2: {
    name: 'Khối 2',
    _id: 'khoi2',
  },
  khoi3: {
    name: 'Khối 3',
    _id: 'khoi3',
  },
  khoi4: {
    name: 'Khối 4',
    _id: 'khoi4',
  },
  khoi5: {
    name: 'Khối 5',
    _id: 'khoi5',
  },
  khoi6: {
    name: 'Khối 6',
    _id: 'khoi6',
  },
  khoi7: {
    name: 'Khối 7',
    _id: 'khoi7',
  },
  khoi8: {
    name: 'Khối 8',
    _id: 'khoi8',
  },
  khoi9: {
    name: 'Khối 9',
    _id: 'khoi9',
  },
  khoi10: {
    name: 'Khối 10',
    _id: 'khoi10',
  },
  khoi11: {
    name: 'Khối 11',
    _id: 'khoi11',
  },
  khoi12: {
    name: 'Khối 12',
    _id: 'khoi12',
  },
  khac: {
    name: 'Khác',
    _id: 'khac',
  },
};

export const tempSchoolLevels = {
  nt: {
    name: 'Nhà Trẻ',
    _id: 'nt',
  },
  th: {
    name: 'Tiểu học',
    _id: 'th',
  },
  thcs: {
    name: 'THCS',
    _id: 'thcs',
  },
  thpt: {
    name: 'THPT',
    _id: 'thpt',
  },
  ch: {
    name: 'Cao học',
    _id: 'ch',
  },
  khac: {
    name: 'Khác',
    _id: 'khac',
  },
};

export const cutter = {
  a: '100',
  ac: '101',
  ach: '102',
  ai: '103',
  am: '104',
  an: '105',
  ang: '106',
  anh: '107',
  ao: '108',
  ap: '109',
  at: '110',
  au: '111',
  ay: '112',
  ăc: '113',
  ăm: '114',
  ăn: '115',
  ăng: '116',
  ăp: '117',
  ăt: '118',
  âc: '119',
  âm: '120',
  ân: '121',
  âng: '122',
  âp: '123',
  ât: '124',
  âu: '125',
  ây: '126',
  e: '200',
  ec: '201',
  em: '202',
  en: '203',
  eng: '255',
  eo: '205',
  ep: '206',
  et: '207',
  ê: '250',
  êc: '251',
  êch: '252',
  êm: '253',
  ên: '254',
  ênh: '256',
  êp: '257',
  êt: '258',
  êu: '259',
  i: '300',
  ia: '301',
  ich: '302',
  iec: '303',
  iêm: '304',
  iên: '305',
  iêng: '306',
  iêp: '307',
  iêt: '308',
  iêu: '309',
  im: '310',
  in: '311',
  inh: '312',
  ip: '313',
  it: '314',
  iu: '315',
  o: '400',
  oa: '401',
  oac: '402',
  oach: '403',
  oai: '404',
  oam: '405',
  oan: '406',
  oang: '407',
  oanh: '408',
  oao: '409',
  oap: '410',
  oat: '411',
  oay: '412',
  oăc: '413',
  oăm: '414',
  oăn: '415',
  oăng: '416',
  oăp: '417',
  oăt: '418',
  oc: '419',
  oe: '420',
  oec: '421',
  oem: '422',
  oen: '423',
  oeng: '424',
  ôe: '425',
  oep: '426',
  oet: '427',
  oi: '428',
  om: '429',
  on: '430',
  ong: '431',
  ooc: '432',
  oong: '433',
  op: '434',
  ot: '435',
  ô: '450',
  ôc: '451',
  ôi: '452',
  ôm: '453',
  ôn: '454',
  ông: '455',
  ôông: '456',
  ôp: '457',
  ôt: '458',
  ơ: '460',
  ơc: '461',
  ơi: '462',
  ơm: '463',
  ơn: '464',
  ơng: '465',
  ơp: '466',
  ơt: '467',
  u: '500',
  ua: '501',
  uân: '502',
  uâng: '503',
  uât: '504',
  uây: '505',
  uc: '506',
  uê: '507',
  uêch: '508',
  uênh: '509',
  ui: '510',
  um: '511',
  un: '512',
  ung: '513',
  uôc: '514',
  uôi: '515',
  uôm: '516',
  uôn: '517',
  uông: '518',
  uôt: '519',
  uơ: '520',
  up: '521',
  ut: '523',
  uy: '524',
  uya: '525',
  uyêch: '526',
  uyên: '527',
  uyêt: '528',
  uym: '529',
  uyn: '530',
  uynh: '531',
  uyp: '532',
  uyt: '533',
  ư: '550',
  ưa: '551',
  ưc: '552',
  ưi: '553',
  ưm: '554',
  ưn: '555',
  ưng: '556',
  ươc: '557',
  ươi: '558',
  ươm: '559',
  ươn: '560',
  ương: '561',
  ươp: '562',
  ươt: '563',
  ươu: '564',
  ưt: '565',
  ưu: '566',
  y: '600',
  ych: '601',
  yêm: '602',
  yên: '603',
  yêng: '604',
  yêt: '605',
  yêu: '606',
  ym: '607',
  yn: '608',
  ynh: '609',
  yp: '610',
};

export const sellingReasons = {
  old: 'Sách báo cũ',
  backward: 'Sách báo lạc hậu',
  lost: 'Mất',
  fray: 'Sách báo rách nát',
};
